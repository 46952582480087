import React from 'react';
import useOverviewTabContent from './useOverviewTab';

import KeyValueDataTable from '../../../components/common/KeyValueDataTable';
import TableCardTwoColumn from '../components/TableCardTwoColumn';

const OverviewTab = (props: any) => {
  const { setBadActor, setChemicalName, setHighlyHazardous } = props;
  const chemicalId = window.location.pathname.split('chemical/')[1];
  
  const values = useOverviewTabContent(chemicalId);
  const { data } = values;
  setChemicalName(data.chemicalName);
  setBadActor(data.badActor);
   setHighlyHazardous(data.highlyHazardous);
  return (
    <>
      <KeyValueDataTable tableHeading="SUMMARY" tableData={data.data.summary} />
      <KeyValueDataTable
        tableHeading={`TOXICITY OF ${data.chemicalName}`}
        tableData={data.data.toxicity}
      />
      <TableCardTwoColumn
        title={`OTHER NAMES FOR ${data.chemicalName}`}
        list={data.data.chemicals.chemicals}
        chemName={data.chemicalName}
        badActor={data.badActor}
        count={data.data.chemicals.metaData.totalchemicals}
        
      />
    </>
  );
};
export default OverviewTab;
