import React, { useEffect, useState } from 'react';
import TooltipSections from '../components/TooltipSections';
import tooltipApi from '../../../api/tooltipApi';

const TooltipsInnerDetails = (props: any) => {
  const [dataNotFound, setDataNotFound] = useState(false);
  const [data, setData] = useState({
    data: [],
  });
  const getSearchData = async () => {
    try {
     
     // setRequestDataComplete(false);
      setDataNotFound(false);
      const results = await tooltipApi.requestTooltipInfo();
      setData(results.data);
     // setRequestDataComplete(true);
     
    } catch (e) {
      if (e.response && e.response.status) {
        setDataNotFound(true);
      }
    }
  };
  useEffect(() => {
    getSearchData();
  }, []);

  return (
    <>
      <TooltipSections
        data={data.data}
      />
    </>
  );
};

export default TooltipsInnerDetails;
