/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useReducer, ReactElement } from 'react';
import resourceReducer from '../reducer';

const DEFAULT_RESOURCE_CONTEXT = {
  searchText: ' ',
  resources: [],
  currentPage: 1,
  numPages: 1,
  category: ' ',
  categories: [],
};

export const ResourceContext = createContext<any>(DEFAULT_RESOURCE_CONTEXT);

export const ResourceContextProvider = (props: {
  children: ReactElement;
}): ReactElement => {
  const { children } = props;
  const [resourceState, dispatch] = useReducer(
    resourceReducer,
    DEFAULT_RESOURCE_CONTEXT,
  );

  const dispatchAction = (action: any) => {
    dispatch({ type: action.type, payload: action.payload });
  };
  const resourceContext = {
    dispatch: dispatchAction,
    resourceState,
  };
  return (
    <ResourceContext.Provider value={resourceContext}>
      {children}
    </ResourceContext.Provider>
  );
};
