import React from 'react';
import { Container, Row, Col } from 'reactstrap';


const FaqBanner = (props: any) => {
  const { title } = props;
  if (title) {
    return (
      <section className="source-methology-main-banner">
        <Container>
          <Row>
            <Col lg="12 text-center">
              <h1>{title}</h1>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
  return <></>;
};
export default FaqBanner;
