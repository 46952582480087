import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from 'reactstrap';

import UseProductOverview from './UseProductOverview';
import KeyValueDataTable from '../../../../components/common/KeyValueDataTable';
import ProductOverviewManufacturer from '../../components/ProductOverviewManufacturer';
import ProductOverviewOtherNames from '../../components/ProductOverviewOtherNames';
import TableCardTwoColumn from '../../components/TableCardTwoColumn';

const ProductOverview = (props: any) => {
  const url = new URL(window.location.href);
  const productRegno = url.searchParams.get('regno')!;
  const productDistno = url.searchParams.get('distno')!;
  const { setBadActor, setProductName, setHighlyHazardous } = props;

  const values = UseProductOverview(productRegno, productDistno);
  /*   const {
    summary,
    manufacturer,
    distributor,
    otherNames
  } = values; */
  const { data } = values;
  setProductName(data.productName);
  setBadActor(data.badActor);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <TabPane className="prod_overview" tabId="1">
        <KeyValueDataTable
          tableHeading="SUMMARY"
          tableData={data.data.summary}
          tableSection = "product"
        />
        <ProductOverviewManufacturer
          manufacturer={data.data.manufacturer}
          distributor={data.data.distributor}
        />
        <TableCardTwoColumn
          title={`OTHER NAMES FOR ${data.productName}`}
          list={data.data.otherNames.otherNames}
          productName={data.productName}
          count={data.data.otherNames.metaData.total}
        />
      </TabPane>
    </>
  );
};
export default ProductOverview;
