/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';
import { searchApiPrefix } from '../constants';

const requestOverviewContent = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=overview`);
};


const sendContactEmail = (
  name: any,
  email: any,
  subject: any,
  message:any
): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/sendcontactmail`, {
    name: name,
    email: email,
    subject: subject,
    message: message
  });
};

const overviewApi = {
  requestOverviewContent,
  sendContactEmail,
};


export default overviewApi;
