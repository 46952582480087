/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PestNameAutoCompleteContainer from '../containers/PestNameAutoCompleteContainer';
import CommodityAutoCompleteContainer from '../containers/CommodityAutoCompleteContainer';

const PestFilter = (props: any) => {
  const {
    data,
    setPestList,
    setbuttonActive,
    setPestName,
    setComodity,
    pestName,
    comodity,
    setIsSearchClicked,
    selectedValues,
    setSelectedValues,
  } = props;
  const [targetPestActiveId, setTargetPestActiveId] = useState();

  const [PestListActiveId, setPestListActiveId] = useState();

  useEffect(() => {
    const newSelectedValues = selectedValues.filter((obj: any) => {
      return obj.name !== 'pestName';
    });
    newSelectedValues.push({
      name: 'pestName',
      value: pestName,
    });
    setSelectedValues(newSelectedValues);
  }, [pestName]);

  useEffect(() => {
    const newSelectedValues = selectedValues.filter((obj: any) => {
      return obj.name !== 'comodity';
    });
    newSelectedValues.push({
      name: 'comodity',
      value: comodity,
    });
    setSelectedValues(newSelectedValues);
  }, [comodity]);

  const togglePestListItem = (parentIndex: number, childIndex: number) => {
    const pestList = [...data];
    const isSelected = pestList[parentIndex].subItems[childIndex].selected;
    if (!isSelected) {
      pestList[parentIndex].selected = true;
      pestList[parentIndex].noOfSelected = 1;
      // eslint-disable-next-line no-empty
      if (pestList[parentIndex].selectedElementIndex > -1) {
        pestList[parentIndex].subItems[
          pestList[parentIndex].selectedElementIndex
        ].selected = false;
      }
      pestList[parentIndex].subItems[childIndex].selected = true;
      pestList[parentIndex].selectedElementIndex = childIndex;

      const newSelectedValues = selectedValues.filter((obj: any) => {
        return obj.name !== 'pestList';
      });
      newSelectedValues.push({
        name: 'pestList',
        itemLabel: pestList[parentIndex].itemLabel,
        itemValue: pestList[parentIndex].itemValue,
        parentIndex,
        childIndex,
        noOfSelected: pestList[parentIndex].noOfSelected,
        subItemsItemLabel: pestList[parentIndex].subItems[childIndex].itemLabel,
        subItemsItemValue: pestList[parentIndex].subItems[childIndex].itemValue,
      });
      setSelectedValues(newSelectedValues);
    } else {
      pestList[parentIndex].selected = false;
      pestList[parentIndex].noOfSelected = 0;
      pestList[parentIndex].subItems[childIndex].selected = false;
      pestList[parentIndex].selectedElementIndex = 0;
      const newSelectedValues = selectedValues.filter((obj: any) => {
        return (
          obj.subItemsItemValue !==
          pestList[parentIndex].subItems[childIndex].itemValue
        );
      });
      setSelectedValues(newSelectedValues);
    }

    pestList[parentIndex].selectedElementIndex = childIndex;
    setPestList(pestList);
  };
  return (
    <div className="filter-search">
      <div className="title">PEST & USES</div>

      <ul className="filter">
        <li className="item-search">
          {/* <input type="search" name="" placeholder="Pest Name" /> */}
          <PestNameAutoCompleteContainer
            setPestName={setPestName}
            pestName={pestName}
            setIsSearchClicked={setIsSearchClicked}
          />
        </li>
        <li className="item-search">
          {/* <input type="search" name="" placeholder="Commodity (Crop) or Site" /> */}
          <CommodityAutoCompleteContainer
            setComodity={setComodity}
            comodity={comodity}
            setIsSearchClicked={setIsSearchClicked}
          />
        </li>
        {data.length > 0 ? (
          <>
            {data.map((item: any, key: number) => (
              <li
                key={`item-${key}`}
                className={`${targetPestActiveId !== key ? 'collapsed' : ''} ${
                  item.noOfSelected > 0 ? 'selected' : ''
                }`}
                onClick={(): void => {
                  if (targetPestActiveId === key) {
                    setTargetPestActiveId('');
                  } else {
                    setTargetPestActiveId(key);
                  }
                }}
                onKeyDown={(): void => {
                  if (targetPestActiveId === key) {
                    setTargetPestActiveId('');
                  } else {
                    setTargetPestActiveId(key);
                  }
                }}
              >
                {item.itemLabel}
                <ul>
                  {item.subItems.map((itemSub: any, innerKey: number) => (
                    <li
                      key={`item-${innerKey}`}
                      className={`${itemSub.selected ? 'selected' : ''}`}
                      onClick={(e): void => {
                        e.stopPropagation();
                        togglePestListItem(key, innerKey);
                        setbuttonActive(true);
                        setIsSearchClicked(false);
                      }}
                    >
                      {itemSub.itemLabel}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </>
        ) : (
          ''
        )}
      </ul>
    </div>
  );
};
export default PestFilter;
