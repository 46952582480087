/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

const ChemicalTypeFilter = (props: any) => {
  const {
    chemicalTypes,
    selectedChemicalType,
    setSelectedChemicalType,
    selectedValues,
    setSelectedValues,
    setIsSearchClicked,
  } = props;
  const [chemicalTypeActiveId, setChemicalTypeActiveId] = useState();

  return (
    <div className="chemical-type-filter">
      <div className="title">CHEMICAL TYPE</div>
      <ul className="filter">
        {chemicalTypes.data.map((item: any, key: number) => (
          <li
            key={`item-${key}`}
            // data-pest-parent={`chemical-${key}`}
            className={`${
              selectedChemicalType.chemicalTypeParent === `chemical-${key}`
                ? 'selected'
                : ''
            } ${chemicalTypeActiveId !== key ? 'collapsed' : ''}`}
            onClick={(): void => {
              if (chemicalTypeActiveId === key) {
                setChemicalTypeActiveId('');
              } else {
                setChemicalTypeActiveId(key);
              }
            }}
            onKeyDown={(): void => {
              if (chemicalTypeActiveId === key) {
                setChemicalTypeActiveId('');
              } else {
                setChemicalTypeActiveId(key);
              }
            }}
          >
            {item.itemLabel}
            <ul>
              {item.subItems.map((subItem: any, innerKey: number) => (
                <li
                  key={`item-${innerKey}`}
                  // data-chemical-parent={`chemical-${key}-${innerKey}`}
                  className={`${
                    selectedChemicalType.chemicalType ===
                    `chemical-${key}-${innerKey}`
                      ? 'selected'
                      : ''
                  }`}
                  onClick={(e): void => {
                    setIsSearchClicked(false);
                    e.stopPropagation();
                    const newSelectedValues = selectedValues.filter(
                      (obj: any) => {
                        return obj.name !== 'type';
                      },
                    );
                    if (
                      selectedChemicalType.chemicalType ===
                      `chemical-${key}-${innerKey}`
                    ) {
                      setSelectedChemicalType({
                        chemicalTypeParent: '',
                        chemicalType: '',
                        chemicalTypeLabel: '',
                        itemValue: '',
                      });
                    } else {
                      setSelectedChemicalType({
                        chemicalTypeParent: `chemical-${key}`,
                        chemicalType: `chemical-${key}-${innerKey}`,
                        chemicalTypeLabel: `${subItem.itemLabel}`,
                        itemValue: `${subItem.itemValue}`,
                      });
                      newSelectedValues.push({
                        name: 'type',
                        chemicalTypeParent: `chemical-${key}`,
                        chemicalType: `chemical-${key}-${innerKey}`,
                        chemicalTypeLabel: `${subItem.itemLabel}`,
                        itemValue: `${subItem.itemValue}`,
                      });
                    }
                    setSelectedValues(newSelectedValues);
                  }}
                >
                  {subItem.itemLabel}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ChemicalTypeFilter;
