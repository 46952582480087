import { useEffect, useState } from 'react';
import sideBarApi from '../api/sideBarApi';

const useSideBarList = () => {
  const [data, setData] = useState({
    blog_posts: [],
  });

  useEffect(() => {
    const getSideBarData = async () => {
      try {
        const results = await sideBarApi.requestSideBarPosts();
        setData(results.data);
      } catch (e) {}
    };
    getSideBarData();
  }, []);
  return data && data;
};

export default useSideBarList;
