/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CompanyNameAutoCompleteContainer from '../containers/CompanyNameAutoCompleteContainer';
import RegNoAutoCompleteContainer from '../containers/RegNoAutoCompleteContainer';
import ProductNameCompleteContainer from '../containers/ProductNameCompleteContainer';

const ProductFilter = (props: any) => {
  const {
    data,
    setRegYearList,
    setbuttonActive,
    setProductName,
    setCompanyName,
    setRegNumber,
    productName,
    companyName,
    regNumber,
    setIsSearchClicked,
    selectedValues,
    setSelectedValues,
  } = props;
  const [regYearActiveId, setregYearActiveId] = useState();

  useEffect(() => {
    const newSelectedValues = selectedValues.filter((obj: any) => {
      return obj.name !== 'productName';
    });
    newSelectedValues.push({
      name: 'productName',
      value: productName,
    });
    setSelectedValues(newSelectedValues);
  }, [productName]);

  useEffect(() => {
    const newSelectedValues = selectedValues.filter((obj: any) => {
      return obj.name !== 'companyName';
    });
    newSelectedValues.push({
      name: 'companyName',
      value: companyName,
    });
    setSelectedValues(newSelectedValues);
  }, [companyName]);

  useEffect(() => {
    const newSelectedValues = selectedValues.filter((obj: any) => {
      return obj.name !== 'regNumber';
    });
    newSelectedValues.push({
      name: 'regNumber',
      value: regNumber,
    });
    setSelectedValues(newSelectedValues);
  }, [regNumber]);

  const toggleRegYearListItem = (parentIndex: number, childIndex: number) => {
    const regYearList = [...data];
    const isSelected = regYearList[parentIndex].subItems[childIndex].selected;

    if (!isSelected) {
      regYearList[parentIndex].selected = true;
      regYearList[parentIndex].noOfSelected = 1;
      // eslint-disable-next-line no-empty
      if (regYearList[parentIndex].selectedElementIndex > -1) {
        regYearList[parentIndex].subItems[
          regYearList[parentIndex].selectedElementIndex
        ].selected = false;
      }
      regYearList[parentIndex].subItems[childIndex].selected = true;
      regYearList[parentIndex].selectedElementIndex = childIndex;

      const newSelectedValues = selectedValues.filter((obj: any) => {
        return obj.name !== 'regYearList';
      });
      newSelectedValues.push({
        name: 'regYearList',
        itemLabel: regYearList[parentIndex].itemLabel,
        itemValue: regYearList[parentIndex].itemValue,
        parentIndex,
        childIndex,
        noOfSelected: regYearList[parentIndex].noOfSelected,
        subItemsItemLabel:
          regYearList[parentIndex].subItems[childIndex].itemLabel,
        subItemsItemValue:
          regYearList[parentIndex].subItems[childIndex].itemValue,
      });
      setSelectedValues(newSelectedValues);
    } else {
      regYearList[parentIndex].selected = false;
      regYearList[parentIndex].noOfSelected = 0;
      regYearList[parentIndex].subItems[childIndex].selected = false;
      regYearList[parentIndex].selectedElementIndex = 0;

      const newSelectedValues = selectedValues.filter((obj: any) => {
        return (
          obj.subItemsItemValue !==
          regYearList[parentIndex].subItems[childIndex].itemValue
        );
      });
      setSelectedValues(newSelectedValues);
    }

    regYearList[parentIndex].selectedElementIndex = childIndex;
    setRegYearList(regYearList);
  };
  return (
    <div className="filter-search">
      <div className="title">PRODUCT INFO</div>
      <ul className="filter">
        <li className="item-search">
          <ProductNameCompleteContainer
            setProductName={setProductName}
            productName={productName}
            setIsSearchClicked={setIsSearchClicked}
          />
        </li>
        <li className="item-search">
          <CompanyNameAutoCompleteContainer
            setCompanyName={setCompanyName}
            companyName={companyName}
            setIsSearchClicked={setIsSearchClicked}
          />
        </li>
        <li className="item-search">
          <RegNoAutoCompleteContainer
            setRegNumber={setRegNumber}
            regNumber={regNumber}
            setIsSearchClicked={setIsSearchClicked}
          />
        </li>
        {data.length > 0 ? (
          <>
            {data.map((item: any, key: number) => (
              <li
                key={`item-${key}`}
                className={`${regYearActiveId !== key ? 'collapsed ' : ''}${
                  item.noOfSelected > 0 ? 'selected' : ''
                }`}
                onClick={(): void => {
                  if (regYearActiveId === key) {
                    setregYearActiveId('');
                  } else {
                    setregYearActiveId(key);
                  }
                }}
                onKeyDown={(): void => {
                  if (regYearActiveId === key) {
                    setregYearActiveId('');
                  } else {
                    setregYearActiveId(key);
                  }
                }}
              >
                {item.itemLabel}
                <ul>
                  {item.subItems.map((itemSub: any, innerKey: number) => (
                    <li
                      key={`item-${innerKey}`}
                      className={`${itemSub.selected ? 'selected' : ''}`}
                      onClick={(e): void => {
                        e.stopPropagation();
                        setIsSearchClicked(false);
                        toggleRegYearListItem(key, innerKey);
                        setbuttonActive(true);
                      }}
                    >
                      {itemSub.itemLabel}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </>
        ) : (
          ''
        )}
      </ul>
    </div>
  );
};
export default ProductFilter;
