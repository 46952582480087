import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PostBlock from '../../../components/common/PostBlock';

const RelatedPosts = (props: any): ReactElement => {
  const { relatedPosts } = props;
  return (
    <section>
      <Container className="common-inner-related-articles">
        <Row>
          <Col sm="12">
            <h2>You may also be interested in...</h2>
          </Col>
          {relatedPosts.map((item: any, key: number) => (
            <Col md="4" key={key}>
              <PostBlock type="blog-small blog-related">
              <a href={item.slug}>
                  <div className="post-block__featured-image">
                    <img src={item.relatedPostThumbnail} alt="" />
                  </div>
                  <div className="post-block__description">
                    <h4>{item.customDate}</h4>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: item.relatedPostTitle,
                      }}
                    ></h3>
                    <p>{item.relatedPostExcerpt}</p>
                    <span>
                    {!!item.relatedPostLinkText
                        ? item.relatedPostLinkText
                        : 'Read More'}
                    </span>
                  </div>
                </a>
              </PostBlock>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
export default RelatedPosts;
