import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useCropDetailTopCounties = (id: string) => {
  const [data, setData]: any = useState({
    data: {
      pesticideCount: '',
      list: [],
    },
  });

  useEffect(() => {
    const getCaliforniaCropDetailsTopCountiesData = async () => {
      try {
        const results = await californiaSearchApi.requestCaliforniaCropDetailTopCounties(
          id,
        );
        if (results.data) {
          setData(results.data.data);
        }
      } catch (e) {}
    };
    getCaliforniaCropDetailsTopCountiesData();
  }, []);

  return data && data;
};

export default useCropDetailTopCounties;
