import React from 'react';
import TableViewMore from '../../../components/common/TableViewMore';
import SearchResultsDownload from '../../../components/common/SearchResultsDownload';

const TopCrops = (props: any) => {
  const { topCropsandSitesFull, total, topCropsandSitesDataLimit } = props;
  const title = 'Top 50 Crops & Sites';
  const tableTitles = [
    {
      label: 'Crop or Site',
      field: 'Commodity_Name',
    },

    {
      label: 'Gross Pounds',
      field: 'Gross_Lbs',
    },
    {
      label: 'Application Rate',
      field: 'Appl_Rate',
    },
    {
      label: 'Acres Planted',
      field: 'Sumacre_plant',
    },
    {
      label: 'Acres Treated',
      field: 'Sumacre_treat',
    },
    {
      label: 'Application Count',
      field: 'CntApplics',
    },
  ];
  const tableItemLink = {
    linkedTo: '/california-pesticide-use/california-crop-detail?sk=',
    field: 'site_code',
  };

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }
   
  }

  return (
    <>
      <div className="search-result-table top-fifty-crops">
        <div className="result-table">
          <div className="result-table-row titles">
            <div>
              <div>Crop or Site</div>
              <div>Gross Pounds</div>
              <div>Application Rate (lbs a.i./acre)</div>
              <div>Acres Planted</div>
              <div>Acres Treated</div>
              <div>Application Count</div>
            </div>
          </div>
          {topCropsandSitesDataLimit.map((item: any, key: number) => (
            <div className="result-table-row">
              <a
                href={`/california-pesticide-use/california-crop-detail?sk=${item.site_code}`}
              >
                <div>
                  <h3>{item.Commodity_Name}</h3>
                </div>
                <div>{decimalFormat(item.Gross_Lbs.replace(/,/g, ""),1)}</div>
                <div>{decimalFormat(item.Appl_Rate.replace(/,/g, ""),2)}</div>
                <div>{decimalFormat(item.Sumacre_plant.replace(/,/g, ""),1)}</div>
                <div>{decimalFormat(item.Sumacre_treat.replace(/,/g, ""),1)}</div>
                <div>{item.CntApplics}</div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <TableViewMore
        items={topCropsandSitesFull}
        title={title}
        tableColumnTitles={tableTitles}
        tableItemLink={tableItemLink}
        total={total}
      />
      <SearchResultsDownload
        csvDownloadPath={`californiaTopCropsDownload?sort=Gross_Lbs&order=DESC&download=csv`}
        pdfDownloadPath={`californiaTopCropsDownload?sort=Gross_Lbs&order=DESC&download=pdf`}
        />
    </>
  );
};
export default TopCrops;
