import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ResourceContext } from '../../../store/resource/context';
import useWindowDimensions from '../../../containers/useWindowDimensions ';

const ResourceCategories = (props: any) => {
  // const { dispatch, resourceState } = useContext(ResourceContext);
  const { height, width } = useWindowDimensions();
  const { resourceCategories, linkClick, activeCategoryId } = props;
  if (resourceCategories !== undefined) {
    // resourceCategories.sort((a: any, b: any) => {
    //   return b.term_id - a.term_id;
    // }); // resourceState.categories = resourceCategories;
    if (width > 1200) {
      return (
        <Container>
          <Row className="resource-categories-bar">
            <Col sm="12">
              <div>
                <Link
                  className={activeCategoryId === ' ' ? 'active' : ''}
                  to="/resources"
                  onClick={(e) => {
                    /* const unorderedList = document.querySelectorAll(
                      '.resource-categories-bar div a',
                    ); */
                    /* unorderedList.forEach((node) => {
                      node.classList.remove('active');
                    });
                    e.currentTarget.classList.add('active'); */
                    linkClick(e, ' ');
                  }}
                >
                  All
                </Link>
                {resourceCategories.map((category: any, i: number) => (
                  <Link
                    key={i}
                    className={
                      activeCategoryId === category.term_id ? 'active' : ''
                    }
                    dangerouslySetInnerHTML={{ __html: category.term_name }}
                    to={`/resources/category/${category.term_slug}`}
                    onClick={(e) => {
                      linkClick(e, category.term_id);
                    }}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container>
        <Row className="resource-categories-bar">
          <Col sm="12">
            <select
              name="categories"
              id="categories"
              onChange={(e) => {
                linkClick(e, e.target.value);
              }}
            >
              <option value=" ">All</option>
              {resourceCategories.map((category: any, i: number) => (
                <option
                  key={i}
                  value={category.term_id}
                  dangerouslySetInnerHTML={{ __html: category.term_name }}
                ></option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>
    );
  }
  return <></>;
};
export default ResourceCategories;
