import React, { ReactElement, useState, useEffect } from 'react';
import productApi from '../../../../api/productApi';
import KeyValueDataTableWithSearchWithLinks from '../../../../components/common/KeyValueDataTableWithSearchWithLinks';

const UsageProductContainer = (props: any): ReactElement => {
  const { tableHeading, tableData, resultCount, productName, badActor } = props;
  const url = new URL(window.location.href);
  const productRegno = url.searchParams.get('regno')!;
  const [modal, setModal] = useState(false);
  const [list, setList] = useState({
    metaData: { totalPests: 0 },
    items: [],
  });
  const closeClick = () => {
    setModal(false);
  };
  const [tableItems, setTableItems] = useState(tableData);
  const [tableResultCount, setTableResultCount] = useState(tableData);
  const [finalSearchValue, setFinalSearchValue] = useState('');
  const [searchInnerValue, setSearchInnerValue] = useState('');
  const [suggestions, setSuggestions]: any = useState([]);

  const productViewMore = async () => {
    setModal(!modal);
    let results;
    try {
      results = await productApi.requestPestSuggestions(
        productRegno,
        finalSearchValue,
        1000,
      );
    } catch (e) {}

    setList({
      metaData: results.data.data.metaData
        ? results.data.data.metaData
        : { totalPests: 0 },
      items: results.data.data.pests ? results.data.data.pests : [],
    });
  };
  const getsuggestions = async () => {
    try {
      const results = await productApi.requestPestSuggestions(
        productRegno,
        searchInnerValue,
        1000,
      );
      const tempSuggestions: any = [];
      // setTableItems(results.data.data.products);
      results.data.data.pests.map((item: any, index: number) => {
        tempSuggestions.push({
          suggestion: item.PROD_NAME,
        });
      });
      setSuggestions(tempSuggestions);
    } catch (e) {}
  };

  useEffect(() => {
    setTableItems(tableData);
  }, [tableData]);

  useEffect(() => {
    setTableResultCount(resultCount);
  }, [resultCount]);

  useEffect(() => {
    const getFilteredTableData = async () => {
      try {
        const results = await productApi.requestPestSuggestions(
          productRegno,
          finalSearchValue,
          8,
        );
        setTableItems(results.data.data.pests);
        setTableResultCount(results.data.data.metaData.totalPests);
      } catch (e) {}
    };
    getFilteredTableData();
  }, [finalSearchValue]);

  return (
    <>
      <KeyValueDataTableWithSearchWithLinks
        tableHeading={tableHeading}
        tableData={tableItems}
        resultCount={tableResultCount}
        itemName={productName}
        badActor={badActor}
        list={list}
        itemKey="PROD_NAME"
        count="totalPests"
        modal={modal}
        closeClick={closeClick}
        viewMore={productViewMore}
        searchInnerValue={searchInnerValue}
        setSearchInnerValue={setSearchInnerValue}
        suggestions={suggestions}
        getsuggestions={getsuggestions}
        setFinalSearchValue={setFinalSearchValue}
        link="/advance-search/product-persist?pest="
      />
    </>
  );
};
export default UsageProductContainer;
