import React, { ReactElement } from 'react';
import PostBlock from './PostBlock';

const SideBar = (props: any): ReactElement => {
  const {
    sideBarPosts,
    catUrl,
    ctaDescription,
    ctaImage,
    ctaTitle,
    ctaUrlTitle,
  } = props;
  return (
    <div className="results-side-bar">
      <PostBlock type="sidebar-articles">
        <a href={catUrl} target="_blank" rel="noopener noreferrer">
          <div className="post-block__featured-image">
            <img src={ctaImage} alt="" />
          </div>
          <div className="post-block__description">
            <h3>{ctaTitle}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: ctaDescription,
              }}
            ></div>
            <span>{ctaUrlTitle}</span>
          </div>
        </a>
      </PostBlock>
      {sideBarPosts.map((item: any, key: number) => (
        <PostBlock type="sidebar-articles" key={key}>
          <a href={`/blog/${item.name}`} rel="noopener noreferrer">
            <div className="post-block__featured-image">
              <img src={item.featured_image} alt="" />
            </div>
            <div className="post-block__description">
              <h3>{item.title}</h3>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.excerpt,
                  }}
                />
              </div>
              <span>{item.readMoreLinkText}</span>
            </div>
          </a>
        </PostBlock>
      ))}
    </div>
  );
};
export default SideBar;
