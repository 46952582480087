import React, { useEffect, useState } from 'react';
import PestFilter from '../components/PestFilter';
import usePestContent from './UsePestContainer';

const PestContainer = (props: any) => {
  const pest = usePestContent();
  const {
    PestList,
    setPestList,
    setbuttonActive,
    setPestName,
    setComodity,
    pestName,
    comodity,
    setIsSearchClicked,
    selectedValues,
    setSelectedValues,
  } = props;

  const [pestProps, setpestProps] = useState();
  const [noOfPestListSelected, setNoOfPestListSelected] = useState(0);

  useEffect(() => {
    setpestProps(pest);
  }, []);
  return (
    <>
      <PestFilter
        setPestList={setPestList}
        PestList={PestList}
        noOfPestListSelected={noOfPestListSelected}
        setNoOfPestListSelected={setNoOfPestListSelected}
        data={pest}
        setbuttonActive={setbuttonActive}
        setPestName={setPestName}
        setComodity={setComodity}
        pestName={pestName}
        comodity={comodity}
        setIsSearchClicked={setIsSearchClicked}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
      />
    </>
  );
};
export default PestContainer;
