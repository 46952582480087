import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useCaliforniaChemicals = () => {
  const [data, setData]: any = useState([]);

  useEffect(() => {
    const getCaliforniaChemicalsData = async () => {
      try {
        const results = await californiaSearchApi.requestCaliforniaChemicalsList();
        // if (results.data) {
        //   const chemList: any = [];
        //   results.data.data.chemicals.map((chemical: any) => {
        //     chemList.push({
        //       label: chemical.value,
        //       value: chemical.key,
        //     });
        //   });

        //   setData([...chemList]);
        // }
        if (results.data) {
          let groupCount = 0;
          const chemList: any = [];
          results.data.data.chemicals.map((chemical: any, index: number) => {
            if (chemical.key === '') {
              chemList.push({
                label: chemical.value,
                options: [],
              });
            }
          });
          results.data.data.chemicals.map((chemical: any, index: number) => {
            if (chemical.key === '') {
              if (index > 1) {
                groupCount += 1;
              }
            } else {
              chemList[groupCount].options.push({
                label: chemical.value,
                value: chemical.key,
              });
            }
          });
          chemList.unshift({
            label: 'All Categories',
            options: [
              {
                label: 'All',
                value: 'all',
              },
            ],
          });
          setData([...chemList]);
        }
      } catch (e) {}
    };
    getCaliforniaChemicalsData();
  }, []);

  return data && data;
};

export default useCaliforniaChemicals;
