import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';


const requestNewsletterForm = (): Promise<any> => {
  return axiosApi.get(`https://actionnetwork.org/api/v2/forms/a2286329-8e94-4337-980b-bd9fe2025e14/embed`);
};

const newsletterApi = {
  requestNewsletterForm
};

export default newsletterApi;
