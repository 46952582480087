import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const SourceMethologyTextBlok = (props: any) => {
  const { content } = props;
  const { rendered } = content;
  return (
    <section className="source-methology-text-block text-block">
      <Container>
        <Row className="justify-content-center">
          {rendered && rendered !== '' ? (
            <Col
              sm="10 text-center"
              dangerouslySetInnerHTML={{ __html: rendered }}
            />
          ) : (
            <Col sm="10 text-center" />
          )}
        </Row>
      </Container>
    </section>
  );
};
export default SourceMethologyTextBlok;
