/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchForm from '../components/SearchForm';
import { SearchContext } from '../../../store/search/context';
import { UPDATE_SIMPLE_SEARCH_VALUE } from '../../../store/search/actionType';

const SearchBlock = (props: any) => {
  const history = useHistory();
  const { dispatch, searchState } = useContext(SearchContext);
  const [showTextInvalidError, setShowTextInvalidError] = useState(false);
  // let showError = '';

  const searchHandleSubmit = (
    e: any,
    searchValue: string,
    searchType: string,
    page: string,
    sort: string,
    order: string,
  ) => {
    const inputValue = searchValue.trim();
    // const inputRegex = /^[a-zA-Z0-9- ]+$/g;
    e.preventDefault();
    // dispatch({ type: UPDATE_SIMPLE_SEARCH_TYPE, payload: searchType });
    // if (inputRegex.test(inputValue)) {
    if (inputValue !== '') {
      dispatch({
        type: UPDATE_SIMPLE_SEARCH_VALUE,
        payload: { searchType, searchValue, page, sort, order },
      });

      history.push(
        `/search-result?page=${page}&sort=${sort}&order=${order}&search=${searchValue}&type=${searchType}`,
      );
    } else {
      setShowTextInvalidError(true);
      // showError = 'invalid';
      dispatch({
        type: UPDATE_SIMPLE_SEARCH_VALUE,
        payload: { searchType, searchValue: '', page, sort, order },
      });
    }
  };

  return (
    <section className="home-search-container">
      <div className="container">
        <div className="row">
          <div className="col-md-10">
            <SearchForm
              searchHandleSubmit={searchHandleSubmit}
              showTextInvalidError={showTextInvalidError}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default SearchBlock;
