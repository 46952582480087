/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import GlobalBanMapBreadcrumb from '../components/GlobalBanMapBreadcrumb';
import TextBlock from '../components/TextBlock';
import SideBarContainer from '../../../containers/SideBarContainer';
import LogoItems from '../components/LogoItems';
import useGlobalBanMapContent from './useGlobalBanMapContent';
import BanMap from '../components/BanMap';
import MapFilters from '../components/MapFilters';
import useGlobalBanMapCountryList from './useGlobalBanMapCountryList';
import EmbedWidget from '../components/EmbedWidget';
import CountryPopup from '../components/CountryPopup';
import globalBanMapApi from '../../../api/globalBanMapApi';
import ChemicalDetials from '../components/ChemicalDetials';
import MapZoom from '../components/MapZoom';

const GlobalBanMapContainer = (props: any) => {
  const url = window.location.href;

  const globalBanMapProps = useGlobalBanMapContent();
  const globalBanCountryProps = useGlobalBanMapCountryList();

  const { list } = globalBanCountryProps;
  const { content, acf } = globalBanMapProps;

  const [isEmbed, setIsEmbed] = useState(false);

  const [countries, setCountries] = useState([['Country', 'Name']]);
  const [isPopup, setIsPopup] = useState(false);
  const [isChemicalDetails, setIsChemicalDetails] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState('');

  const [selectedCountryData, setSelectedCountryData] = useState({
    meteData: {
      countryName: '',
      totalBannedChemicals: 0,
      otherPesticides: 0,
      hhpsBanned: '',
    },
    list: [],
  });

  const [selectedChemical, setSelectedChemical] = useState('');
  const [selectedChemicalData, setSelectedChemicalData]: any = useState({
    meteData: {
      totalCountries: 0,
      chemicalName: '',
    },
    list: [],
  });

  const [zoom, setZoom] = useState(0);
  const [zoomClass, setZoomClass] = useState('zoom-0');

  useEffect(() => {
    setSelectedCountryData({
      meteData: {
        countryName: '',
        totalBannedChemicals: 0,
        otherPesticides: 0,
        hhpsBanned: '',
      },
      list: [],
    });
    const getSelectedCountryData = async () => {
      try {
        const results = await globalBanMapApi.globalBanMapSelectedCountryChemical(
          selectedCounty,
          '',
        );

        setSelectedCountryData(results.data.data);
      } catch (e) {}
    };
    getSelectedCountryData();
  }, [selectedCounty]);

  useEffect(() => {
    setSelectedChemicalData({
      meteData: {
        totalCountries: 0,
        chemicalName: '',
      },
      list: [],
    });
    if (selectedChemical !== '') {
      const getSelectedChemicalData = async () => {
        try {
          const results = await globalBanMapApi.globalBanMapSelectedCountryChemical(
            '',
            selectedChemical,
          );

          setSelectedChemicalData(results.data.data);
          if (results.data.data.list.length > 0) {
            const bannedCountries = [['Country', 'Name']];
            results.data.data.list.map((country: any): void => {
              if (country.country === 'USA') {
                bannedCountries.push(['United States', 'United States']);
              } else if (country.country === 'UK') {
                bannedCountries.push(['United Kingdom', 'United Kingdom']);
              } else {
                var countryName = country.country;
                countryName = countryName.replace(" (EU)", "");
                countryName = countryName.replace(" (EU+)", "");
                bannedCountries.push([countryName, countryName]);
              }
            });
            setCountries(bannedCountries);
            setIsChemicalDetails(true);
          }
        } catch (e) {}
      };
      getSelectedChemicalData();
    }
  }, [selectedChemical]);

  useEffect(() => {
    if (url.indexOf('embed') > 0) {
      setIsEmbed(true);
    }
  }, []);

  return (
    <>
      <section className={`${isEmbed ? 'embed' : ''} global-ban-map-data`}>
        <Container>
          <Row>
            <Col lg={isEmbed ? '12' : '9'}>
              <CountryPopup
                isPopup={isPopup}
                setIsPopup={setIsPopup}
                selectedCountryData={selectedCountryData}
              />
              {!isEmbed ? <GlobalBanMapBreadcrumb /> : ''}

              {!isEmbed ? <TextBlock content={content.rendered} /> : ''}

              <MapZoom
                setZoom={setZoom}
                setZoomClass={setZoomClass}
                zoom={zoom}
              />
              <MapFilters
                countryList={list}
                setCountries={setCountries}
                setSelectedChemical={setSelectedChemical}
                setIsPopup={setIsPopup}
                setIsChemicalDetails={setIsChemicalDetails}
                setSelectedCounty={setSelectedCounty}
              />
              <BanMap
                countries={countries}
                setIsPopup={setIsPopup}
                isPopup={isPopup}
                setSelectedCounty={setSelectedCounty}
                zoomClass={zoomClass}
                selectedChemical={selectedChemical}
              />
              <ChemicalDetials
                selectedChemicalData={selectedChemicalData}
                isChemicalDetails={isChemicalDetails}
              />

              {!isEmbed ? <EmbedWidget /> : ''}

              <LogoItems
                logoItems={acf.globalBanLogoItems}
                logHeading={acf.logoItemHeading}
              />
            </Col>
            {!isEmbed ? (
              <Col lg="3">
                <SideBarContainer />
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};
export default GlobalBanMapContainer;
