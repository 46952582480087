import React from 'react';
import Helmet from 'react-helmet';
import SearchBlock from '../containers/SearchBlock';
import DonateWithWebList from '../containers/DonateWithWebList';

const Home = (props: any) => {
  return (
    <div>
      <Helmet>
        <title>PesticideInfo | Home</title>
        <meta name="description" content="Pesticide Info" />
      </Helmet>

      <SearchBlock />
      <DonateWithWebList />
    </div>
  );
};
export default Home;
