import React from 'react';
import useRegulationTabContent from './useRegulationTab';
import KeyValueDataTable from '../../../components/common/KeyValueDataTable';

const RegulationTab = (props: any) => {
  const chemicalId = window.location.pathname.split('chemical/')[1];
  const values = useRegulationTabContent(chemicalId);
  const {
    internationalRegulatoryStatus,
    usRegulatoryStatus,
    californiaRegulatoryStatus,
    allowableResidueLevels
  } = values;
  return (
    <>
      <KeyValueDataTable
        tableHeading="INTERNATIONAL REGULATORY STATUS"
        tableData={internationalRegulatoryStatus}
      />
      <KeyValueDataTable
        tableHeading="U.S. REGULATORY STATUS"
        tableData={usRegulatoryStatus}
      />
      <KeyValueDataTable
        tableHeading="CALIFORNIA REGULATORY STATUS"
        tableData={californiaRegulatoryStatus}
      />
      <KeyValueDataTable
        tableHeading="ALLOWABLE RESIDUE LEVELS"
        tableData={allowableResidueLevels}
      />
    </>
  );
};
export default RegulationTab;
