import React from 'react';
import Helmet from 'react-helmet';
import FaqContent from '../containers/FaqContent';
import FaqBreadcrumb from '../components/FaqBreadcrumb';

const Faq = (props: any) => {
  return (
    <div>
      <Helmet>
        <title>PesticideInfo | Frequently Asked Questions</title>
        <meta name="description" content="Frequently Asked Questions" />
      </Helmet>
      <FaqBreadcrumb />
      <FaqContent />
    </div>
  );
};
export default Faq;
