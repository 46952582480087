import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AutoComplete from '../../../components/common/AutoComplete';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';

const SearchResultSearchForm = (props: any) => {
  const {
    resultsSearchHandleSubmit,
    showTextInvalidError,
    type,
    setSort,
  } = props;
  const searchinput: any = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState(type);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState();

  const getSearchAutoCompleteSuggestions = async (search: any) => {
    // console.log(searchTerm);
    try {
      const results = await advanceSearchProductApi.requestAdvanceSearchProductNameSuggest(
        search,
        searchType,
      );

      setSuggestions(results.data.data.list);
    } catch (e) {}
  };
  useEffect(() => {
    setSearchValue(searchValue);
  }, []);

  const getsuggestions = (search: any) => {
    setSearchTerm(search);
    getSearchAutoCompleteSuggestions(search);
  };

  let errorMessage = '';
  let searchValueParam = encodeURI(searchValue);
  searchValueParam = searchValueParam.replace(/#/g, '%23');
  searchValueParam = searchValueParam.replace(/&/g, '%2526');

  if (showTextInvalidError) {
    errorMessage = 'Keyword is required';
  }
  return (
    <form
      className={`search search-result-page ${
        errorMessage !== '' ? 'error' : ''
      }`}
      onSubmit={(e) => {
        resultsSearchHandleSubmit(e, searchValueParam, searchType);
      }}
    >
      {errorMessage !== '' ? (
        <div className="error-message">{errorMessage}</div>
      ) : (
        ''
      )}
      <AutoComplete
        output={searchValue}
        setOutput={setSearchValue}
        suggestions={suggestions}
        getsuggestions={getsuggestions}
        placeholder="Search by a chemical, product or more"
        setFinalSearchValue={() => {}}
        searchinput={searchinput}
        searchButton={true}
      />
     {/*  <input
        id="search-input"
        type="search"
        name=""
        placeholder="Search by a chemical, product or more"
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        ref={searchinput}
      /> */}
      <div className="bottom-elements">
        <input
          type="radio"
          id="chemical-search"
          name="mainsearch"
          value="chemical"
          checked={searchType === 'chemical'}
          onChange={(e) => {
            setSearchType(e.target.value);
            setSort('Chem_Name');
            searchinput.current.focus();
          }}
        />
        <label htmlFor="chemical-search">Chemical Search</label>
        <input
          type="radio"
          id="product-search"
          name="mainsearch"
          value="product"
          checked={searchType === 'product'}
          onChange={(e) => {
            setSearchType(e.target.value);
            setSort('name');
            searchinput.current.focus();
          }}
        />
        <label htmlFor="product-search">Product Search</label>
        <Link to="/advance-search" className="icon-link">
          Advanced search
        </Link>
      </div>
    </form>
  );
};
export default SearchResultSearchForm;
