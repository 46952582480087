/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestNavContent = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/menus`);
};

const navApi = {
  requestNavContent,
};
export default navApi;
