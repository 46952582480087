import { useEffect, useState } from 'react';
import navigationApi from '../api/navigationApi';

const useHeader = () => {
  // const { query } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const getNavigationData = async () => {
      try {
        const results = await navigationApi.requestNavContent();

        setData(results.data);
      } catch (e) {}
    };
    getNavigationData();
  }, []);

  return data && data;
};

export default useHeader;
