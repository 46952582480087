import React from 'react';
import { Table } from 'reactstrap';

const ProductToxicityOtherIngredients = (props: any) => {
  const { data } = props;

  if (data) {
    return (
      <Table responsive bordered>
        <tbody>
          <tr>
            <td className="heading" colSpan={2}>
              OTHER INGREDIENTS
            </td>
          </tr>
          {data.map((item: any, key: number) => (
            <>
              <tr key={key}>
                <td colSpan={2}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.value,
                    }}
                  />{' '}
                 
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    );
  }
  return <></>;
};
export default ProductToxicityOtherIngredients;
