import React from 'react';
import Helmet from 'react-helmet';
import BreadCrumbContainer from '../containers/BreadCrumbContainer';
import TitleButtonContainer from '../containers/TitleButtonContainer';
import FilterContainer from '../containers/FilterContainer';
import SearchResultContainer from '../containers/SearchResultContainer';

const AdvanceSearchLanding = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Advanced Product Search</title>
        <meta name="description" content="Advanced Product Search" />
      </Helmet>
      <BreadCrumbContainer />
      <TitleButtonContainer />
      <FilterContainer />
    </>
  );
};
export default AdvanceSearchLanding;
