import { useEffect, useState } from 'react';
import globalBanMapApi from '../../../api/globalBanMapApi';

const useGlobalMapContent = () => {
  // const { query } = props;
  const [data, setData] = useState({
    content: {
      rendered: '',
    },
    acf: {
      logoItemHeading: '',
      globalBanLogoItems: [],
    },
  });

  useEffect(() => {
    const getGlobalBanMapData = async () => {
      try {
        const results = await globalBanMapApi.globalBanMapContent();

        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    getGlobalBanMapData();
  }, []);

  return data && data;
};

export default useGlobalMapContent;
