import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PostBlock from '../../../components/common/PostBlock';

const RelatedArticles = (props: any): ReactElement => {
  const { relatedWebsites } = props;
  if (relatedWebsites.length > 0) {
    return (
      <section className="home-related-articles">
        <Container>
          <Row>
            <Col>
              <div className="featured-heading">
                <h2>Related Websites</h2>
              </div>
            </Col>
          </Row>
          <Row>
            {relatedWebsites.map((item: any, key: number) => (
              <Col md="4" key={key}>
                <PostBlock type="related-website">
                  <a
                    href={item.relatedWebLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="post-block__featured-image">
                      <img src={item.relatedWebThumbnail} alt="" />
                    </div>
                    <div className="post-block__description">
                      <h3>{item.relatedWebTitle}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.relatedWebExcerpt,
                        }}
                      />
                      <span>{item.relatedWebLinkText}</span>
                    </div>
                  </a>
                </PostBlock>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  }
  return <></>;
};

export default RelatedArticles;
