import React from 'react';

const ChemicalDetials = (props: any) => {
  const { selectedChemicalData, isChemicalDetails } = props;
  if (selectedChemicalData.meteData.chemicalName !== '' && isChemicalDetails) {
    return (
      <div className="ban-map-chemical-detials">
        <div>
          <span>{selectedChemicalData.meteData.chemicalName}</span>
          <span>
            Country Bans:
            <span>{selectedChemicalData.meteData.totalCountries}</span>
          </span>
        </div>
        {selectedChemicalData.list[0].country ? (
          <div>
            <span>Full List</span>
            <ul>
              {selectedChemicalData.list.map((country: any, index: number) => (
                <li key={index}>{country.country}</li>
              ))}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
  return <></>;
};
export default ChemicalDetials;
