/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import chemicalApi from '../../../api/chemicalApi';

const useEcotoxicityContent = (id: string) => {
  const [data, setData] = useState({
    waterContaminationPotential: [],
    usNationalDrinkingWaterStandards: [],
    whoWaterQuality: [],
    environmentalToxicity: [],
  });

  useEffect(() => {
    const getEcotoxicityData = async () => {
      try {
        const results = await chemicalApi.requestEcotoxicityContent(id);
        setData(results.data.data.data);
       // console.log('results---->', results);
      } catch (e) {
       // console.log('results---->', e);
      }
    };
    getEcotoxicityData();
  }, []);

  return data && data;
};

export default useEcotoxicityContent;
