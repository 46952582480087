import { useEffect, useState } from 'react';
import advanceSearchApi from '../../../api/advanceSearchApi';

const useAdvanceSearchContent = () => {
  // const { query } = props;
  const [data, setData] = useState({
    content: '',
    acf: '',
  });

  useEffect(() => {
    const getAdvanceSearchData = async () => {
      try {
        const results = await advanceSearchApi.requestadvanceSearchContent();

        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    getAdvanceSearchData();
  }, []);

  return data && data;
};

export default useAdvanceSearchContent;
