import React, { ReactElement, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import ViewMoreModal from './ViewMoreModal';
import AutoComplete from './AutoComplete';
import ViewMoreModalWithLinks from './ViewMoreModalWithLinks';

const KeyValueDataTableWithSearchWithLinks = (props: any): ReactElement => {
  const {
    tableHeading,
    tableData,
    resultCount,
    itemName,
    badActor,
    list,
    itemKey,
    count,
    modal,
    closeClick,
    viewMore,
    searchInnerValue,
    setSearchInnerValue,
    suggestions,
    getsuggestions,
    setFinalSearchValue,
    link,
  } = props;

  return (
    <>
      <div className="data-table with-search">
        <div className="data-table-heading">
          {tableHeading}
          <span>
            ({resultCount}
            {resultCount > 1 ? ' Results' : ' Result'})
          </span>
        </div>
        <div className="data-table-search">
          <AutoComplete
            output={searchInnerValue}
            setOutput={setSearchInnerValue}
            getsuggestions={getsuggestions}
            suggestions={suggestions}
            placeholder="Search"
            setFinalSearchValue={setFinalSearchValue}
            viewMore={viewMore}
          />
        </div>
        <div className="data-table-content">
          {tableData.map((item: any, key: number) => (
            <div className="data-table-key-value" key={key}>
              <div>
                <Link to={`${link}${item.PROD_NAME}`}>{item.PROD_NAME}</Link>
              </div>
            </div>
          ))}
        </div>
        {resultCount > 8 ? (
          <div className="button-holder">
            <Button className="viewmore" onClick={() => viewMore()}>
              view More
            </Button>
          </div>
        ) : (
          ''
        )}
        <ViewMoreModalWithLinks
          popUpTitle={tableHeading}
          badActor={badActor}
          itemName={itemName}
          modal={modal}
          closeClick={closeClick}
          list={list}
          itemKey={itemKey}
          count={count}
          link={link}
        />
      </div>
    </>
  );
};
export default KeyValueDataTableWithSearchWithLinks;
