import React, { useEffect } from 'react';
import BlogInnerArticle from '../components/BlogInnerArticle';
import BlogInnerForm from '../components/BlogInnerForm';
import BlogRelatedPosts from '../components/BlogRelatedPosts';
import BlogInnerBreadcrumb from '../components/BlogInnerBreadcrumb';
import useBlogInner from './useBlogInner';
import Donation from '../../../components/common/Donation';
import NewsLetterForm from '../../../components/common/NewsLetterForm';

const BloginnerDetails = () => {
  const blogInnerProps = useBlogInner(
    window.location.pathname.split('blog/')[1],
  );
  const { title, featured_image, acf, content, relatedPost , yoast_meta, enableDonation , enableNewsletterSubscription} = blogInnerProps;  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BlogInnerBreadcrumb title={title.rendered} />
      <BlogInnerArticle
        title={title.rendered}
        bannerImage={featured_image}
        customDate={acf.custom_date}
        description={acf.blog__textblock}
        content={content.rendered}
        formName = {acf.action_network_form_name}
        yoast_meta = {yoast_meta}
      />
      {/* <BlogInnerForm /> */}
      {enableNewsletterSubscription === "Yes" ? <NewsLetterForm /> : ''}
      
      <BlogRelatedPosts relatedPosts={relatedPost} />
      {enableDonation === "Yes" ? <Donation /> :''}
    </>
  );
};

export default BloginnerDetails;
