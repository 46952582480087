import React, { useEffect, useState } from 'react';
import AutoComplete from '../../../components/common/AutoComplete';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';

const RegNoAutoCompleteContainer = (props: any) => {
  const { regNumber, setRegNumber, setIsSearchClicked } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState();

  const getRegNoAutoCompleteSuggestions = async (search: any) => {
    // console.log(searchTerm);
    try {
      const results = await advanceSearchProductApi.requestAdvanceSearchRegNoSuggest(
        search,
      );

      setSuggestions(results.data.data.list);
      // console.log(results.data.data);
    } catch (e) {}
  };
  useEffect(() => {
    setRegNumber(searchTerm);
    // getPestNameAutoCompleteSuggestions(setPestName);
  }, []);

  const getsuggestions = (search: any) => {
    // console.log(serchTerm);
    setSearchTerm(search);
    getRegNoAutoCompleteSuggestions(search);
    // console.log(suggestions);
  };

  return (
    <AutoComplete
      output={regNumber}
      setOutput={setRegNumber}
      suggestions={suggestions}
      getsuggestions={getsuggestions}
      placeholder="Product Registration Number"
      setFinalSearchValue={() => {}}
      setIsSearchClicked={setIsSearchClicked}
    />
  );
};
export default RegNoAutoCompleteContainer;
