import React from 'react';

const OverviewTab = (props: any) => {
  const { list, details } = props;
  return (
    <div className="content-wrapper overview-content">
      <div className="content-holder">
        <div className="summery">
          <h4>SUMMARY</h4>
          <p>
            <strong>{list.countyName} Agricultural Comminsion</strong>
          </p>
          <p dangerouslySetInnerHTML={{ __html: details.countyAddress }} />
          <p>
            Phone:&nbsp;
            <a href={`tel:${details.countyPhone}`}>{details.countyPhone}</a>
            <br />
            <br />
            Visit&nbsp;
            <a href={details.cdfaWebsite} target="_blank">
              CDFA’s website for contact information
            </a>
          </p>
        </div>
        <div className="map">
          <a
            href={`https://pan-uploads.s3.amazonaws.com/cacountymaps/${list.countyName}.gif`}
            target="_blank"
          >
            <img
              src={`https://pan-uploads.s3.amazonaws.com/cacountymaps/${list.countyName}.png`}
              alt={list.countyName}
            />
          </a>
          {/* <img
            src={`https://pan-uploads.s3.amazonaws.com/cacountymaps/${list.countyName}.jpg`}
            alt={list.countyName}
          /> */}
        </div>
      </div>
    </div>
  );
};
export default OverviewTab;
