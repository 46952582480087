import React from 'react';
import Helmet from 'react-helmet';

import CaTownshipmapDetails from '../containers/caTownShipDetails';

const CaTownShipMaps = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California Township Map</title>
        <meta name="description" content="CA Township Map" />
      </Helmet>
      <CaTownshipmapDetails />
    </>
  );
};
export default CaTownShipMaps;
