import { useEffect, useState } from 'react';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';

const useChemicalPropertyContent = () => {
  const [chemicalProperty, setChemicalProperty] = useState();
  const getChemPropertyData = async () => {
    try {
      const results = await advanceSearchProductApi.requestAdvanceSearchChemProperties();

      return results.data.data;
    } catch (e) {}
  };
  useEffect(() => {
    const loadChemicalPropertyData = async () => {
      const chemicalPropertyData = await getChemPropertyData();

      chemicalPropertyData.map((elm: any) => {
        elm.selected = false;
        elm.selectedElementIndex = -1;
        elm.noOfSelected = 0;
        elm.subItems.map((subElm: any) => {
          subElm.selected = false;
        });
      });
      setChemicalProperty(chemicalPropertyData);
    };
    loadChemicalPropertyData();
  }, []);

  return chemicalProperty;
};

export default useChemicalPropertyContent;
