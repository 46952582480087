import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const TitleButton = (props: any) => {
  return (
    <section className="title-button-container">
      <Container>
        <Row>
          <Col lg="6" className="title">
            <h2>Advanced Product Search</h2>
          </Col>
          <Col lg="6" className="button">
            <Link to="/advance-search/chemical">
              <span>OR SEARCH BY CHEMICAL</span>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default TitleButton;
