import React, { useState } from 'react';
import FaqQuestionsAnswers from '../components/FaqQuestionsAnswers';
import useFaq from './useFaq';
import FaqBanner from '../components/FaqBanner';
import Donation from '../../../components/common/Donation';
import NewsLetterForm from '../../../components/common/NewsLetterForm';
import FaqDescriptionTextBlock from '../components/FaqDescriptionTextBlock';

const FaqInnerDetails = () => {
  const faqProps = useFaq();
  const { title, acf, content } = faqProps;

  let enableDonation = acf.enable_donation;
  let enableNewsletterSubscription = acf.enable_newsletter_subscription;

  const [activeId, setActiveId] = useState(-1);
  const updateActiveId = (id: number): void => {
    setActiveId(id);
  };
  return (
    <>
      <FaqBanner title={title.rendered} />
      <FaqDescriptionTextBlock content={content} />
      <FaqQuestionsAnswers
        title={title.rendered}
        questionAnswers={acf.question_answers}
        activeId={activeId}
        updateActiveId={updateActiveId}
      />
      {enableDonation==="Yes" ? <Donation /> :''}
      {enableNewsletterSubscription === "Yes" ? <NewsLetterForm /> : ''}
    </>
  );
};

export default FaqInnerDetails;
