import React from 'react';
import { Container, Row, Breadcrumb, BreadcrumbItem, Col } from 'reactstrap';

const ResourceBreadcrumb = (props: any) => {
  return (
    <Container>
      <Row>
        <Col sm="12">
          <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem className="disable" tag="a">
              Alternatives & Resources
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" className="active">
              Resources
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </Container>
  );
};
export default ResourceBreadcrumb;
