import React from 'react';
const OverviewTab = (props: any) => {
  const { data } = props;  

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }   
  }

  return (
    <div className="content-wrapper crop-overview-content">
      <div className="data-table">
        {data ? (
          <>
            <div className="data-table-heading">SUMMARY</div>

            <div className="data-table-content">
              <div className="data-table-key-value">
                {/* <pre>{JSON.stringify(data)}</pre> */}
                <div>
                  Name<span>:</span>
                </div>
                <div>{data.cropName}</div>
              </div>
              <div className="data-table-key-value">
                <div>
                  CDFA Commodity Group <span>:</span>
                </div>
                <div>{data.cropDetails.cdfaCommodityGroup}</div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Gross Pounds <span>:</span>
                </div>
                <div>{decimalFormat(data.cropDetails.grossPunds,1)}</div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Acres Planted <span>:</span>
                </div>
                <div>{decimalFormat(data.cropDetails.acresPlanted,1)}</div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Acres Treated <span>:</span>
                </div>
                <div>{decimalFormat(data.cropDetails.acresTreated,1)}</div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default OverviewTab;
