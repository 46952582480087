import React, { useState } from 'react';

import { Button } from 'reactstrap';
/* import ChemicalOtherNames from '../../../components/common/ViewMoreModal'; */
import productApi from '../../../api/productApi';
import ViewMoreModal from '../../../components/common/ViewMoreModal';

const TableCardTwoColumn = (props: any) => {
  const { list, productName, title, badActor, count } = props;
  const url = new URL(window.location.href);
  const productRegno = url.searchParams.get('regno')!;
  const [modal, setModal] = useState(false);
  const [otherList, setotherList] = useState({
    metaData: { total: 0 },
    items: [],
  });
  const closeClick = () => {
    setModal(false);
  };

  const othernamesViewMore = async () => {
    setModal(!modal);
    let results;
    try {
      results = await productApi.requestProductOthernames(productRegno);
    } catch (e) {}

    setotherList({
      metaData: results.data.data.metaData
        ? results.data.data.metaData
        : { total: 0 },
      items: results.data.data.otherNames ? results.data.data.otherNames : [],
    });
  };

  return (
    <div>
      <div className="data-table-two-columns">
        <div className="data-table-heading">{title}</div>
        <div className="data-table-content">
          {list.map((item: any, key: number) => (
            <div key={key}>{item.prodName}</div>
          ))}
        </div>
        {count > 8 ? (
          <div className="button-holder">
            <Button className="viewmore" onClick={() => othernamesViewMore()}>
              view More
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
      <ViewMoreModal
        popUpTitle={title}
        badActor={badActor}
        itemName={productName}
        modal={modal}
        closeClick={closeClick}
        list={otherList}
        itemKey={'prodName'}
        count={'total'}
      />
    </div>
  );
};
export default TableCardTwoColumn;
