import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import useGoogleAnalytics from '../helpers/useGoogleAnalytics';
import Layout from '../components/common/Layout';
import Home from '../modules/home/pages/Home';
import Blog from '../modules/blog/pages/BlogList';
import BlogInner from '../modules/blog/pages/BlogInner';
import ResourceList from '../modules/resources/pages/ResourceList';
import ResourceInner from '../modules/resources/pages/ResourceInner';
import Faq from '../modules/faq/pages/Faq';
import TooltipInfo from '../modules/tooltip-info/pages/TooltipInfo';

import Overview from '../modules/overview/pages/Overview';
import SourceMethology from '../modules/source-metholodgy/pages/SourceMethology';
import PrivacyPolicy from '../modules/privacy-policy/pages/PrivacyPolicy';
import TermsConditions from '../modules/terms-conditions/pages/TermsCondtions';
import ChemicalDetail from '../modules/chemical/pages/ChemicalDetail';
import ProductlDetail from '../modules/product/pages/ProductDetail';
import SearchResult from '../modules/search-result/pages/SearchResult';
import AdvanceSearch from '../modules/advance-search/pages/AdvanceSearch';
import AdvanceProductSearchLanding from '../modules/advance-search-product/pages/AdvanceProductSearchLanding';
import AdvanceSearchChemical from '../modules/advance-search-chemical/pages/AdvanceSearchChemical';
import CaliforniaSearch from '../modules/california-search/pages/CaliforniaSearch';
import SearchResultPage from '../modules/california-search-result/pages/SearchResultPage';
import CountyDetailPage from '../modules/california-search-county-detail/pages/CountyDetailPage';
import CropDetailPage from '../modules/california-search-crop-detail/pages/CropDetailPage';
import ShortcutDetailPage from '../modules/california-search-shortcut-detail/pages/ShortcutDetailPage';
import CaliforniaChemicalDetail from '../modules/california-search-chemical-detail/pages/CaliforniaChemicalDetail';
import UsgsMaps from '../modules/usgsmap/pages/usgsMap';
import CaTownShipMaps from '../modules/ca-township-maps/pages/caTownship';
import GlobalBanMaps from '../modules/global-ban-maps/pages/GlobalBanMap';
import CprMap from '../modules/global-ban-maps/pages/CprMap';
import NotFound from '../modules/notfound/pages/NotFound';
import GlobalBanIframe from '../components/common/MapEmbed';
import AdvanceSearchLandingPersist from '../modules/advance-search-product-persist/pages/AdvanceProductSearchLandingPersist';

const Routes = (): JSX.Element => {
  useGoogleAnalytics();
  if (window.location.pathname === '/pesticide-maps/global-ban-embed') {
    return (
      <Switch>
        <Route
          exact
          path="/pesticide-maps/global-ban-embed"
          component={GlobalBanMaps}
        />
      </Switch>
    );
  } else {
    return (
      <Layout>
        <Switch>
          <Route
            exact
            path="/pesticide-maps/ca-pesticide-map"
            component={CprMap}
          />
          <Route exact path="/search-chemicals-or-products" component={Home} />
          <Route exact path="/" component={Home} />
          <Route exact path="/blog/:slug" component={BlogInner} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/resources/:slug" component={ResourceInner} />
          <Route exact path="/resources" component={ResourceList} />
          <Route
            exact
            path="/resources/category/:slug"
            component={ResourceList}
          />
          <Route exact path="/about/faq" component={Faq} />
          <Route exact path="/about/overview" component={Overview} />
          <Route
            exact
            path="/about/source-methology"
            component={SourceMethology}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsConditions} />
          <Route exact path="/chemical/:id" component={ChemicalDetail} />
          <Route exact path="/product" component={ProductlDetail} />
          <Route exact path="/search-result" component={SearchResult} />
          <Route exact path="/advance-search" component={AdvanceSearch} />
          <Route exact path="/docs" component={TooltipInfo} />
          {/* <Route path="" component={NotFound} /> */}
          <Route
            exact
            path="/advance-search/chemical"
            component={AdvanceSearchChemical}
          />
          <Route
            exact
            path="/advance-search/product"
            component={AdvanceProductSearchLanding}
          />
          <Route
            exact
            path="/advance-search/product-persist"
            component={AdvanceSearchLandingPersist}
          />
          <Route
            exact
            path="/california-pesticide-use"
            component={CaliforniaSearch}
          />
          <Route exact path="/california-search" component={SearchResultPage} />
          <Route
            exact
            path="/california-pesticide-use/california-county-detail"
            component={CountyDetailPage}
          />
          <Route
            exact
            path="/california-pesticide-use/california-crop-detail"
            component={CropDetailPage}
          />
          <Route
            exact
            path="/california-top-pesticide-usage"
            component={ShortcutDetailPage}
          />
          <Route
            exact
            path="/california-pesticide-use/california-chemical-detail"
            component={CaliforniaChemicalDetail}
          />
          <Route exact path="/pesticide-maps/usgs" component={UsgsMaps} />
          <Route
            exact
            path="/pesticide-maps/ca-township"
            component={CaTownShipMaps}
          />
          <Route
            exact
            path="/pesticide-maps/global-ban"
            component={GlobalBanMaps}
          />
          <Route exact path="/not-found" component={NotFound} />
          <Route path="/*" component={Home} />
        </Switch>
      </Layout>
    );
  }
};

export default Routes;
