/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import blogApi from '../../../api/blogsApi';
import  homeApi from '../../../api/homeApi';

const useBlogInner = (slug: string) => {
  const [enableDonation, setEnableDonation] = useState('No');
  const [enableNewsletterSubscription, setEnableNewsletterSubscription] = useState('No');
  
  const [data, setData] = useState({
    title: {
      rendered: '',
    },
    featured_image:'',
    acf: {
      custom_date: '',
      blog__textblock:'',
      action_network_form_name: '',
      enable_donation: '',
      enable_newsletter_subscription: '',
    },
    content: {
      rendered: '',
    },
    relatedPost: [],
    yoast_meta: [],
    enableDonation: enableDonation,
    enableNewsletterSubscription: enableNewsletterSubscription,
  });
  

  useEffect(() => {
    const getBlogInnerData = async () => {
      try {
        const results = await blogApi.requestBlogDetails(slug);
        
        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    const getHeaderData = async () => {
      try {
        const results = await homeApi.requestHeader();
        setEnableDonation(results.data.acf.enable_donation);
        setEnableNewsletterSubscription(results.data.acf.enable_newsletter_subscription);
      } catch (e) {}
    };
    
    getBlogInnerData();
    getHeaderData();
  }, []);
  data['enableDonation'] = enableDonation;
  data['enableNewsletterSubscription'] = enableNewsletterSubscription;
  return data && data;
};

export default useBlogInner;
