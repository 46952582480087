/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

const TargetPestFilter = (props: any) => {
  const {
    targetPests,
    selectedTargetPest,
    setSelectedTargetPest,
    selectedValues,
    setSelectedValues,
    setIsSearchClicked,
  } = props;
  const [targetPestActiveId, setTargetPestActiveId] = useState();
  // const [isSearchClicked, setIsSearchClicked] = useState();
  return (
    <div className="target-pest-filter">
      <div className="title">target pest</div>
      <ul className="filter">
        {targetPests.data.map((item: any, key: number) => (
          <li
            key={`item-${key}`}
            // data-pest-parent={`pest-${key}`}
            className={`${
              selectedTargetPest.pestParent === `pest-${key}` ? 'selected' : ''
            } ${targetPestActiveId !== key ? 'collapsed' : ''}`}
            onClick={(): void => {
              if (targetPestActiveId === key) {
                setTargetPestActiveId('');
              } else {
                setTargetPestActiveId(key);
              }
            }}
            onKeyDown={(): void => {
              if (targetPestActiveId === key) {
                setTargetPestActiveId('');
              } else {
                setTargetPestActiveId(key);
              }
            }}
          >
            {item.itemLabel}
            <ul>
              {item.subItems.map((subItem: any, innerKey: number) => (
                <li
                  key={`item-${innerKey}`}
                  // data-pest-parent={`pest-${key}-${innerKey}`}
                  className={`${
                    selectedTargetPest.pest === `pest-${key}-${innerKey}`
                      ? 'selected'
                      : ''
                  }`}
                  onClick={(e): void => {
                    e.stopPropagation();
                    const newSelectedValues = selectedValues.filter(
                      (obj: any) => {
                        return obj.name !== 'pest';
                      },
                    );
                    setIsSearchClicked(false);
                    if (selectedTargetPest.pest === `pest-${key}-${innerKey}`) {
                      setSelectedTargetPest({
                        pestParent: '',
                        pest: '',
                        pestLabel: '',
                        itemValue: '',
                      });
                    } else {
                      setSelectedTargetPest({
                        pestParent: `pest-${key}`,
                        pest: `pest-${key}-${innerKey}`,
                        pestLabel: `${subItem.itemLabel}`,
                        itemValue: `${subItem.itemValue}`,
                      });
                      newSelectedValues.push({
                        name: 'pest',
                        pestParent: `pest-${key}`,
                        pest: `pest-${key}-${innerKey}`,
                        pestLabel: `${subItem.itemLabel}`,
                        itemValue: `${subItem.itemValue}`,
                      });
                    }
                    setSelectedValues(newSelectedValues);
                  }}
                >
                  {subItem.itemLabel}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default TargetPestFilter;
