import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SideBarContainer from '../../../containers/SideBarContainer';

const UsgsContent = (props: any) => {
  const { content, acf } = props;
  const { map, text, link } = acf;
  const { rendered } = content;

  return (
    <section className="common-page-content common-map-content">
      <Container>
        <Row>
          <Col lg="9">
            <Row className="common-inner-header">
              <Col sm="12">
                <div
                  className="text-block detail-page-content"
                  dangerouslySetInnerHTML={{ __html: rendered }}
                />
              </Col>

              <div className="commonLinkMaps">
                <Col sm="12">
                  <a href={link.url} target="_blank">
                    <img src={map} alt="" className="img-fluid" />
                  </a>
                </Col>

                <Col sm="12">
                  <a href={link.url} target="_blank" className="map-url" rel="noopener noreferrer">
                    {text}
                  </a>
                </Col>
              </div>
            </Row>
          </Col>
          <Col lg="3">
            <SideBarContainer />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default UsgsContent;
