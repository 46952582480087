import React, { useState, useRef } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import globalBanMapApi from '../../../api/globalBanMapApi';

const MapFilters = (props: any) => {
  const {
    countryList,
    setCountries,
    setSelectedChemical,
    setIsPopup,
    setIsChemicalDetails,
    setSelectedCounty,
  } = props;

  const chemicalSelect: any = useRef(null);
  const countrySelect: any = useRef(null);
  const chemicalSelectField: any = useRef(null);
  const countrySelectField: any = useRef(null);

  const [countrySelectValue, setCountrySelectValue] = useState(null);
  const [chemicalSelectValue, setChemicalSelectValue] = useState(null);

  const countryListItems: any = [];
  countryList.map((countryItem: any) => {
    countryListItems.push({ label: countryItem.countryName });
  });

  const getGlobalBanMapChemicalData = async (search: any) => {
    try {
      const results = await globalBanMapApi.globalBanMapChemicals(search);

      const listData = results.data.data.list;
      const list: any = [];
      listData.map((item: any) => {
        list.push({ label: item.Chem_Name, value: item.Rec_Id });
      });
      return list;
    } catch (e) {}
  };

  return (
    <div className="map-filters">
      <div className="filter-holder">
        <div ref={countrySelect}>
          <Select
            ref={countrySelectField}
            options={countryListItems}
            placeholder="Search By Country"
            onChange={(e: any) => {
              countrySelectField.current.blur();
              if (e.label === 'USA') {
                setCountries([
                  ['Country', 'Name'],
                  ['United States', 'United States'],
                ]);
              } else if (e.label === 'UK') {
                setCountries([
                  ['Country', 'Name'],
                  ['United Kingdom', 'United Kingdom'],
                ]);
              } else if (e.label.indexOf('(in addtion to eu)') > -1) {
                const country = e.label.replace('(in addtion to eu)', '');
                setCountries([
                  ['Country', 'Name'],
                  [country, country],
                ]);
              } else if (e.label.indexOf('(in addition to eu)') > -1) {
                const country = e.label.replace('(in addition to eu)', '');
                setCountries([
                  ['Country', 'Name'],
                  [country, country],
                ]);
              } else if (e.label === 'Russian federation') {
                setCountries([
                  ['Country', 'Name'],
                  ['Russia', 'Russia'],
                ]);
              } else {
                let countryName = e.label;
                countryName = countryName.replace(" (EU)", "");
                countryName = countryName.replace(" (EU+)", "");
                setCountries([
                  ['Country', 'Name'],
                  [countryName, countryName],
                ]);
              }
              setIsChemicalDetails(false);
              setChemicalSelectValue(null);
              setCountrySelectValue(e);
              setSelectedChemical('');
              window.scrollTo(0, 220);
              const country = e.label;
              if (country === 'United States') {
                setSelectedCounty('USA');
              } else if (country === 'United Kingdom') {
                setSelectedCounty('UK');
              } else {
                setSelectedCounty(country);
              }
              setTimeout(() => {
                if (country) {
                  setIsPopup(true);
                }
              }, 100);
            }}
            onFocus={(e: any) => {
              setIsPopup(false);
              setIsChemicalDetails(false);
              setChemicalSelectValue(null);
              setSelectedChemical('');
              setCountrySelectValue(null);
              setCountries([
                ['Country', 'Name'],
                ['', ''],
              ]);
              if (chemicalSelect) {
                chemicalSelect.current.style = 'opacity:.3';
                countrySelect.current.style = 'opacity:1';
              }
            }}
            value={countrySelectValue}
          />
        </div>
        <span>Or</span>
        <div ref={chemicalSelect}>
          <AsyncSelect
            ref={chemicalSelectField}
            cacheOptions
            placeholder="Search By Chemical"
            loadOptions={getGlobalBanMapChemicalData}
            defaultOptions={[]}
            onFocus={(e: any) => {
              setIsPopup(false);
              setChemicalSelectValue(null);
              setCountrySelectValue(null);
              setSelectedChemical('');
              setCountries([
                ['Country', 'Name'],
                ['', ''],
              ]);
              if (countrySelect) {
                countrySelect.current.style = 'opacity:.3';
                chemicalSelect.current.style = 'opacity:1';
              }
            }}
            setSelectedChemical
            onChange={(e: any) => {
              chemicalSelectField.current.blur();
              setSelectedChemical(e.value);
              setChemicalSelectValue(e);
              window.scrollTo(0, 420);
            }}
            value={chemicalSelectValue}
          />
        </div>
      </div>
    </div>
  );
};
export default MapFilters;
