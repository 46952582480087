/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const SearchResultTable = (props: any) => {
  const {
    data,
    requestDataComplete,
    dataNotFound,
    clickSort,
    numberOfResults,
    list,
  } = props;
  if (requestDataComplete) {
    return (
      <section>
        <Container>
          <div className="search-result-table container advance-product-result-table">
            <Row>
              {list && list.length > 0 ? (
                <Col lg="12">
                  <div className="result-count">
                    <h3>
                      Search Results{' '}
                      <span>
                        [{numberOfResults}{' '}
                        {numberOfResults > 1 ? ' Results' : ' Result'}]
                      </span>
                    </h3>
                  </div>
                  <div className="result-table">
                    <div className="result-table-row titles">
                      <div>
                        <div
                          onClick={() => {
                            clickSort('name');
                          }}
                        >
                          PRODUCT NAME
                        </div>
                        <div
                          onClick={() => {
                            clickSort('us_reg_status');
                          }}
                        >
                          U.S. REG STATUS
                        </div>
                        <div
                          onClick={() => {
                            clickSort('ahw_label');
                          }}
                        >
                          ACUTE HAZARD WARNING LABEL
                        </div>
                        <div
                          onClick={() => {
                            clickSort('rup_status');
                          }}
                        >
                          U.S. RESTRICTED USE STATUS
                        </div>
                        <div
                          onClick={() => {
                            clickSort('company_name');
                          }}
                        >
                          MANUFACTURER
                        </div>
                        <div
                          onClick={() => {
                            clickSort('bad_actor');
                          }}
                        >
                          PAN BAD ACTOR
                        </div>
                      </div>
                    </div>
                    {data.list.map((item: any, key: number) => (
                      <div className="result-table-row" key={key}>
                        <Link
                          target="_blank"
                          to={`/product?regno=${item.reg_number}&distno=${item.company_number}`}
                        >
                          <div>
                            <h3>{item.name}</h3>
                            <h4>{item.label}</h4>
                          </div>
                          <div>{item.us_reg_status}</div>
                          <div>{item.ahw_label}</div>
                          <div>{item.rup_status}</div>
                          <div>{item.company_name}</div>
                          <div>{item.bad_actor}</div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </div>
        </Container>
      </section>
    );
  }
  if (dataNotFound) {
    return (
      <Container className="search-result-table">
        <Row>
          <Col sm="12" className="no-result-found">
            <h3>No Search Results Found</h3>
            <p>No results found in products. Please search for chemicals.</p>
          </Col>
        </Row>
      </Container>
    );
  }
  return <></>;
};
export default SearchResultTable;
