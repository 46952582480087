import React from 'react';
import useDonateWithWebList from '../../home/containers/useDonateWithWebList';
import TextBlock from '../components/TextBlock';
import RelatedArticles from '../components/RelatedArticles';

import CaliforniaHomeSearch from '../components/CaliforniaHomeSearch';
import useCaliforniaChemicals from './useCaliforniaChemicals';
import useCaliforniaCropSite from './useCaliforniaCropSite';
import useCaliforniaRegionCounty from './useCaliforniaRegionCounty';

const CaliforniaSearchContainer = (props: any) => {
  const homeProps = useDonateWithWebList();
  const {
    donateText,
    donateButtonLabel,
    donateButtonLink,
    relatedWebsites,
  } = homeProps;

  const californiaChemicalsList = useCaliforniaChemicals();
  const californiaCropSiteList = useCaliforniaCropSite();
  const californiaRegionCountyList = useCaliforniaRegionCounty();

  return (
    <>
      <CaliforniaHomeSearch
        californiaChemicalsList={californiaChemicalsList}
        californiaCropSiteList={californiaCropSiteList}
        californiaRegionCountyList={californiaRegionCountyList}
      />
      <TextBlock
        donateText={donateText}
        donateButtonLabel={donateButtonLabel}
        donateButtonLink={donateButtonLink}
      />
      <RelatedArticles relatedWebsites={relatedWebsites} />
    </>
  );
};
export default CaliforniaSearchContainer;
