import React from 'react';
import { Container } from 'reactstrap';
import ResourceInnerDetails from '../containers/ResourceInnerDetails';

const ResourceInner = (props: any) => {
  return (
    <section>
      <Container>
        <ResourceInnerDetails />
      </Container>
    </section>
  );
};
export default ResourceInner;
