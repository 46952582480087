import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const ResourceTitle = (props: any) => {
  const { resourceTitle } = props;
  return (
    <Container>
      <Row className="common-inner-header">
        <Col sm="12">
          <h1>{resourceTitle}</h1>
        </Col>
      </Row>
    </Container>
  );
};
export default ResourceTitle;
