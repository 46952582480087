import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const ProductDetailNavButton = (props: any) => {
  return (
    <div className="button-container">
      <a
        className="red-btn"
        href="https://www.migrantclinician.org/issues/occupational-health/pesticides/reporting-illnesses"
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <span>Report a Poisoning</span>
      </a>
    </div>
  );
};
export default ProductDetailNavButton;
