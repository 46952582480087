/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { Chart } from 'react-google-charts';

const BanMap = (props: any) => {
  const {
    countries,
    isPopup,
    setIsPopup,
    setSelectedCounty,
    zoomClass,
    selectedChemical,
  } = props;

  return (
    <>
      <div className={`map-wrapper ${zoomClass}`}>
        <Chart
          chartType="GeoChart"
          data={countries}
          mapsApiKey="YOUR_KEY_HERE"
          options={{
            datalessRegionColor: '#28b7d0',
            defaultColor: '#f3ec54',
            tooltip: {
              textStyle: {
                color: '#616060',
                fontSize: 15,
              },
              trigger: 'none',
            },
            dataMode: 'regions',
          }}
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({ chartWrapper, google }) => {
                google.visualization.events.addListener(
                  chartWrapper,
                  'select',
                  (e: any) => {
                    const chart = chartWrapper.getChart();
                    const selection = chart.getSelection();
                    setIsPopup(false);
                    const euCountries = ["AUSTRIA","BELGIUM", "BULGARIA", "CROATIA", "CYPRUS", "CZECHIA", "DENMARK", "ESTONIA", "FINLAND", "GERMANY", "GREECE", "HUNGARY", "IRELAND", "ITALY", "LATVIA", "LITHUANIA", "LUXEMBURG", "MALTA", "NETHERLANDS","POLAND","PORTUGAL","ROMANIA","SLOVAKIA","SLOVENIA","SPAIN"];
                    const euCountriesPlus = ["FRANCE", "SWEDEN"];
                    if (selection.length > 0) {
                      const country = countries[selection[0].row + 1][0];
                      if (country === 'United States') {
                        setSelectedCounty('USA');
                      } else if (country === 'United Kingdom') {
                        setSelectedCounty('UK');
                      } else if(euCountries.includes(country) === true){
                        setSelectedCounty(country + ' (EU)');
                     } else if(euCountriesPlus.includes(country) === true){
                        setSelectedCounty(country + ' (EU+)');
                      }else {
                        setSelectedCounty(country);
                      }


                      setTimeout(() => {
                        if (selectedChemical === '') {
                          setIsPopup(!isPopup);
                        }
                      }, 100);
                    }
                  },
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
export default BanMap;
