import React from 'react';
import { Container } from 'reactstrap';
import Helmet from 'react-helmet';

import BlogInnerDetails from '../containers/BlogInnerDetails';

const BlogInner = (props: any) => {
  return (
    <section>
      <Container>
        <BlogInnerDetails />
      </Container>
    </section>
  );
};
export default BlogInner;
