import React from 'react';

const MapZoom = (props: any) => {
  const { zoom, setZoom, setZoomClass } = props;
  return (
    <div className="map-zoom-wrapper">
      <div className="map-zoom">
        <button
          type="button"
          onClick={(e) => {
            switch (zoom) {
              case 0:
                setZoom(1);
                setZoomClass(`zoom-1`);
                break;
              case 1:
                setZoom(2);
                setZoomClass(`zoom-2`);
                break;
              case 2:
                setZoom(3);
                setZoomClass(`zoom-3`);
                break;
              default:
            }
          }}
          disabled={zoom === 3}
        >
          +
        </button>
        <button
          type="button"
          onClick={() => {
            switch (zoom) {
              case 3:
                setZoom(2);
                setZoomClass(`zoom-2`);
                break;
              case 2:
                setZoom(1);
                setZoomClass(`zoom-1`);
                break;
              case 1:
                setZoom(0);
                setZoomClass(`zoom-0`);
                break;
              default:
            }
          }}
          disabled={zoom === 0}
        >
          -
        </button>
      </div>
    </div>
  );
};
export default MapZoom;
