import React, { useState, useEffect } from 'react';

const ChmicalPropertyFilter = (props: any) => {
  const {
    data,
    setChemicalPropertyList,
    setbuttonActive,
    setIsSearchClicked,
    selectedValues,
    setSelectedValues,
  } = props;
  const [
    chemicalPropertyListActiveId,
    setchemicalPropertyListActiveId,
  ] = useState();

  const toggleChemTypeListItem = (parentIndex: number, childIndex: number) => {
    const chemicalPropertyList = [...data];
    const isSelected =
      chemicalPropertyList[parentIndex].subItems[childIndex].selected;
    let newSelectedValues = selectedValues;
    if (!isSelected) {
      chemicalPropertyList[parentIndex].selected = true;
      chemicalPropertyList[parentIndex].noOfSelected = 1;
      // eslint-disable-next-line no-empty
      if (chemicalPropertyList[parentIndex].selectedElementIndex > -1) {
        chemicalPropertyList[parentIndex].subItems[
          chemicalPropertyList[parentIndex].selectedElementIndex
        ].selected = false;
        newSelectedValues = selectedValues.filter((obj: any) => {
          return (
            obj.subItemsItemValue !==
            chemicalPropertyList[parentIndex].subItems[
              chemicalPropertyList[parentIndex].selectedElementIndex
            ].itemValue
          );
        });
      }
      chemicalPropertyList[parentIndex].subItems[childIndex].selected = true;
      chemicalPropertyList[parentIndex].selectedElementIndex = childIndex;

      newSelectedValues.push({
        name: 'chemicalPropertyList',
        itemLabel: chemicalPropertyList[parentIndex].itemLabel,
        itemValue: chemicalPropertyList[parentIndex].itemValue,
        parentIndex,
        childIndex,
        noOfSelected: chemicalPropertyList[parentIndex].noOfSelected,
        subItemsItemLabel:
          chemicalPropertyList[parentIndex].subItems[childIndex].itemLabel,
        subItemsItemValue:
          chemicalPropertyList[parentIndex].subItems[childIndex].itemValue,
      });
      setSelectedValues(newSelectedValues);
    } else {
      chemicalPropertyList[parentIndex].selected = false;
      chemicalPropertyList[parentIndex].noOfSelected = 0;
      chemicalPropertyList[parentIndex].subItems[childIndex].selected = false;
      chemicalPropertyList[parentIndex].selectedElementIndex = 0;
      const removeSelectedValues = selectedValues.filter((obj: any) => {
        return (
          obj.subItemsItemValue !==
          chemicalPropertyList[parentIndex].subItems[childIndex].itemValue
        );
      });
      setSelectedValues(removeSelectedValues);
    }

    chemicalPropertyList[parentIndex].selectedElementIndex = childIndex;
    setChemicalPropertyList(chemicalPropertyList);
  };
  return (
    <div className="filter-search">
      <div className="title">CHEMICAL PROPERTIES</div>

      {data !== undefined ? (
        <ul className="filter">
          {data.map((item: any, key: number) => (
            <li
              key={`item-${key}`}
              className={`${
                chemicalPropertyListActiveId !== key ? 'collapsed' : ''
              } ${item.noOfSelected > 0 ? 'selected' : ''}`}
              onClick={(): void => {
                if (chemicalPropertyListActiveId === key) {
                  setchemicalPropertyListActiveId('');
                } else {
                  setchemicalPropertyListActiveId(key);
                }
              }}
            >
              {item.itemLabel}
              <ul>
                {item.subItems.map((itemSub: any, innerKey: number) => (
                  <li
                    key={`item-${innerKey}`}
                    className={`${itemSub.selected ? 'selected' : ''}`}
                    onClick={(e): void => {
                      e.stopPropagation();
                      setIsSearchClicked(false);
                      toggleChemTypeListItem(key, innerKey);
                      setbuttonActive(true);
                    }}
                  >
                    {itemSub.itemLabel}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
};
export default ChmicalPropertyFilter;
