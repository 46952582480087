import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SideBarContainer from '../../../containers/SideBarContainer';

const CaTownshipmapContent = (props: any) => {
  const { content, acf } = props;
  const { map, text, link } = acf;
  const { rendered } = content;

  return (
    <section className="common-page-content common-map-content">
      <Container>
        <Row>
          <Col lg="9">
            {' '}
            <div className="common-inner-header">
              <div
                className="text-block detail-page-content"
                dangerouslySetInnerHTML={{ __html: rendered }}
              />

              <div className="commonLinkMaps">
                <a href={link.url} target="_blank">
                  <img src={map} alt="" className="img-fluid" />
                </a>

                <a
                  href={link.url}
                  target="_blank"
                  className="map-url"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              </div>
            </div>
          </Col>
          <Col lg="3">
            <SideBarContainer />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default CaTownshipmapContent;
