import React from 'react';
import { Container, Row, Breadcrumb, BreadcrumbItem, Col } from 'reactstrap';

const ChemicalResultBreadcrumb = (props: any) => {
  return (
    <Container>
      <Row>
        <Col sm="12">
          <Breadcrumb tag="nav" listTag="div" className="detail-page">
            <BreadcrumbItem tag="a" href="/">
              Search Chemicals or Products
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href="/advance-search">
              Advanced Search
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" className="active">
              Chemicals
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </Container>
  );
};
export default ChemicalResultBreadcrumb;
