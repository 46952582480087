import React from 'react';
import useNavigationContainer from './useNavigationContainer';
import Navigation from '../components/common/Navigation';

const NavigationContainer = () => {
  const navigationProps = useNavigationContainer();

  return (
    <>
      <Navigation navItems={navigationProps} />
    </>
  );
};

export default NavigationContainer;
