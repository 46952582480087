import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestAdvanceSearchTargetPest = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/targetPest`);
};

const requestAdvanceSearchChemicalType = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/chemicalType`);
};

const requestAdvanceSearchChemicalToxicityList = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/chemicalToxicityList`);
};

const requestAdvanceSearchChemicalRegulatoryList = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/chemicalRegulatoryList`);
};

const requestAdvancedChemicalSearchResults = (
  // searchTerm: string | null,
  // pageNumber: string | null,
  // sortField: string | null,
  // orderBy: string | null,
  // targetPestTerm: string | null,
  // chemicalTypeTerm: string | null,
  // chemicalToxicityTerm: string | null,
  // regulartoryListTerm: string | null,
  searchData: any,
): Promise<any> => {
  const {
    searchTerm,
    pageNumber,
    sortField,
    orderBy,
    targetPestTerm,
    chemicalTypeTerm,
    chemicalToxicityTerm,
    regulartoryListTerm,
  } = searchData;
  // console.log(
  //   searchTerm,
  //   pageNumber,
  //   sortField,
  //   orderBy,
  //   targetPestTerm,
  //   chemicalTypeTerm,
  //   chemicalToxicityTerm,
  //   regulartoryListTerm,
  // );
  return axiosApi.post(
    `${searchApiPrefix}/advancedSearch?page=${pageNumber}&sort=${sortField}&order=${orderBy}&search=${searchTerm}`,
    {
      targetPest: targetPestTerm,
      chemicalType: chemicalTypeTerm,
      chemicalToxicity: chemicalToxicityTerm,
      regulartoryList: regulartoryListTerm,
    },
  );
};

const downloadAdvancedChemicalSearchResults = (
  fileType: string,
  searchData: any,
): Promise<any> => {
  let requestStatement;
  let config: any = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const {
    pageNumber,
    sortField,
    orderBy,
    targetPestTerm,
    chemicalTypeTerm,
    chemicalToxicityTerm,
    regulartoryListTerm,
  } = searchData;

  if (fileType == 'pdf') {
    requestStatement = `${searchApiPrefix}/advChemicalpdf?page=${pageNumber}&sort=${sortField}&order=${orderBy}`;
    config = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    };
  } else {
    requestStatement = `${searchApiPrefix}/advChemicalcsv?page=${pageNumber}&sort=${sortField}&order=${orderBy}`;
  }

  return axiosApi.post(
    /* `${searchApiPrefix}/advProductcsv?page=${pageNumber}&sort=${sortField}&order=${orderBy}`, */
    requestStatement,
    {
      targetPest: targetPestTerm,
      chemicalType: chemicalTypeTerm,
      chemicalToxicity: chemicalToxicityTerm,
      regulartoryList: regulartoryListTerm,
    },
    config,
  );
};

const advanceSearchChemicalApi = {
  requestAdvanceSearchTargetPest,
  requestAdvanceSearchChemicalType,
  requestAdvanceSearchChemicalToxicityList,
  requestAdvanceSearchChemicalRegulatoryList,
  requestAdvancedChemicalSearchResults,
  downloadAdvancedChemicalSearchResults,
};

export default advanceSearchChemicalApi;
