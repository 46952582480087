import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PestContainer from './PestContainer';
import InfoContainer from './InfoContainer';
import ChemicalPropertyContainer from './ChemicalPropertyContainer';
import ProductSelections from '../components/ProductSelections';
import SearchResultTable from '../components/SearchResultTable';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';
import AdvSearchResultsDownload from '../../../components/common/AdvSearchResultsDownload';
import ProductResultTablePlaceholder from '../components/ProductResultTablePlaceholder';
import ProductPagination from '../components/ProductPagination';

const fileDownload = require('js-file-download');

const TitleButton = (props: any) => {
  const [data, setData] = useState({
    list: [],
    metaData: { page: '1', total: '0' },
  });
  const [pestName, setPestName] = useState('');
  const [comodity, setComodity] = useState('');
  const [productName, setProductName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [regNumber, setRegNumber] = useState('');
  const [ChemicalPropertyList, setChemicalPropertyList] = useState([
    { subItems: [{ selected: false, itemValue: null }], itemValue: null },
  ]);
  const [PestList, setPestList] = useState([
    { subItems: [{ selected: false, itemValue: null }], itemValue: null },
  ]);
  const [RegYearList, setRegYearList] = useState([
    { subItems: [{ selected: false, itemValue: null }], itemValue: null },
  ]);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [total, setTotal] = useState('0');
  const [buttonActive, setbuttonActive] = useState(false);
  const [sort, setSort] = useState('name');
  const [order, setOrder] = useState('ASC');
  const [isSorting, setIsSorting] = useState(false);
  const [searchData, setSearchData] = useState({
    sortField: sort,
    orderBy: order,
    pageNumber: '1',
    prodStatus: 'active',
    // pestName: null,
    // siteName: null,
    prodUses: null,
    prodFormulation: null,
    // prodName: null,
    // companyName: null,
    // prodRegNo: null,
    chemToxCategory: null,
    ahwLabel: null,
    regulatoryList: null,
    chemicalType: null,
    prodRegYear: null,
  });
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);

  const getSearchData = async () => {
    try {
      setIsSearchClicked(true);
      setRequestDataComplete(false);
      setDataNotFound(false);
      if(await validateSearchData() === false) return false;
      const results = await advanceSearchProductApi.requestAdvancedProductSearchResults(
        searchData,
        pestName,
        comodity,
        productName,
        companyName,
        regNumber,
      );

      setData(results.data.data);
      setTotal(results.data.data.metaData.total);
      setRequestDataComplete(true);
      if (window.screen.width > 768) {
        window.scrollTo(0, 485);
      } else if (window.screen.width > 400) {
        window.scrollTo(0, 950);
      } else {
        window.scrollTo(0, 1550);
      }
      setIsSorting(false);
    } catch (e) {
      if (e.response && e.response.status) {
        setDataNotFound(true);
      }
    }
  };

  const validateSearchData = async () => {
       if(searchData.ahwLabel === null && searchData.chemToxCategory === null && searchData.chemicalType === null
            && searchData.prodFormulation === null && searchData.regulatoryList === null
            && searchData.prodRegYear === null && comodity === ""
            && companyName === "" && pestName === "" && productName === ""
            && regNumber === ""  && searchData.prodUses === null){
            return false;
        }
       return true;
   };

  const searchButtonClick = () => {
    for (let i = 0; i < PestList.length; i++) {
      const item = PestList[i];
      for (
        let innerIndex = 0;
        innerIndex < item.subItems.length;
        innerIndex++
      ) {
        if (item.itemValue == 'prodFormulation') {
          searchData.prodFormulation =
            item.subItems[innerIndex].selected == true
              ? item.subItems[innerIndex].itemValue
              : null;
          if (item.subItems[innerIndex].selected == true) {
            break;
          }
        }
        if (item.itemValue == 'prodUses') {
          searchData.prodUses =
            item.subItems[innerIndex].selected == true
              ? item.subItems[innerIndex].itemValue
              : null;
          if (item.subItems[innerIndex].selected == true) {
            break;
          }
        }
      }
    }

    for (let x = 0; x < RegYearList.length; x++) {
      const item = RegYearList[x];
      for (
        let innerIndex = 0;
        innerIndex < item.subItems.length;
        innerIndex++
      ) {
        if (item.itemValue == 'prodRegYear') {
          searchData.prodRegYear =
            item.subItems[innerIndex].selected == true
              ? item.subItems[innerIndex].itemValue
              : null;
          if (item.subItems[innerIndex].selected == true) {
            break;
          }
        }
      }
    }

    for (let k = 0; k < ChemicalPropertyList.length; k++) {
      const item = ChemicalPropertyList[k];
      for (
        let innerIndex = 0;
        innerIndex < item.subItems.length;
        innerIndex++
      ) {
        if (item.itemValue == 'chemToxCategory') {
          searchData.chemToxCategory =
            item.subItems[innerIndex].selected == true
              ? item.subItems[innerIndex].itemValue
              : null;
          if (item.subItems[innerIndex].selected == true) {
            break;
          }
        }
        if (item.itemValue == 'ahwLabel') {
          searchData.ahwLabel =
            item.subItems[innerIndex].selected == true
              ? item.subItems[innerIndex].itemValue
              : null;
          if (item.subItems[innerIndex].selected == true) {
            break;
          }
        }
        if (item.itemValue == 'regulatoryList') {
          searchData.regulatoryList =
            item.subItems[innerIndex].selected == true
              ? item.subItems[innerIndex].itemValue
              : null;
          if (item.subItems[innerIndex].selected == true) {
            break;
          }
        }
        if (item.itemValue == 'chemicalType') {
          searchData.chemicalType =
            item.subItems[innerIndex].selected == true
              ? item.subItems[innerIndex].itemValue
              : null;
          if (item.subItems[innerIndex].selected == true) {
            break;
          }
        }
      }
    }

    getSearchData();
  };

  const downloadSearchData = async (fileType: string) => {
    try {
      const results = await advanceSearchProductApi.downloadAdvancedProductSearchResults(
        fileType,
        searchData,
        pestName,
        comodity,
        productName,
        companyName,
        regNumber,
      );

      if (fileType == 'pdf') {
        fileDownload(
          results.data,
          `advanced-product-download-${Date.now()}.pdf`,
        );
      } else {
        fileDownload(
          results.data,
          `advanced-product-download-${Date.now()}.csv`,
        );
      }
    } catch (e) {
      if (e.response && e.response.status) {
      }
    }
  };

  const downloadlick = (fileType: string) => {
    downloadSearchData(fileType);
  };

  const pageClick = (e: any, pageNumber: string) => {
    e.preventDefault();
    searchData.pageNumber = pageNumber;
    getSearchData();
  };

  const clickSort = (sortName: string) => {
    setIsSorting(true);
    let pageOrder = searchData.orderBy;
    if (sortName === searchData.sortField) {
      pageOrder = searchData.orderBy === 'ASC' ? 'DESC' : 'ASC';
    } else {
      pageOrder = 'ASC';
    }
    searchData.pageNumber = '1';
    searchData.sortField = sortName;
    searchData.orderBy = pageOrder;
    getSearchData();
  };

  return (
    <>
      <section className="filter-container">
        <Container>
          <Row>
            <Col lg="12">
              <div className="filter-wrapper">
                <form>
                  <div className="selections">
                    <span>CHOOSE SEARCH OPTION</span>
                    <div>
                      <input
                        type="radio"
                        id="current-registered"
                        name="mainsearch"
                        value="active"
                        defaultChecked
                        onChange={(e) => {
                          searchData.prodStatus = e.target.value;
                        }}
                      />
                      <label htmlFor="chemical-search">
                        Currently Registered Products
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="all-products"
                        name="mainsearch"
                        value="all"
                        onChange={(e) => {
                          searchData.prodStatus = e.target.value;
                        }}
                      />
                      <label htmlFor="product-search">ALL PRODUCTS</label>
                    </div>
                  </div>
                </form>
                <div className="filter-columns">
                  <div className="column">
                    <PestContainer
                      PestList={PestList}
                      setPestList={setPestList}
                      setbuttonActive={setbuttonActive}
                      setPestName={setPestName}
                      setComodity={setComodity}
                      pestName={pestName}
                      comodity={comodity}
                      setIsSearchClicked={setIsSearchClicked}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                    />
                  </div>
                  <div className="column">
                    <InfoContainer
                      setRegYearList={setRegYearList}
                      RegYearList={RegYearList}
                      setbuttonActive={setbuttonActive}
                      setProductName={setProductName}
                      setCompanyName={setCompanyName}
                      setRegNumber={setRegNumber}
                      productName={productName}
                      companyName={companyName}
                      regNumber={regNumber}
                      setIsSearchClicked={setIsSearchClicked}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                    />
                  </div>
                  <div className="column">
                    <ChemicalPropertyContainer
                      ChemicalPropertyList={ChemicalPropertyList}
                      setChemicalPropertyList={setChemicalPropertyList}
                      setbuttonActive={setbuttonActive}
                      setIsSearchClicked={setIsSearchClicked}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <ProductSelections
                PestList={PestList}
                ChemicalPropertyList={ChemicalPropertyList}
                RegYearList={RegYearList}
                setPestList={setPestList}
                setChemicalPropertyList={setChemicalPropertyList}
                setRegYearList={setRegYearList}
                searchButtonClick={searchButtonClick}
                buttonActive={buttonActive}
                setbuttonActive={setbuttonActive}
                productName={productName}
                setProductName={setProductName}
                companyName={companyName}
                setCompanyName={setCompanyName}
                regNumber={regNumber}
                setRegNumber={setRegNumber}
                pestName={pestName}
                setPestName={setPestName}
                comodity={comodity}
                setComodity={setComodity}
                isSearchClicked={isSearchClicked}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <SearchResultTable
        data={data}
        list={data.list}
        pageClick={pageClick}
        requestDataComplete={requestDataComplete}
        dataNotFound={dataNotFound}
        numberOfResults={total}
        clickSort={clickSort}
      />
      <ProductResultTablePlaceholder
        isSorting={isSorting}
        numberOfResults={total}
      />
      {requestDataComplete ? (
        <AdvSearchResultsDownload
          csvParams={`sort=${sort}&order=${order}&type=product&search=`}
          pdfParams={`sort=${sort}&order=${order}&type=product&search=`}
          downloadlick={downloadlick}
        />
      ) : (
        ''
      )}
      <ProductPagination
        currentPage={parseInt(data.metaData.page,10)}
        numPages={Math.ceil(parseInt(data.metaData.total) / 25)}
        pageClick={pageClick}
        requestDataComplete={requestDataComplete}
        dataNotFound={dataNotFound}
      />
      <div style={{ padding: '60px  0 0 0' }} />
    </>
  );
};
export default TitleButton;
