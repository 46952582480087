import React from 'react';
import Helmet from 'react-helmet';
import GlobalBanMapMainContainer from '../containers/GlobalBanMapMainContainer';

const BlogInner = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Global Ban Map</title>
        <meta name="description" content="Global Ban Map" />
      </Helmet>
      <GlobalBanMapMainContainer />
    </>
  );
};
export default BlogInner;
