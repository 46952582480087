import React from 'react';
import OverviewBreadcrumb from '../components/OverviewBreadcrumb';
import OverviewTextBlock from '../components/OverviewTextBlock';
import useOverviewContent from './useOverviewContent';
import Donation from '../../../components/common/Donation';
import NewsLetterForm from '../../../components/common/NewsLetterForm';


const BlogInner = () => {
  const overviewProps = useOverviewContent();
  const { content, acf } = overviewProps;
  let enableDonation = acf.enable_donation;
  let enableNewsletterSubscription = acf.enable_newsletter_subscription;
  return (
    <>
      <OverviewBreadcrumb />
      <OverviewTextBlock content={content} acf={acf} />
      {enableDonation==="Yes" ? <Donation /> :''}
      {enableNewsletterSubscription === "Yes" ? <NewsLetterForm /> : ''}
    </>
  );
}; 
export default BlogInner;
