import React, { useState } from 'react';
import { Button } from 'reactstrap';
import TableViewMoreModal from './TableViewMoreModal';

const TableViewMore = (props: any) => {
  const {
    items,
    total,
    title,
    tableColumnTitles,
    tableItemLink,
    viewMoreEnableCount,
    specialClass,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeClick = () => {
    setIsModalOpen(false);
  };
  const viewmoreLimit = viewMoreEnableCount || 25;
  return (
    <div>
      {total ? (
        <>
          {/* <pre>{JSON.stringify(total.total)}</pre> */}
          {total.total > viewmoreLimit || total > viewmoreLimit ? (
            <div className="button-holder">
              <Button className="viewmore" onClick={() => setIsModalOpen(true)}>
                view More
              </Button>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      <TableViewMoreModal
        items={items}
        total={total}
        title={title}
        isModalOpen={isModalOpen}
        closeClick={closeClick}
        tableColumnTitles={tableColumnTitles}
        tableItemLink={tableItemLink}
        specialClass={specialClass}
      />
    </div>
  );
};
export default TableViewMore;
