import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TableViewMore from '../../../components/common/TableViewMore';
import SearchResultsDownload from '../../../components/common/SearchResultsDownload';

const TopCounties = (props: any) => {
  const { county, total, chemName } = props;
  const [countyForTable, setountyForTable] = useState([]);

  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();
  const rec_id: string | null = query.get('id') ? query.get('id') : '';

  const tableTitles = [
    {
      label: 'County',
      field: 'County_Name',
    },
    {
      label: 'Gross Pounds',
      field: 'grossPounds',
    },
    {
      label: 'Application Rate',
      field: 'applicationRate',
    },
    {
      label: 'Acres Planted',
      field: 'acresPlanted',
    },
    {
      label: 'Acres Treated',
      field: 'acresTreated',
    },
    {
      label: 'Application Count',
      field: 'applicationCount',
    },
  ];
  const tableItemLink = {
    linkedTo: '/california-pesticide-use/california-crop-detail?sk=',
    field: 'county_cd',
  };

  useEffect(() => {
    if (county) {
      setountyForTable(county.slice(0, 25));
    }
  }, [county]);

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }   
  }

  return (
    <>
      <div className="search-result-table  top-fifty-crops">
        <div className="result-table">
          <div className="result-table-row titles">
            <div>
              <div>County</div>
              <div>Gross Pounds</div>
              <div>Application Rate<br></br>(lbs a.i./acre)</div>
              <div>Acres Planted</div>
              <div>Acres Treated</div>
              <div>Application Count</div>
            </div>
          </div>
          {countyForTable.length > 0 ? (
            countyForTable.map((item: any, index: number) => (
              <div className="result-table-row" key={index}>
                {/* <pre>{JSON.stringify(item)}</pre> */}
                <Link
                  to={`/california-pesticide-use/california-crop-detail?sk=${item.county_cd}`}
                >
                  <div>
                    <h3>{item.County_Name}</h3>
                  </div>
                  <div>{decimalFormat(item.grossPounds.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.applicationRate.replace(/,/g, ""),2)}</div>
                  <div>{decimalFormat(item.acresPlanted.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.acresTreated.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.applicationCount.replace(/,/g, ""),0)}</div>
                </Link>
              </div>
            ))
          ) : (
            <p className="no-results">No Results Found!</p>
          )}
        </div>
        <TableViewMore
          items={county}
          total={total}
          title={chemName}
          tableColumnTitles={tableTitles}
          tableItemLink={tableItemLink}
        />
        {countyForTable.length > 0 ? (
          <SearchResultsDownload
            csvDownloadPath={`californiaChemical?Rec_Id=${rec_id}&tab=chemicalcounties&limit=50&download=csv`}
            pdfDownloadPath={`californiaChemical?Rec_Id=${rec_id}&tab=chemicalcounties&limit=50&download=pdf`}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default TopCounties;
