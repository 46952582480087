import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useChemicalDetailOverview = (id: string) => {
  const [data, setData]: any = useState({
    data: {
      chemicalName: '',
      data: {
        chemicals: {
          chemicals: [],
          metaData: {},
        },
        summary: [],
        toxicity: [],
      },
      latestYear: '',
    },
  });

  useEffect(() => {
    const getCaliforniaChemicalsDetailsOverviewData = async () => {
      try {
        const results = await californiaSearchApi.requestCaliforniaChemicalDetailOverview(
          id,
        );
        if (results.data) {
          setData(results.data.data);
        }
      } catch (e) {}
    };
    getCaliforniaChemicalsDetailsOverviewData();
  }, []);

  return data && data;
};

export default useChemicalDetailOverview;
