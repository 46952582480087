import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestCaliforniaChemicalsList = (): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/californiaChemicals`);
};

const requestCaliforniaCropSitesList = (): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/californiaCropsSites`);
};

const requestCaliforniaRegionCountyList = (): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/californiaRegionCounty`);
};

const requestCaSearchResultsSpecificChemicalCropRegion = (
  chk: string | null,
  sk: string | null,
  cok: string | null,
  minYear: number | null,
  maxYear: number | null,
): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/californiaSearch`, {
    chk,
    sk,
    cok,
    minYear,
    maxYear,
  });
};

const downloadCaliforniaSearchApiResults = (
  fileType: string,
  searchData: any,
): Promise<any> => {
  let requestStatement;
  let config: any = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const { chk, sk, cok, minYear, maxYear } = searchData;

  if (fileType == 'pdf') {
    //TODO
    requestStatement = `${searchApiPrefix}/californiaSearchPdf?chk=${chk}&sk=${sk}&cok=${cok}&minYear=${minYear}&maxYear=${maxYear}`;
    config = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    };
  } else {
    requestStatement = `${searchApiPrefix}/californiaSearchCsv?chk=${chk}&sk=${sk}&cok=${cok}&minYear=${minYear}&maxYear=${maxYear}`;
  }

  return axiosApi.post(requestStatement, {
    chk: chk,
    sk: sk,
    cok: cok,
    minYear: minYear,
    maxYear: maxYear,
  },
  config,
  );
};

const requestCaliforniaChemicalDetailOverview = (
  recId: string,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiaChemical?Rec_Id=${recId}&tab=chemicaloverview`,
  );
};

const requestCaliforniaChemicalDetailTopCrops = (
  recId: string,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiaChemical?Rec_Id=${recId}&tab=chemicalcropsSites&limit=50`,
  );
};

const requestCaliforniaChemicalDetailTopCounties = (
  recId: string,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiaChemical?Rec_Id=${recId}&tab=chemicalcounties&limit=50`,
  );
};

const requestCountyOverviewContent = (cok: string | null): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiacounty?cok=${cok}&tab=countySummary`,
  );
};
const requestCountyTop50PesticidesContent = (
  cok: string | null,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiacounty?cok=${cok}&tab=countyPesticide&limit=50`,
  );
};
const requestCountyTop50CropSitesContent = (
  cok: string | null,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiacounty?cok=${cok}&tab=countyCropsSites&limit=50`,
  );
};

const requestCaliforniaCropDetailOverview = (skId: string): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiacrop?sk=${skId}&tab=cropSummary`,
  );
};

const requestCaliforniaCropDetailTopPesticides = (
  skId: string,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiacrop?sk=${skId}&tab=croppesticide`,
  );
};

const requestCaliforniaCropDetailTopCounties = (skId: string): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiacrop?sk=${skId}&tab=cropcounties&limit=50`,
  );
};

const californiaSearchApi = {
  requestCaliforniaChemicalsList,
  requestCaliforniaCropSitesList,
  requestCaliforniaRegionCountyList,
  requestCaSearchResultsSpecificChemicalCropRegion,
  downloadCaliforniaSearchApiResults,
  requestCountyTop50PesticidesContent,
  requestCountyOverviewContent,
  requestCountyTop50CropSitesContent,
  requestCaliforniaChemicalDetailOverview,
  requestCaliforniaChemicalDetailTopCrops,
  requestCaliforniaChemicalDetailTopCounties,
  requestCaliforniaCropDetailOverview,
  requestCaliforniaCropDetailTopPesticides,
  requestCaliforniaCropDetailTopCounties,
};

export default californiaSearchApi;
