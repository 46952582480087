import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import SideBarContainer from '../../../containers/SideBarContainer';
import CountyDetailBreadcrumb from '../components/CropDetailBreadcrumb';
import OverviewTab from '../components/OverviewTab';
import TopPesticides from '../components/TopPesticides';
import TopCounties from '../components/TopCounties';
import useCropDetailOverview from './useCropDetailOverview';
import useCropDetailTopCounties from './useCropDetailTopCounties';
import useCropDetailTopPesticides from './useCropDetailTopPesticides';

const CropDetailContainer = (props: any) => {
  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();

  const sk: string | null = query.get('sk') ? query.get('sk') : '';
  // const cok: string | null = query.get('cok') ? query.get('cok') : '';

  const overViewTabData = useCropDetailOverview(sk!);
  const topPesticidesTabData = useCropDetailTopPesticides(sk!);
  const topCountiesTabData = useCropDetailTopCounties(sk!);

  const [activeTab, setActiveTab] = useState('1');
  const tabtoggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [overViewData, setOverViewData]: any = useState({
    data: {
      list: {},
    },
  });
  const [topPesticideData, setTopPesticideData]: any = useState({
    data: {
      pesticideCount: '',
      list: [],
    },
  });
  const [topCountiesData, setTopCountiesData]: any = useState({
    data: {
      pesticideCount: '',
      list: [],
    },
  });

  useEffect(() => {
    setOverViewData(overViewTabData);
    // console.log(overViewTabData);
  }, [overViewTabData]);

  useEffect(() => {
    setTopPesticideData(topPesticidesTabData);
    // console.log(topPesticidesTabData);
  }, [topPesticidesTabData]);

  useEffect(() => {
    setTopCountiesData(topCountiesTabData);
  }, [topCountiesTabData]);

  return (
    <>
      <CountyDetailBreadcrumb
        cropName={overViewData.list ? overViewData.list.cropName : ''}
      />
      <section className="tab-main-content california-tab-content">
        <Container>
          <Row>
            <Col lg="9">
              <div className="inner-upper-container">
                <div className="title-container">
                  <h2>{overViewData.list ? overViewData.list.cropName : ''}</h2>
                  <span>
                    {`(All California, ${
                      overViewData.list ? overViewData.list.latestYear : ''
                    })`}
                  </span>
                </div>
              </div>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('1');
                      }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('2');
                      }}
                    >
                      Top 50 Pesticides
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('3');
                      }}
                    >
                      TOP COUNTIES
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane className="overview" tabId="1">
                    <OverviewTab
                      data={overViewData.list ? overViewData.list : ''}
                    />
                  </TabPane>
                  <TabPane className="top-pesticides" tabId="2">
                    <TopPesticides
                      pesticides={topPesticideData.list}
                      total={topPesticideData.pesticideCount}
                      cropName={
                        overViewData.list ? overViewData.list.cropName : ''
                      }
                    />
                  </TabPane>
                  <TabPane className="top-crops-sites" tabId="3">
                    <TopCounties
                      county={topCountiesData.list}
                      total={topCountiesData.pesticideCount}
                      cropName={
                        overViewData.list ? overViewData.list.cropName : ''
                      }
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col lg="3">
              <SideBarContainer />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default CropDetailContainer;
