/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import chemicalApi from '../../../api/chemicalApi';

const useUsageContent = (id: string) => {
  const [data, setData] = useState({
    chemicals: { metaData: { totalchemicals: 0 }, chemicals: [] },
    products: { metaData: { totalProducts: 0 }, products: [] },
    californiaDetails: {
      metaData: { totalcropsSites: 0 },
      chemicalcropsSitesDetails: [],
    },
    agriculturalMap: { latestYear: '', chemicalName: '' },
  });

  useEffect(() => {
    const getUsageData = async () => {
      try {
        const results = await chemicalApi.requestUsageContent(id);
        setData(results.data.data.data);
      } catch (e) {}
    };
    getUsageData();
  }, []);

  return data && data;
};

export default useUsageContent;
