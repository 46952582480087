import React, { useEffect } from 'react';
import TermsConditionsContent from '../components/TermsConditionsContent';
import useTermsConditions from './useTermsConditions';

const TermsConditionsDetails = () => {
  const termsConditionsProps = useTermsConditions();
  const { content, acf } = termsConditionsProps;
  return (
    <>
      <TermsConditionsContent content={content} acf={acf} />
    </>
  );
};

export default TermsConditionsDetails;
