import React from 'react';
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

const ResourcePagination = (props: any) => {
  const { currentPage, numPages, pageClick } = props;
  return (
    <>
      {numPages && numPages > 1 ? (
        <section>
          <Container>
            <Row>
              <Col sm={{ size: 10, offset: 1 }}>
                <Pagination>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink
                      previous
                      href="#"
                      onClick={(e) => pageClick(e, currentPage - 1)}
                    />
                  </PaginationItem>

                  {[...Array(numPages)].map((page, i) => (
                    <PaginationItem active={i === currentPage - 1} key={i}>
                      <PaginationLink
                        href="#"
                        onClick={(e) => pageClick(e, i + 1)}
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= numPages}>
                    <PaginationLink
                      next
                      href="#"
                      onClick={(e) => pageClick(e, currentPage + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <div className="mb-5">&nbsp;</div>
      )}
    </>
  );
};
export default ResourcePagination;
