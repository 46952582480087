import React, { useEffect } from 'react';
import PrivacyPolicyContent from '../components/PrivacyPolicyContent';
import useprivacyPolicy from './usePrivacyPolicy';

const PrivacyPolicyDetails = () => {
  const privacyPolicyProps = useprivacyPolicy();
  const { content, acf } = privacyPolicyProps;
  return (
    <>
      <PrivacyPolicyContent content={content} acf={acf} />
    </>
  );
};

export default PrivacyPolicyDetails;
