
import { useEffect, useState } from 'react';
import catownshipApi from '../../../api/catownshipApi';

const useCaTownshipmapDetails= () => {
  // const { query } = props;
  const [data, setData] = useState({
    
    content: '',
    acf: '',
  });

  useEffect(() => {
    const getOverviewData = async () => {
      try {
        const results = await catownshipApi.requestCaTownshipMapContent();
        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    getOverviewData();
  }, []);
  return data && data;
};

export default useCaTownshipmapDetails;
