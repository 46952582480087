import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestResourceTitle = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/page/title?slug=resource-library`);
  //return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=resource-library`);  
};

const requestResourcePosts = (
  category: number,
  search: string,
  page: number,
): Promise<any> => {
  return axiosApi.get(
    `${wpApiPrefix}/wp/v2/resources?resource-category=${category}&s=${search}&page=${page}`,
  );
};

const requestCategories = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/taxonomy?name=resource-category`);
};

const requestResourceDetails = (slug: string): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/resources?slug=${slug}`);
};

const resourcesApi = {
  requestResourceTitle,
  requestResourcePosts,
  requestResourceDetails,
  requestCategories,
};

export default resourcesApi;
