import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';
import fileSizeConverion from '../../../helpers/file-size-conversion';

interface ResourceInnerArticleBlock {
  fileUpload: any;
}

const ResourceInnerArticle = (
  props: ResourceInnerArticleBlock,
): ReactElement => {
  const { fileUpload } = props;
  const fileCount = fileUpload.length;
  return (
    <>
      {fileCount > 0 ? (
        <Row className="resources-inner-attachements">
          <Col sm="12">
            <div>
              <h2>Attachments</h2>
              <ul>
                {fileUpload.map((item: any, key: number) => (
                  <li key={key}>
                    <a
                      href={item.file.url}
                      target={item.file.subtype == 'pdf' ? '_blank' : '_self'}
                    >
                      <p>{item.file_name}</p>
                      <span>{fileSizeConverion(item.file.filesize)}</span>
                      <span>Download</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
};
export default ResourceInnerArticle;
