/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const ProductResultTablePlaceholder = (props: any) => {
  const { isSorting, numberOfResults } = props;
  const tableRows = new Array(25);
  for (let i = 0, len = tableRows.length; i < len; i += 1) {
    tableRows[i] = {
      html: ``,
    };
  }
  if (isSorting) {
    return (
      <Container>
        <div className="search-result-table container advance-product-result-table">
          <Row>
            <Col lg="12">
              <div className="result-count">
                <h3>
                  Search Results{' '}
                  <span>
                    [{numberOfResults}{' '}
                    {numberOfResults > 1 ? ' Results' : ' Result'}]
                  </span>
                </h3>
              </div>
              <div className="result-table">
                <div className="result-table-row titles">
                  <div>
                    <div>PRODUCT NAME</div>
                    <div>U.S. REG STATUS</div>
                    <div>ACUTE HAZARD WARNING LABEL</div>
                    <div>U.S. RESTRICTED USE STATUS</div>
                    <div>MANUFACTURER</div>
                    <div>PAN BAD ACTOR</div>
                  </div>
                </div>
                {tableRows.map((item: any, key: number) => (
                  <div className="result-table-row" key={key}>
                    <Link to="/">
                      <div>
                        <h3>{item.name}</h3>
                        <h4>{item.label}</h4>
                      </div>
                      <div>{item.us_reg_status}</div>
                      <div>{item.ahw_label}</div>
                      <div>{item.rup_status}</div>
                      <div>{item.company_name}</div>
                      <div>{item.bad_actor}</div>
                    </Link>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
  return <></>;
};
export default ProductResultTablePlaceholder;
