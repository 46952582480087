/* eslint-disable react/no-array-index-key */
import React, { ReactElement } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import CustomAccordion from '../../../components/common/CustomAccordion';

interface FaqQuestionsAnswersBlock {
  title: string;
  questionAnswers: any;
  activeId: number;
  updateActiveId: any;
}

const createMarkup = (text: any) => {
  let originalText = text;
  originalText = originalText.replace(
    '<table',
    '<div class="table-responsive"><table',
  );
  originalText = originalText.replace('</table>', '<table></div>');
  return { __html: originalText };
};

const FaqQuestionsAnswers = (props: FaqQuestionsAnswersBlock): ReactElement => {
  const { title, questionAnswers, activeId, updateActiveId } = props;
  return (
    <>
      <section className="faq-content">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10">
              {questionAnswers.map((item: any, key: number) => (
                <CustomAccordion
                  title={item.question}
                  key={key}
                  index={key}
                  activeId={activeId}
                  updateActiveId={updateActiveId}
                >
                  <div dangerouslySetInnerHTML={createMarkup(item.answer)} />
                </CustomAccordion>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default FaqQuestionsAnswers;
