/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestSimpleSearchResults = (
  searchTerm: string | null,
  searchType: string | null,
  pageNumber: string | null,
  sortField: string | null,
  orderBy: string | null,
): Promise<any> => {
  return axiosApi.post(
    `${searchApiPrefix}/search?page=${pageNumber}&sort=${sortField}&order=${orderBy}`,
    {
      search: searchTerm,
      type: searchType,
    },
  );
};

const simpleSearchResultApi = {
  requestSimpleSearchResults
};

export default simpleSearchResultApi;
