import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const GlobalBanMapTextBlock = (props: any) => {
  const { content } = props;
  return (
    <div className="global-ban-map-content text-block">
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  );
};
export default GlobalBanMapTextBlock;
