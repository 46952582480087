/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import californiaSearchShortcutApi from '../../../api/californiaSearchShortcutApi';

const useTopCountiesContent = () => {
  // const { query } = props;
  const [data, setData] = useState({
    data: {
      data: [],
      metaData: {
        total: '',
      },
    },
  });

  useEffect(() => {
    const getTopCountyData = async () => {
      try {
        const results = await californiaSearchShortcutApi.requestAllPesticideUse();
        setData(results.data);
      } catch (e) {}
    };
    getTopCountyData();
  }, []);

  return data && data;
};

export default useTopCountiesContent;
