/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { searchApiPrefix } from '../../constants';
import config from '../../config';

const SearchResultsDownload = (props: any) => {
  /* const { csvParams, pdfParams } = props;
  const baseURL = `${config.api.BASE_URL}${config.api.API_PREFIX}/`;
  const csvDownloadUrl = `${baseURL}${searchApiPrefix}/searchcsv?${csvParams}`;
  const pdfDownloadUrl = `${baseURL}${searchApiPrefix}/searchpdf?${pdfParams}`; */
  const { csvDownloadPath, pdfDownloadPath } = props;
  const baseURL = `${config.api.BASE_URL}${config.api.API_PREFIX}/`;
  const csvDownloadUrl = `${baseURL}${searchApiPrefix}/${csvDownloadPath}`;
  const pdfDownloadUrl = `${baseURL}${searchApiPrefix}/${pdfDownloadPath}`

  return (
    <Container>
      <Row>
        <Col sm="12">
          <div className="search-result-download">
            <span>
              DOWNLOAD RESULTS
              <ul>
                <li>
                  <a href={csvDownloadUrl}>CSV</a>
                </li>
                <li>
                  <a href={pdfDownloadUrl}>PDF</a>
                </li>
              </ul>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default SearchResultsDownload;
