import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TableViewMore from '../../../components/common/TableViewMore';
import SearchResultsDownload from '../../../components/common/SearchResultsDownload';

const TopCropsSites = (props: any) => {
  const { crops, countyName, total } = props;
  const [cropsForTable, setCropsForTable] = useState([]);

  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();
  const cok: string | null = query.get('cok') ? query.get('cok') : '';

  const tableTitles = [
    {
      label: 'Crop or Site',
      field: 'Commodity_Name',
    },
    {
      label: 'Gross Pounds',
      field: 'grossPounds',
    },
    {
      label: 'Application Rate',
      field: 'applicationRate',
    },
    {
      label: 'Acres Planted',
      field: 'acresPlanted',
    },
    {
      label: 'Acres Treated',
      field: 'acresTreated',
    },
    {
      label: 'Application Count',
      field: 'applicationCount',
    },
  ];
  const tableItemLink = {
    linkedTo: '/california-pesticide-use/california-crop-detail?sk=',
    field: 'site_code',
  };

  useEffect(() => {
    if (crops) {
      setCropsForTable(crops.slice(0, 25));
    }
  }, [crops]);

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }   
  }

  return (
    <>
      <div className="search-result-table top-fifty-crops">
        <div className="result-table">
          <div className="result-table-row titles">
            <div>
              <div>Crop or Site</div>
              <div>Gross Pounds</div>
              <div>Application Rate<br></br>(lbs a.i./acre)</div>
              <div>Acres Planted</div>
              <div>Acres Treated</div>
              <div>Application Count</div>
            </div>
          </div>
          {cropsForTable.length > 0 ? (
            cropsForTable.map((item: any, index: number) => (
              <div className="result-table-row" key={index}>
                <Link
                  to={`/california-pesticide-use/california-crop-detail?sk=${item.site_code}`}
                >
                  <div>
                    <h3>{item.Commodity_Name}</h3>
                  </div>
                  <div>{decimalFormat(item.grossPounds.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.applicationRate.replace(/,/g, ""),2)}</div>
                  <div>{decimalFormat(item.acresPlanted.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.acresTreated.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.applicationCount.replace(/,/g, ""),0)}</div>
                </Link>
              </div>
            ))
          ) : (
            <p className="no-results">No Results Found!</p>
          )}
        </div>
      </div>
      <TableViewMore
        items={crops}
        title={countyName}
        tableColumnTitles={tableTitles}
        tableItemLink={tableItemLink}
        total={total}
      />
      {cropsForTable.length > 0 ? (
        <SearchResultsDownload
          csvDownloadPath={`californiacounty?cok=${cok}&tab=countyCropsSites&download=csv`}
          pdfDownloadPath={`californiacounty?cok=${cok}&tab=countyCropsSites&download=pdf`}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default TopCropsSites;
