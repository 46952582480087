import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

const EcotoxicityEnvironmental = (props: any) => {
  const { data } = props;

  if (data) {
    return (
      <Table responsive bordered>
        <tbody>
          <tr>
            <td className="heading" colSpan={2}>
              ENVIRONMENTAL TOXICITY
            </td>
          </tr>
          {data.map((item: any, key: number) => (
            <>
              <tr key={key}>
                <td colSpan={2}>
                  <div>
                    For in-depth information on Ecotoxicity by Chemical,
                    Species, and Effect, we encourage users to visit EPA’s
                    ECOTOX site which is updated regularly. We provide detailed,
                    step-by-step search guidance on our{' '}
                    <Link to="/resources/ecotoxicity-resources">
                      Ecotoxicity Resources
                    </Link>{' '}
                    page, so users can more easily access and use EPA’s site.
                  </div>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    );
  }
  return <></>;
};
export default EcotoxicityEnvironmental;
