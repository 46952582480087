const config = {
  api: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BASE_URL_TEMP: process.env.REACT_APP_BASE_URL_TEMP,
    MEDIA_URL: process.REACT_APP_MEDIA_URL,
    API_PREFIX: process.env.REACT_APP_API_PREFIX,
    // BASE_URL_TEMP: process.env.REACT_APP_BASE_URL_TEMP,
  },
  googleAnalytic: {
    GA_TRACK_ID: process.env.REACT_APP_GA_TRACK_ID,
  },
};

export default {
  ...config,
};
