import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PostBlock from '../../../components/common/PostBlock';

const RelatedPosts = (props: any): ReactElement => {
  const { relatedPosts } = props;
  return (
    <section className="resources-related-posts">
      <Container className="common-inner-related-articles">
        <Row>
          <Col sm="12">
            <h2>You may also be interested in...</h2>
          </Col>
          {relatedPosts.map((item: any, key: number) => (
            <Col md="4" key={key}>
              <PostBlock type="resource-small blog-related resource-related-small">
                <a href={item.slug}>
                  <div className="post-block__featured-image">
                    <img src={item.relatedPostThumbnail} alt="" />
                  </div>
                  <div className="post-block__description">
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: item.relatedPostTitle,
                      }}
                    ></h3>
                    {item.relatedPostCategory.length > 0 && (
                      <h4>
                        {item.relatedPostCategory.map(
                          (categroy: any, key: number) => (
                            <span
                              key={key}
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: categroy,
                              }}
                            />
                          ),
                        )}
                      </h4>
                    )}
                    <p> {item.relatedPostExcerpt}</p>
                    <span>
                      {!!item.relatedPostLinkText
                        ? item.relatedPostLinkText
                        : 'Read More'}
                    </span>
                  </div>
                </a>
              </PostBlock>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
export default RelatedPosts;
