import { Console } from 'console';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
// import skullImage from '../../assets/images/detailpages/badactor.svg';
// import questionImage from '../../assets/images/detailpages/highhazaradous.svg';
import TooltipInTable from './TooltipInTable';

const KeyValueDataTable = (props: any): ReactElement => {
  const {
    tableHeading,
    tableData,
    linkData,
    tableSection = 'chemical',
  } = props;

  const isHTML = (str: string) =>
    !(str || '')
      .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
      .replace(/(<([^>]+)>)/gi, '')
      .trim();

  const convertHtml = (param: string) => {
    let output: any;
    
    if (param.indexOf('skull7.gif') > -1) {
      output = 'Yes';
    } else if (param.indexOf('question_mark4a.gif') > -1) {
      output = 'Insufficiently Studied';
    }else if(param.indexOf('<font color="#FF0000">Suspected</font>') > -1){
      output = 'Suspected';
    }else if(param.indexOf('<font color="#FF0000">Moderate</font>') > -1){
      output = 'Moderate';
    }else if(param.indexOf('<font color="#FF0000">Possible</font>') > -1){
      output = 'Possible';
    }else if(param.indexOf('<font color="#FF0000">Potential</font>') > -1){
      output = 'Potential';
    } else {
      output = false;
    }
    return output;
  };

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }   
  }

  return (
    <>
      <div className="data-table">
        <div className="data-table-heading">{tableHeading}</div>
        <div className="data-table-content">
          {tableData.map((item: any, key: number) => (
            <div className="data-table-key-value" key={key}>
              <div>
                {
                  item.key === "Bee Toxin" ? ('Bee Toxicant'):
                  item.key === "Development or Reproductive Toxin" ? ('Development Or Reproductive Toxicant'):
                  
                item.key!="Reference Dose (g/kg/day)" ? 
                (item.key)
                :
                <div dangerouslySetInnerHTML={{ __html: 'Reference Dose <span class="kg-inner">(g/kg/day)</span>' }} />  
                }
                {item.tooltipKey ? (
                  <TooltipInTable
                    tooltipKey={item.tooltipKey}
                    section={tableSection}
                  />
                ) : (
                  ''
                )}
                <span>:</span>
              </div>
              {isHTML(`${item.value}`) ? (
                <div>
                    { 
                    item.key==="PAN Bad Actor" && item.value==='<div align="center"><img src="images/skull7.gif" width="32" height="32" align="bottom"></div>'?(<div dangerouslySetInnerHTML={{ __html: '<font color="#FF0000">Yes</font>' }} />)
                    :
                    (
                      convertHtml(item.value) ? (
                        convertHtml(item.value)
                      ) : ( 
                        <div dangerouslySetInnerHTML={{ __html: item.value }} />
                      )
                    )}                    
                </div>
              ) : (
                <div>
                  {
                  item.key==="Highly Hazardous Pesticide" && item.value==='Yes'?(<div dangerouslySetInnerHTML={{ __html: '<font color="#FF0000">Yes</font>' }} />)
                    :                    
                    item.value === 'No Data' || item.value === '0'  ? ('Not Listed')
                    :
                    item.value === '1' ? ('Yes') 
                    : 
                    item.key==="EPA PC code" ? item.value.replace(/,/g, ', '):
                    item.key==="CAS number" ? item.value.replace(/,/g, ', '):
                    !(item.key === "Molecular weight" || item.key === 'CA DPR chem code') ? decimalFormat(item.value, 1) : item.value         
                  }</div>
              )}
            </div>
          ))}
        </div>
        {linkData ? (
          <div className="data-table-link">
            <Link to={linkData.linkTo}>{linkData.label}</Link>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default KeyValueDataTable;
