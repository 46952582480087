import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';

const TableCardSymptoms = (props: any) => {
  const { tableData, chemName } = props;
  const [poisioningData, setPoisioningData] = useState({
    summarySignsSymptoms: {
      summarySignsSymptoms: '',
      summarySymptomURL: '',
      summaryTreatmentURL: '',
    },
    poisioningSignsSymptoms: {
      inhalationSymptom: '',
      inhalationFirstaid: '',
      skinSymptoms: '',
      skinFirstaid: '',
      eyesSymptoms: '',
      eyesFirstaid: '',
      ingestionSymptoms: '',
      ingestionFirstaid: '',
      icscNotes: '',
    },
  });
  useEffect(() => {
    const temp = tableData[0];
    setPoisioningData(temp);
  }, [tableData]);
  
  return (
    <Table responsive bordered>
      <tbody>
        <tr>
          <td className="heading" colSpan={2}>
            {chemName} POISONING: SIGNS & SYMPTOMS <i className="healthsign" />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    Summary signs & symptoms for chemical:
                    <br />
                    {poisioningData ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            poisioningData.summarySignsSymptoms
                              .summarySignsSymptoms,
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td>
                    Summary signs & symptoms group <span>:</span>
                  </td>
                  <td>                  
                    {poisioningData && poisioningData.summarySignsSymptoms.summarySymptomURL ? (
                      <a 
                        href={poisioningData.summarySignsSymptoms
                        .summarySymptomURL} 
                        target={'_blank'}> 
                        View Source
                      </a>                                              
                    ) : (
                      'Not Listed'
                    )} 
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table>
              <tbody>
                <tr>
                  <td>
                    Treatment <span>:</span>
                  </td>
                  <td>
                  {poisioningData && poisioningData.summarySignsSymptoms.summaryTreatmentURL ? (
                      <a 
                        href={poisioningData.summarySignsSymptoms.summaryTreatmentURL} 
                        target={'_blank'}>
                         View Source 
                      </a>                                              
                    ) : (
                      'Not Listed'
                    )}                  
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <strong>
              Symptoms of Exposure from the International Chemical Safety Card:
            </strong>
            {poisioningData ? (
              <table>
                <tbody>
                  <Table responsive striped className="symptoms-table">
                    <thead>
                      <tr>
                        <th>Route of Exposure</th>
                        <th>Symptoms</th>
                        <th>First Aid</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Inhalation</td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .inhalationSymptom || 'No data available'}
                        </td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .inhalationFirstaid || 'No data available'}
                        </td>
                      </tr>
                      <tr>
                        <td>Skin</td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .skinSymptoms || 'No data available'}
                        </td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .skinFirstaid || 'No data available'}
                        </td>
                      </tr>
                      <tr>
                        <td>Eyes</td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .eyesSymptoms || 'No data available'}
                        </td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .eyesFirstaid || 'No data available'}
                        </td>
                      </tr>
                      <tr>
                        <td>Ingestion</td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .ingestionSymptoms || 'No data available'}
                        </td>
                        <td>
                          {poisioningData.poisioningSignsSymptoms
                            .ingestionFirstaid || 'No data available'}
                        </td>
                      </tr>
                      {poisioningData.poisioningSignsSymptoms.icscNotes ? (
                        <>
                        <tr>
                          <td colSpan={3}>
                          Notes for ICSC Information
                          </td>
                        </tr> 
                        <tr>
                        <td colSpan={3}>
                        {poisioningData.poisioningSignsSymptoms.icscNotes}
                          </td>
                        </tr>
                        </>
                        ) : (
                          ''
                        )}
                    </tbody>
                  </Table>
                </tbody>
              </table>
            ) : (
              ''
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
export default TableCardSymptoms;
