import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestadvanceSearchContent = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=advanced-search`);
};

const advanceSearchApi = {
  requestadvanceSearchContent,
};

export default advanceSearchApi;
