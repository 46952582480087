import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import skullImage from '../../assets/images/detailpages/badactor.svg';
import questionImage from '../../assets/images/detailpages/highhazaradous.svg';

const KeyValueDataTable = (props: any): ReactElement => {
  const { tableHeading, tableData, linkData } = props;

  const isHTML = (str: string) =>
    !(str || '')
      .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
      .replace(/(<([^>]+)>)/gi, '')
      .trim();

  const convertHtml = (param: string) => {
    let output: any;
    if (param.indexOf('skull7.gif') > -1) {
      output = 'Yes';
    } else if (param.indexOf('question_mark4a.gif') > -1) {
      output = 'Insufficiently Studied';
    } else {
      output = false;
    }
    return output;
  };

  return (
    <>
      <div className="data-table multiple-key-value-table">
        <div className="data-table-heading">{tableHeading}</div>

        <div className="data-table-content">
          {tableData.map((item: any, key: number) => (
            <div className="main-wrapper" key={key}>
              <div className="data-table-key-value">
                <div>
                  Active Ingredients<span>:</span>
                </div>
                <div>
                  <a target="_blank" href={item.ingredientLink}>
                    {item.activeIngredientName}
                  </a>
                </div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Cholinesterase Inhibitor<span>:</span>
                </div>
                <div>
                  {convertHtml(item.cholinesteraseInhibitor) ? (
                    convertHtml(item.cholinesteraseInhibitor)
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.cholinesteraseInhibitor,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Percent Active Ingredients<span>:</span>
                </div>
                <div>{item.percentActiveIngredient}</div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Developmental Or Reproductive Toxicant<span>:</span>
                </div>
                <div>
                  {convertHtml(item.devOrReprod) ? (
                    convertHtml(item.devOrReprod)
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: item.devOrReprod }}
                    />
                  )}
                </div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Carcinogen<span>:</span>
                </div>
                <div>
                  {' '}
                  {convertHtml(item.carcinogen) ? (
                    convertHtml(item.carcinogen)
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: item.carcinogen }}
                    />
                  )}
                </div>
              </div>
              <div className="data-table-key-value">
                <div>
                  Endocrine Disruptor<span>:</span>
                </div>
                <div>
                  {' '}
                  {convertHtml(item.endocrineDisruptor) ? (
                    convertHtml(item.endocrineDisruptor)
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.endocrineDisruptor,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          {/* <div className="data-table-key-value" >
            <div>
              {item.key}
              <span>:</span>
            </div>
            {isHTML(`${item.value}`) ? (
              <div>
                {convertHtml(item.value) ? (
                  convertHtml(item.value)
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: item.value }} />
                )}
              </div>
            ) : (
              <div>{item.value}</div>
            )}
          </div> */}
        </div>

        {linkData ? (
          <div className="data-table-link">
            <Link to={linkData.linkTo}>{linkData.label}</Link>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default KeyValueDataTable;
