/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import productApi from '../../../../api/productApi';

const UseProductRegistration = (regno: string, distno: string) => {
  const [data, setData] = useState({
    prodStatus: '',
    uses: [],
    pests: {
      metaData: { totalPests: '' },
      pests: [],
    },
    commodityOrSite: {
      metaData: { totalCommodities: '' },
      commodities: [],
    },
  });

  useEffect(() => {
    const getProductRegistrationData = async () => {
      try {
        const results = await productApi.requestProductRegistrationContent(
          regno,
          distno,
        );

        setData(results.data.data.data);
      } catch (e) {}
    };
    getProductRegistrationData();
  }, []);

  return data && data;
};

export default UseProductRegistration;
