import React from 'react';
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

const ProductPagination = (props: any) => {
  const {
    currentPage,
    numPages,
    pageClick,
    requestDataComplete,
    dataNotFound,
  } = props;
  const currentPageNumber = parseInt(currentPage, 10);
  if (!dataNotFound && requestDataComplete) {
    return (
      <>
        {numPages && numPages > 1 ? (
          <Container>
            <Row>
              <Col sm={{ size: 10, offset: 1 }}>
                <Pagination>
                  <PaginationItem disabled={currentPageNumber <= 1}>
                    <PaginationLink
                      previous
                      href="#"
                      onClick={(e) => pageClick(e, currentPageNumber - 1)}
                    />
                  </PaginationItem>

                  {[...Array(numPages)].map((page, i) => (
                    <PaginationItem
                      active={i === currentPageNumber - 1}
                      key={i}
                    >
                      <PaginationLink
                        href="#"
                        onClick={(e) => pageClick(e, i + 1)}
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPageNumber >= numPages}>
                    <PaginationLink
                      next
                      href="#"
                      onClick={(e) => pageClick(e, currentPageNumber + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </Container>
        ) : (
          ''
        )}
      </>
    );
  }
  return <></>;
};
export default ProductPagination;
