import React, { useEffect, useState } from 'react';
import ChmicalPropertyFilter from '../components/ChmicalPropertyFilter';
import UseChemicalPropertyContent from './UseChemicalPropertyContainer';

const ChemicalProperyyContainer = (props: any) => {
  const {
    setChemicalPropertyList,
    ChemicalPropertyList,
    setbuttonActive,
    setIsSearchClicked,
    selectedValues,
    setSelectedValues,
  } = props;
  const chem = UseChemicalPropertyContent();

  const [chemProps, setchemProps] = useState();
  const [
    noOfChemPropertyListSelected,
    setNoOfChemPropertyListSelected,
  ] = useState(0);
  useEffect(() => {
    setchemProps(chem);
  }, []);
  return (
    <>
      <ChmicalPropertyFilter
        setChemicalPropertyList={setChemicalPropertyList}
        noOfChemPropertyListSelected={noOfChemPropertyListSelected}
        setNoOfChemPropertyListSelected={setNoOfChemPropertyListSelected}
        data={chem}
        ChemicalPropertyList={ChemicalPropertyList}
        setbuttonActive={setbuttonActive}
        setIsSearchClicked={setIsSearchClicked}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
      />
    </>
  );
};
export default ChemicalProperyyContainer;
