/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestUsgsMapContent = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=usgs-maps`);
};

const overviewApi = {
  requestUsgsMapContent,
};

export default overviewApi;
