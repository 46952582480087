/* eslint-disable eqeqeq */
import React from 'react';

const CustomAccordion = (props: any) => {
  const { title, children, index, activeId, updateActiveId } = props;
  return (
    <div className="custom-accordion">
      <div
        className={`accordion-title ${activeId == index ? 'open' : ''}`}
        onClick={(): void => updateActiveId(index)}
        onKeyDown={(): void => updateActiveId(index)}
        role="button"
        tabIndex={0}
      >
        {title}
      </div>
      <div className={`accordion-item ${activeId != index ? 'collapsed' : ''}`}>
        <div className="accordion-content text-block">{children}</div>
      </div>
    </div>
  );
};
export default CustomAccordion;
