import React from 'react';
import TableViewMore from '../../../components/common/TableViewMore';
import SearchResultsDownload from '../../../components/common/SearchResultsDownload';

const TopCounties = (props: any) => {
  const { topCountiesData, total, topCountiesFull } = props;

  const title = 'All Pesticide Use by County';
  const tableTitles = [
    {
      label: 'County',
      field: 'County_Name',
    },
    {
      label: 'Gross Pounds',
      field: 'grossPounds',
    },
    {
      label: 'Application Rate',
      field: 'applicationRate',
    },
    {
      label: 'Acres Planted',
      field: 'acresPlanted',
    },
    {
      label: 'Acres Treated',
      field: 'acresTreated',
    },
    {
      label: 'Application Count',
      field: 'applicationCount',
    },
  ];
  const tableItemLink = {
    linkedTo: '/california-pesticide-use/california-county-detail?cok=',
    field: 'county_cd',
  };

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }   
  }

  return (
    <>
      <div className="search-result-table top-fifty-crops">
        <div className="result-table">
          <div className="result-table-row titles">
            <div>
              <div>County</div>
              <div>Gross Pounds</div>
              <div>Application Rate (lbs a.i./acre)</div>
              <div>Acres Planted</div>
              <div>Acres Treated</div>
              <div>Application Count</div>
            </div>
          </div>
          {topCountiesData.map((item: any, key: number) => (
            <div className="result-table-row">
              <a
                href={`/california-pesticide-use/california-county-detail?cok=${item.county_cd}`}
              >
                <div>
                  <h3>{item.County_Name}</h3>
                </div>
                <div>{decimalFormat(item.grossPounds.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.applicationRate.replace(/,/g, ""),2)}</div>
                  <div>{decimalFormat(item.acresPlanted.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.acresTreated.replace(/,/g, ""),1)}</div>
                <div>{decimalFormat(item.applicationCount.replace(/,/g, ""),0)}</div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <TableViewMore
        items={topCountiesFull}
        title={title}
        tableColumnTitles={tableTitles}
        tableItemLink={tableItemLink}
        total={total}
      />
      <SearchResultsDownload
        csvDownloadPath={`californiaPestUseByCountyDownload?sort=Gross_Lbs&order=DESC&download=csv`}
        pdfDownloadPath={`californiaPestUseByCountyDownload?sort=Gross_Lbs&order=DESC&download=pdf`}
      />
    </>
  );
};

export default TopCounties;
