/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestPrivacyPolicyDetails = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=privacy-policy`);
};

const privacyPolicyApi = {
  requestPrivacyPolicyDetails,
};

export default privacyPolicyApi;
