/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';

const AutoComplete = (props: any) => {
  const {
    output,
    setOutput,
    getsuggestions,
    suggestions,
    placeholder,
    setFinalSearchValue,
    searchinput,
    setIsSearchClicked,
    searchButton,
  } = props;
  const wrapperRef = useRef(null);

  const [autoComplete, setAutoComplete] = useState({
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: '',
  } as any);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setAutoComplete({
            ...autoComplete,
            showSuggestions: false,
          });
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  const onFocus = () => {
    if (autoComplete.showSuggestions) {
      setAutoComplete({
        ...autoComplete,
        showSuggestions: false,
      });
    } else {
      setAutoComplete({
        ...autoComplete,
        showSuggestions: true,
      });
    }
  };

  // Event fired when the input value is changed
  const onChange = async (e: any) => {
    if(setIsSearchClicked)  setIsSearchClicked(false);
    const userInput = e.currentTarget.value;
    setOutput(userInput);
    if (userInput.length > 3) {
      await getsuggestions(userInput);
    }
    const filteredSuggestions = suggestions;
    setAutoComplete({
      ...autoComplete,
      filteredSuggestions,
      showSuggestions: true,
      userInput,
    });
  };

  const onSelect = (e: any) => {
    const selectedValue = e.currentTarget.innerText;
    // Update the user input and reset the rest of the state
    setAutoComplete({
      ...autoComplete,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: selectedValue,
    });
    setOutput(selectedValue);
    setFinalSearchValue(selectedValue);
    if (searchinput) {
      searchinput.current.focus();
    }


  };

  const onKeyUp = async (e: any) => {
    if (e.keyCode === 13) {
      // Cancel the default action, if needed
      e.preventDefault();
      // Trigger the button element with a click
      // Update the user input and reset the rest of the state
      const selectedValue = e.target.value;
      setAutoComplete({
        ...autoComplete,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: selectedValue,
      });
      setOutput(selectedValue);
      setFinalSearchValue(selectedValue);

    }
  };

  return (
    <>
      <div className="auto-complete" ref={wrapperRef}>
        <input
          type="search"
          onChange={onChange}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          value={output}
          placeholder={placeholder}
          ref={searchinput}
        />
        {searchButton ? (<input type="submit" value="" className='search-button ' />): ''}
       {autoComplete.showSuggestions &&
        autoComplete.userInput &&
        autoComplete.userInput.length > 3 &&
        suggestions !== undefined ? (
          <div className="list">
            {suggestions.length ? (
              <ul>
                {suggestions.map((suggestion: any, index: number) => (
                  <li key={index} onClick={onSelect}>
                    {suggestion.suggestion}
                  </li>
                ))}
              </ul>
            ) : (
              <ul>
                <li>No Results Found</li>
              </ul>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default AutoComplete;
