import React from 'react';
import KeyValueDataTableWithTwoColumnsWithLinks from '../../../components/common/KeyValueDataTableWithTwoColumnsWithLinks';

const ProductRegistrationUses = (props: any) => {
  const { uses } = props;
  return (
    <KeyValueDataTableWithTwoColumnsWithLinks
      tableHeading="REGISTERED USES"
      tableData={uses}
      classname="two-columns"
      link="/advance-search/product-persist?uses="
    />
  );
};
export default ProductRegistrationUses;
