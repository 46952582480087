import React from 'react';
import Helmet from 'react-helmet';
import ResultContainer from '../containers/SearchResultContainer';

const SearchResulContainer = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California Pesticide Use Search Results</title>
        <meta
          name="description"
          content="California Pesticide Use Search Results"
        />
      </Helmet>
      <ResultContainer />
    </>
  );
};
export default SearchResulContainer;
