/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const SearchResultPlaceholderTable = (props: any) => {
  const { type, isSorting } = props;
  const tableRows = new Array(25);
  for (let i = 0, len = tableRows.length; i < len; i += 1) {
    tableRows[i] = {
      html: ``,
    };
  }
  if (isSorting) {
    return (
      <Container className="search-result-table">
        <Row>
          <Col sm="12">
            {type === 'chemical' ? (
              <div className="result-table">
                <div className="result-table-row titles">
                  <div>
                    <div>Chemical Name</div>
                    <div>EPA REGISTERED</div>
                    <div>PAN BAD ACTOR</div>
                    <div>PAN INT. HHP</div>
                    <div>EU REGISTERED</div>
                  </div>
                </div>
                {tableRows.map((item: any, key: number) => (
                  <div className="result-table-row" key={key}>
                    <div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="result-table">
                <div className="result-table-row titles">
                  <div>
                    <div>REGISTERED NAME</div>
                    <div>U.S. REG STATUS</div>
                    <div>ACUTE HAZARD WARNING LABEL</div>
                    <div>U.S. RESTRICTED USE STATUS</div>
                    <div>PAN BAD ACTOR</div>
                  </div>
                </div>
                {tableRows.map((item: any, key: number) => (
                  <div className="result-table-row" key={key}>
                    <div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
  return <></>;
};
export default SearchResultPlaceholderTable;
