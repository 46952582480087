/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix, searchApiPrefix } from '../constants';

const globalBanMapContent = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=global-ban`);
};

const globalBanMapCountries = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/globalBanCounty`);
};

const globalBanMapChemicals = (search: string): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/globalBanChemicals`, {
    search,
  });
};

const globalBanMapSelectedCountryChemical = (
  country: string,
  chemical: string,
): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/mapFilterSelection`, {
    countryName: country,
    Rec_Id: chemical,
  });
};

const globalBanMapApi = {
  globalBanMapContent,
  globalBanMapCountries,
  globalBanMapChemicals,
  globalBanMapSelectedCountryChemical,
};

export default globalBanMapApi;
