/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const PrivacyPolicyContent = (props: any) => {
  const { acf, content } = props;
  const { page_title } = acf;
  const { rendered } = content;

  return (
    <section className="common-page-content custom-padding-top">
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="text-center">{page_title}</h1>
            <div
              className="text-block"
              dangerouslySetInnerHTML={{ __html: rendered }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default PrivacyPolicyContent;
