import { useEffect, useState } from 'react';
import globalBanMapApi from '../../../api/globalBanMapApi';

const useGlobalMapCountryContent = () => {
  // const { query } = props;
  const [data, setData] = useState({
    list: [],
  });

  useEffect(() => {
    const getGlobalBanMapCountryData = async () => {
      try {
        const results = await globalBanMapApi.globalBanMapCountries();

        setData(results.data.data);
      } catch (e) {}
    };
    getGlobalBanMapCountryData();
  }, []);

  return data && data;
};

export default useGlobalMapCountryContent;
