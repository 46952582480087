/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestChemicalOverviewContent = (id: string): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/chemical?Rec_Id=${id}&tab=overview`);
};

const requestChemicalHealthContent = (id: string): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/chemical?Rec_Id=${id}&tab=health`);
};

const requestChemicalRegulationContent = (id: string): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/chemical?Rec_Id=${id}&tab=regulation`,
  );
};

const requestEcotoxicityContent = (id: string): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/chemical?Rec_Id=${id}&tab=ecotoxicity`,
  );
};

const requestUsageContent = (id: string): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/chemical?Rec_Id=${id}&tab=usage`);
};

const requestChemicalOthernames = (id: string): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/chemicalNames?Rec_Id=${id}`);
};

const requestChemicalUsageContain = (id: string): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/chemProducts?Rec_Id=${id}`);
};

const requestRelatedChemicals = (id: string): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/relatedChemicals?Rec_Id=${id}`);
};

const requestChemicalUsageProductSuggestions = (
  id: string,
  searchTerm: string,
  limit: number,
): Promise<any> => {
  return axiosApi.post(
    `${searchApiPrefix}/chemProducts?Rec_Id=${id}&limit=${limit}`,
    {
      search: searchTerm,
    },
  );
};

const chemicalApi = {
  requestChemicalOverviewContent,
  requestChemicalHealthContent,
  requestChemicalRegulationContent,
  requestEcotoxicityContent,
  requestUsageContent,
  requestChemicalOthernames,
  requestChemicalUsageContain,
  requestChemicalUsageProductSuggestions,
  requestRelatedChemicals,
};

export default chemicalApi;
