import React, { useState } from 'react';

const EmbedWidget = () => {
  const [mapState, setMapstate]: any = useState(false);
  const iframeHtml = `<iframe src="https://${window.location.hostname}/pesticide-maps/global-ban-embed" width="1170" height="1000"></iframe>`;
  return (
    <div className="map-widget-container">
      <div className={`iframe-holder ${mapState ? 'active' : ''}`}>
        <textarea className="content">{iframeHtml}</textarea>
        <div
          className="close"
          onClick={() => {
            setMapstate(false);
          }}
        >
          x
        </div>
      </div>
      <div className="map-widget">
        <span
          onClick={() => {
            setMapstate(!mapState);
          }}
        >
          Embed This Map
        </span>
      </div>
    </div>
  );
};
export default EmbedWidget;
