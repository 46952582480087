import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useCaliforniaRegionCounty = () => {
  const [data, setData]: any = useState([]);

  useEffect(() => {
    const getCaliforniaRegionCountyData = async () => {
      try {
        const results = await californiaSearchApi.requestCaliforniaRegionCountyList();
        // if (results.data) {
        //   const chemList: any = [];
        //   results.data.data.regionCounty.map((regionCounty: any) => {
        //     chemList.push({
        //       label: regionCounty.value,
        //       value: regionCounty.key,
        //     });
        //   });

        //   setData([...chemList]);
        // }

        if (results.data) {
          let groupCount = 0;
          const chemList: any = [];
          results.data.data.regionCounty.map(
            (regionCounty: any, index: number) => {
              if (regionCounty.key === '') {
                chemList.push({
                  label: regionCounty.value,
                  options: [],
                });
              }
            },
          );
          results.data.data.regionCounty.map(
            (regionCounty: any, index: number) => {
              if (regionCounty.key === '') {
                if (index > 1) {
                  groupCount += 1;
                }
              } else {
                chemList[groupCount].options.push({
                  label: regionCounty.value,
                  value: regionCounty.key,
                });
              }
            },
          );
          chemList.unshift({
            label: 'All Categories',
            options: [
              {
                label: 'All',
                value: 'all',
              },
            ],
          });
          setData([...chemList]);
        }
      } catch (e) {}
    };
    getCaliforniaRegionCountyData();
  }, []);

  return data && data;
};

export default useCaliforniaRegionCounty;
