/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import TooltipInTable from '../../../components/common/TooltipInTable';

const SearchResultTable = (props: any) => {
  const { type, list, requestDataComplete, dataNotFound, clickSort, tableSection = 'chemical' } = props;
  const hhpData = (hhp: any) => {
    let hhpValue;
    if (hhp == '1') {
      hhpValue = 'Yes';
    } else if (hhp == '0') {
      hhpValue = 'No';
    } else {
      hhpValue = 'Not Listed';
    }
    return hhpValue;
  };
  if (requestDataComplete) {
    return (
      <Container className="search-result-table">
        <Row>
          {list && list.length > 0 ? (
            <Col sm="12">
              {type === 'chemical' ? (
                <div className="result-table">
                  <div className="result-table-row titles">
                    <div>
                      <div
                        onClick={() => {
                          clickSort('Chem_Name');
                        }}
                      >
                        Chemical Name
                      </div>
                      <div
                        onClick={() => {
                          clickSort('EPA_Reg');
                        }}
                      >
                        EPA REGISTERED
                      </div>
                      <div
                        onClick={() => {
                          clickSort('panBadActor');
                        }}
                      >
                        PAN BAD ACTOR
                        <TooltipInTable tooltipKey="badactor"  section={tableSection} />
                      </div>
                      <div
                      // onClick={() => {
                      //   clickSort('name4');
                      // }}
                      >
                        PAN INT. HHP
                        <TooltipInTable tooltipKey="hhps"  section={tableSection} />
                      </div>
                      <div
                      // onClick={() => {
                      //   clickSort('name4');
                      // }}
                      >
                        EU REGISTERED
                      </div>
                    </div>
                  </div>
                  {list.map((item: any, key: number) => (
                    <div className="result-table-row" key={key}>
                      <Link to={`/chemical/${item.Rec_Id}`}>
                        <div>
                          <h3>{item.Chem_Name}</h3>
                        </div>
                        <div>{item.EPA_Reg}</div>
                        <div>{item.panBadActor}</div>
                        <div>{hhpData(item.hhp)}</div>
                        <div>{item.eu_reg ? item.eu_reg : 'Not Listed'}</div>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="result-table">
                  <div className="result-table-row titles">
                    <div>
                      <div
                        onClick={() => {
                          clickSort('name');
                        }}
                      >
                        REGISTERED NAME
                      </div>
                      <div
                        onClick={() => {
                          clickSort('us_reg_status');
                        }}
                      >
                        U.S. REG STATUS
                      </div>
                      <div
                        onClick={() => {
                          clickSort('ahw_label');
                        }}
                      >
                        ACUTE HAZARD WARNING LABEL
                      </div>
                      <div
                        onClick={() => {
                          clickSort('rup_status');
                        }}
                      >
                        U.S. RESTRICTED USE STATUS
                      </div>
                      <div
                        onClick={() => {
                          clickSort('bad_actor');
                        }}
                      >
                        PAN BAD ACTOR
                        <TooltipInTable tooltipKey="badactor" section={tableSection} />
                      </div>
                    </div>
                  </div>

                  {list.map((item: any, key: number) => (
                    <div className="result-table-row" key={key}>
                      <Link
                        to={`/product?regno=${item.reg_number}&distno=${item.dist_number}`}
                      >
                        <div>
                          <h3>{item.name}</h3>
                          {item.label ? <h4>({item.label})</h4> : ''}
                        </div>
                        <div>{item.us_reg_status}</div>
                        <div>{item.ahw_label}</div>
                        <div>{item.rup_status}</div>
                        <div>{item.bad_actor}</div>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Container>
    );
  }
  if (dataNotFound) {
    return (
      <Container className="search-result-table">
        <Row>
          <Col sm="12" className="no-result-found">
            <h3>No Search Results Found</h3>
            <p>
              {type === 'chemical'
                ? 'No results found in chemicals. Please search for products.'
                : 'No results found in products. Please search for chemicals.'}
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
  return <></>;
};
export default SearchResultTable;
