import { useEffect, useState } from 'react';
import overviewApi from '../../../api/overviewApi';

const useOverviewContent = () => {
  // const { query } = props;
  const [data, setData] = useState({
    content: '',
    //acf: '',
    acf: {
      enable_donation:'',
      enable_newsletter_subscription:'',
    },
  });

  useEffect(() => {
    const getOverviewData = async () => {
      try {
        const results = await overviewApi.requestOverviewContent();

        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    getOverviewData();
  }, []);

  return data && data;
};

export default useOverviewContent;
