import React from 'react';
import PageNotFoundContainer from '../components/NotfoundText';

const NotFoundContainer = (props: any) => {
  return (
    <>
      <PageNotFoundContainer />
    </>
  );
};
export default NotFoundContainer;
