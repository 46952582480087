import React from 'react';
import useUsageContent from './useUsageTab';
import TableCardRelatedList from '../components/TableCardRelatedList';
import UsageProductContainer from './UsageProductContainer';
import TableCaliforniaData from '../components/TableCaliforniaData';
import UsageMap from '../components/UsageMap';

const UsageTab = (props: any) => {
  const chemicalId = window.location.pathname.split('chemical/')[1];
  const values = useUsageContent(chemicalId);
  const { products, chemicals, californiaDetails, agriculturalMap } = values;
  const { chemName, badActor } = props;

  return (
    <>
      <UsageProductContainer
        tableHeading={` PRODUCTS CONTAINING ${chemName} `}
        tableData={products.products}
        resultCount={products.metaData.totalProducts}
        chemName={chemName}
        badActor={badActor}
      />
      <UsageMap
        latestYear={agriculturalMap.latestYear}
        chemicalName={agriculturalMap.chemicalName}
      />
      <TableCaliforniaData
        tableHeading={`Primary Use In California`}
        californiaDetails={californiaDetails.chemicalcropsSitesDetails}
        total={californiaDetails.metaData.totalcropsSites}
      />
      <TableCardRelatedList
        chemicals={chemicals.chemicals}
        total={chemicals.metaData.totalchemicals}
        chemicalId={chemicalId}
      />
    </>
  );
};
export default UsageTab;
