/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useReducer, ReactElement } from 'react';
import searchReducer from '../reducer';

const DEFAULT_SEARCH_CONTEXT = {
  searchValue: '',
  searchType: '',
  numberOfResults: 0,
  page: 1,
  sort: '',
  order: 'ASC',
};

export const SearchContext = createContext<any>(DEFAULT_SEARCH_CONTEXT);

export const SearchContextProvider = (props: {
  children: ReactElement;
}): ReactElement => {
  const { children } = props;
  const [searchState, dispatch] = useReducer(
    searchReducer,
    DEFAULT_SEARCH_CONTEXT,
  );

  const dispatchAction = (action: any) => {
    dispatch({ type: action.type, payload: action.payload });
  };
  const searchContext = {
    dispatch: dispatchAction,
    searchState,
  };
  return (
    <SearchContext.Provider value={searchContext}>
      {children}
    </SearchContext.Provider>
  );
};
