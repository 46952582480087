import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const ChemicalDetailBreadcrumb = (props: any) => {
  const { chemName } = props;
  return (
    <section>
      <Container>
        <Row>
          <Col lg="12">
            <Breadcrumb className="detail-page" tag="nav" listTag="div">
              <BreadcrumbItem tag="a" href="/california-pesticide-use">
                Search California Pesticides
              </BreadcrumbItem>
              <BreadcrumbItem tag="a" className="active">
                <span dangerouslySetInnerHTML={{ __html: chemName }} />
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default ChemicalDetailBreadcrumb;
