/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

const SeeMoreYears = (props: any) => {
  const {
    minYear,
    setMinYear,
    maxYear,
    setMaxYear,
    earliestYear,
    latestYear,
    onDateUpdate,
    setPanelVisible,
    panelVisible,
  } = props;
  const [toYears, setToYears]: any = useState([]);
  const [fromYears, setFromYears]: any = useState([]);
  // const toYear = useRef({});
  useEffect(() => {
    const initialYearsArr = [];
    let i = earliestYear;
    while (i < latestYear + 1) {
      initialYearsArr.push(i);
      i += 1;
    }
    const fromYearsArr = [...fromYears, ...initialYearsArr];
    initialYearsArr.shift();
    const toYearsArr = [...toYears, ...initialYearsArr];
    setToYears(toYearsArr);
    fromYearsArr.pop();
    setFromYears(fromYearsArr);
    // setFromYears(initialYearsArr);
  }, [latestYear]);

  const onFromYearChange = (e: any) => {
    const selectedValue = parseInt(e.target.value, 0);
    // const toYearsTemp = [];
    // let i = selectedValue;
    setMinYear(selectedValue);
    // while (i < latestYear + 1) {
    //   toYearsTemp.push(i);
    //   i += 1;
    // }
    // const toYearsArr = [...toYearsTemp];
    // toYearsArr.shift();
    // setToYears(toYearsArr);
    // const updatedMaxYear = selectedValue + 1;
    // setMaxYear(updatedMaxYear);
  };

  const onToYearChange = (e: any) => {
    setMaxYear(parseInt(e.target.value, 0));
  };

  const onSearch = () => {
    onDateUpdate();
    setPanelVisible(false);
  };

  return (
    <div className={`${panelVisible ? 'show' : ''}`}>
      <select onChange={onFromYearChange}>
        <option selected disabled>
          From
        </option>
        {fromYears.map((year: any, index: number) => (
          <option key={`${index}-fromYears`} value={year}>
            {year}
          </option>
        ))}
      </select>
      {/* <select onChange={onToYearChange} ref={toYear}> */}
      <select onChange={onToYearChange}>
        <option selected disabled>
          To
        </option>
        {toYears.map((year: any, index: number) => (
          <option key={`${index}-toYears`} value={year}>
            {year}
          </option>
        ))}
      </select>
      {minYear >= maxYear ? (
        <div className="error">From year should be less than To year</div>
      ) : (
        ''
      )}
      <button
        onClick={onSearch}
        type="button"
        className={minYear >= maxYear ? 'disable' : ''}
      >
        Save
      </button>
    </div>
  );
};
export default SeeMoreYears;
