import React, { useState } from 'react';

import SourceMethologyBreadcrumb from '../components/SourceMethologyBreadcrumb';
import SourceMethologyBanner from '../components/SourceMethologyBanner';
import SourceMethologyTextBlok from '../components/SourceMethologyTextBlock';
import SourceMethologyAccordian from '../components/SourceMethologyAccordian';
import SourceMethologyDonate from '../components/SourceMethologyDonate';
import useSourceMethologyMainContent from './useSourceMethologyMainContent';
import Donation from '../../../components/common/Donation';
import NewsLetterForm from '../../../components/common/NewsLetterForm';

const SourceMethologyMainContent = (props: any) => {
  const sourceMethologyProps = useSourceMethologyMainContent();
  const { content, acf } = sourceMethologyProps;
  let enableDonation = acf.enable_donation;
  let enable_newsletter_subscription = acf.enable_newsletter_subscription;
  const [activeId, setActiveId] = useState(-1);
  const updateActiveId = (id: number): void => {
    setActiveId(id);
  };
  return (
    <div>
      <SourceMethologyBreadcrumb />
      <SourceMethologyBanner title={acf.page_title} />
      <SourceMethologyTextBlok content={content} />
      <SourceMethologyAccordian
        faqs={acf.question_answers}
        activeId={activeId}
        updateActiveId={updateActiveId}
      />
      {enableDonation==="Yes" ? <Donation /> :''}
      {enable_newsletter_subscription === "Yes" ? <NewsLetterForm /> : ''}
    </div>
  );
};
export default SourceMethologyMainContent;
