/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useTopCropSitesTabContent = (cok: string) => {
  const [data, setData]: any = useState({
    data: {
      list: [],
    },
  });

  useEffect(() => {
    const getCountyTopCropSitesData = async () => {
      try {
        const results = await californiaSearchApi.requestCountyTop50CropSitesContent(
          cok,
        );
        setData(results.data);
      } catch (e) {}
    };
    getCountyTopCropSitesData();
  }, []);

  return data && data;
};

export default useTopCropSitesTabContent;
