/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import NumberFormat from 'react-number-format';
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const TableViewMoreModal = (props: any) => {
  const {
    isModalOpen,
    items,
    title,
    closeClick,
    tableColumnTitles,
    tableItemLink,
    specialClass,
  } = props;

  return (
    <>
      <Modal isOpen={isModalOpen} className="viewmore-modal">
        <div
          onClick={() => {
            closeClick();
          }}
          className="close"
        />
        <ModalBody>
          <div className="title-container">
            <h2 style={{ padding: ' 0 0 0 10px' }}>{title}</h2>
          </div>

          <div
            className={`search-result-table view-more-popup ${specialClass}`}
          >
            <div className="result-table">
              <div className="result-table-row titles">
                <div>
                  {tableColumnTitles.map((title: any, key: number) => (
                    <div
                      key={key}
                      className={`popup-col-${tableColumnTitles.length}`}
                    >
                      {title.label!='Application Rate' ? title.label : 'Application Rate (lbs a.i./acre)'}
                    </div>
                  ))}
                </div>
              </div>
              {items
                ? items.map((item: any, index: number) => (
                    <div className="result-table-row" key={index}>
                      <Link
                        to={`${tableItemLink.linkedTo}${
                          item[tableItemLink.field]
                        }`}
                      >
                        <>
                          {tableColumnTitles.map((col: any, key: number) => (
                            <div
                              key={key}
                              className={`popup-col-${tableColumnTitles.length}`}
                            >
                              <span>
                              {
                              item[col.field] > 0
                                  ? 
                                  col.field === 'applicationRate' ||  col.field === 'Appl_Rate' ?
                                    parseFloat(item[col.field]).toFixed(2) :
                                    col.field === 'applicationCount' ||  col.field === 'Gross_Lbs' ?
                                      parseFloat(item[col.field]).toFixed(0) :
                                      item[col.field] < 1000 ? parseFloat(item[col.field]).toFixed(1) : 

                                  <NumberFormat value={Math.round(item[col.field])} displayType={'text'} thousandSeparator={true} />
                                  : item[col.field]}{' '}
                              </span>                              
                            </div>
                          ))}
                        </>
                      </Link>
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default TableViewMoreModal;
