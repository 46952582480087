import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const TitleWrapper = (props: any) => {
  const { title } = props;
  let split_length = title.split(',').length;
  const [subTitles, setSubTitles] = useState([]);
  useEffect(() => {
    setSubTitles(title.split(','));
  }, []);
  return (
    <section className="search-result-title">
      <Container>
        <Row>
          <Col md="6 title-holder">
            <h2>Your California Search:</h2>
            <ul>
              <li>{split_length === 4 ? subTitles[0]+","+subTitles[1] : subTitles[0]}</li>
              <li>{split_length === 4 ? subTitles[2] : subTitles[1]}</li>
              <li>{split_length === 4 ? subTitles[3] : subTitles[2]}</li>
            </ul>
            <hr/>
          </Col>
          <Col md="6 search-button-holder">
            <Link to="/california-pesticide-use">
              <span>Start New Search</span>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default TitleWrapper;
