import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const SearchResultBreadcrumb = (props: any) => {
  return (
    <section>
      <Container>
        <Row>
          <Col xs="12">
            <Breadcrumb tag="nav" listTag="div">
              <BreadcrumbItem
                className="active"
                tag="a"
                href="/california-pesticide-use"
              >
                Search California Pesticides
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default SearchResultBreadcrumb;
