/* eslint-disable react/no-danger */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import tempFooterFbImage from '../../assets/images/temp/fb.svg';
import tempFooterTwImage from '../../assets/images/temp/tw.svg';
import tempFooterPtImage from '../../assets/images/temp/pinterest.svg';
import tempFooterYtImage from '../../assets/images/temp/youtube.svg';

const Footer = (props: any) => {
  const {
    footerAddressOne,
    footerAddressTwo,
    footerBottomCaption,
    footerMainLogo,
    footerSocialMediaCaption,
    footerSocialMediaFacebook,
    footerSocialMediaPinterest,
    footerSocialMediaTwitter,
    footerSocialMediaYoutube,
  } = props;
  if (footerAddressOne) {
    return (
      <footer className="footer">
        <Container className="footer-content">
          <Row>
            <Col lg="2" md="3" className="footer-logo">
              <img src={footerMainLogo} alt="" />
            </Col>
            <Col xl="8" lg="7" md="5" className="footer-adresses">
              <div
                className="footer-adress"
                dangerouslySetInnerHTML={{
                  __html: footerAddressOne,
                }}
              />
              <div
                className="footer-adress"
                dangerouslySetInnerHTML={{
                  __html: footerAddressTwo,
                }}
              />
            </Col>
            <Col xl="2" lg="3" md="4" className="footer-socail-media">
              <p>{footerSocialMediaCaption}</p>
              <ul>
                {footerSocialMediaFacebook ? (
                  <li>
                    <a href={footerSocialMediaFacebook} target="_blank">
                      <img src={tempFooterFbImage} alt="" />
                    </a>
                  </li>
                ) : (
                  ''
                )}

                {footerSocialMediaTwitter ? (
                  <li>
                    <a href={footerSocialMediaTwitter} target="_blank">
                      <img src={tempFooterTwImage} alt="" />
                    </a>
                  </li>
                ) : (
                  ''
                )}

                {footerSocialMediaPinterest ? (
                  <li>
                    <a href={footerSocialMediaPinterest} target="_blank">
                      <img src={tempFooterPtImage} alt="" />
                    </a>
                  </li>
                ) : (
                  ''
                )}

                {footerSocialMediaYoutube ? (
                  <li>
                    <a href={footerSocialMediaYoutube} target="_blank">
                      <img src={tempFooterYtImage} alt="" />
                    </a>
                  </li>
                ) : (
                  ''
                )}
              </ul>
            </Col>
          </Row>
        </Container>
        <Container className="footer-copy-right" fluid>
          <Row>
            <Col sm="12">
              <p>{footerBottomCaption}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
  return <></>;
};
export default Footer;
