export const wpApiPrefix = 'cms/index.php/wp-json';
export const searchApiPrefix = 'pan/v1';

// ********************
// Page url's
// ********************

export const home = '/';

export const ASC = 'ASC';
export const DESC = 'DESC';
