import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ChemicalOthernames = (props: any) => {
  const { modal, itemName, badActor, closeClick, list, itemKey, count, popUpTitle } = props;
  const { metaData, items } = list;
  return (
    <>
      <Modal isOpen={modal} className="viewmore-modal">
        <div
          onClick={() => {
            closeClick();
          }}
          className="close"
        ></div>
        <ModalBody>
          <div className="title-container">
            <h2>{itemName}</h2>
            <div className="logo-wrapper">
              {badActor === 'Yes' ? (
                <div className="tooltip-holder">
                  <span id="badActor"></span>
                </div>
              ) : (
                ''
              )}
              {/* <div className="tooltip-holder ">
                <span id="highHazardous"></span>
              </div> */}
            </div>
          </div>
          <div className="otherlist">
            <div className="title">
              {/* PRODUCTS CONTAINING {itemName} */}
              {popUpTitle}
              <span>
                ({metaData[count]}{' '}
                {metaData[count] > 1 ? ' Results' : ' Result'})
              </span>
            </div>
            <div className="chemical-list-wrapper">
              {items.map((item: any, key: number) => (
                <>
                  <div className="item">{item[itemKey]}</div>
                </>
              ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ChemicalOthernames;
