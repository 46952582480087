import { useEffect, useState } from 'react';
import globalCprMapApi from '../../../api/globalCprMapApi';

const useCprMapContent = () => {
  // const { query } = props;
  const [data, setData] = useState({
    content: {
      rendered: '',
    },
    acf: {
      logoItemHeading: '',
      globalBanLogoItems: [],
    },
  });

  useEffect(() => {
    const globalCprMapApiData = async () => {
      try {
        const results = await globalCprMapApi.globalCprMapContent();

        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    globalCprMapApiData();
  }, []);

  return data && data;
};

export default useCprMapContent;
