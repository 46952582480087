import { useEffect, useState } from 'react';
import homeApi from '../../../api/homeApi';

const useDonateWithWebList = () => {
  // const { query } = props;
  const [data, setData] = useState({
    donateText: '',
    donateButtonLabel: '',
    donateButtonLink: '',
    relatedWebsites: [],
  });

  useEffect(() => {
    const getHomeData = async () => {
      try {
        const results = await homeApi.requestRelatedWebsite();
        setData(results.data);
      } catch (e) {}
    };
    getHomeData();
  }, []);
  return data && data;
};

export default useDonateWithWebList;
