/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';

const ChemicalSelections = (props: any) => {
  const {
    selectedTargetPest,
    setSelectedTargetPest,
    selectedChemicalType,
    setSelectedChemicalType,
    chemicalToxicity,
    setChemicalToxicity,
    chemicalRegulatoryList,
    setChemicalRegulatoryList,
    noOfChemicalToxicitySelected,
    setNoOfChemicalToxicitySelected,
    noOfRegulatoryListSelected,
    setNoOfRegulatoryListSelected,
    errorMessage,
    setErrorMessage,
    searchButtonClick,
    selectedValues,
    setSelectedValues,
    isSearchClicked,
  } = props;

  // const [toxicity, settoxicity] = useState();

  const removeToxicity = (parentIndex: number, childIndex: number) => {
    const toxicity = [...chemicalToxicity];
    const isSelected = toxicity[parentIndex].subItems[childIndex].selected;
    if (isSelected) {
      toxicity[parentIndex].noOfSelected -= 1;
      setNoOfChemicalToxicitySelected(noOfChemicalToxicitySelected - 1);
      const error = { ...errorMessage, showMaxLimitMessage: false };
      setErrorMessage(error);
    }
    toxicity[parentIndex].subItems[childIndex].selected = !isSelected;
    setChemicalToxicity(toxicity);
    const newSelectedValues = selectedValues.filter((obj: any) => {
      return (
        obj.subItemsItemValue !==
        toxicity[parentIndex].subItems[childIndex].itemValue
      );
    });
    setSelectedValues(newSelectedValues);
  };

  const removeChemicalRegulatoryListItem = (
    parentIndex: number,
    childIndex: number,
  ) => {
    const regulatoryList = [...chemicalRegulatoryList];
    const isSelected =
      regulatoryList[parentIndex].subItems[childIndex].selected;
    if (isSelected) {
      regulatoryList[parentIndex].noOfSelected -= 1;
      setNoOfRegulatoryListSelected(noOfRegulatoryListSelected - 1);
      const error = { ...errorMessage, showMaxLimitMessage: false };
      setErrorMessage(error);
    }
    regulatoryList[parentIndex].subItems[childIndex].selected = !isSelected;
    setChemicalRegulatoryList(regulatoryList);
    const newSelectedValues = selectedValues.filter((obj: any) => {
      return (
        obj.subItemsItemValue !==
        regulatoryList[parentIndex].subItems[childIndex].itemValue
      );
    });
    setSelectedValues(newSelectedValues);
  };

  const applySearch = () => {
    if (
      isSearchClicked === true &&
      (selectedTargetPest.pestLabel !== '' ||
        selectedChemicalType.chemicalTypeLabel !== '')
    ) {
      searchButtonClick();
    }
  };

  useEffect(() => {
    applySearch();
  }, [
    selectedTargetPest,
    selectedChemicalType,
    chemicalToxicity,
    chemicalRegulatoryList,
  ]);

  const getComponent = () => {
    return selectedValues.map((item: any, key: number) => {
      switch (item.name) {
        case 'type':
          return (
            <div
              className="selected-item"
              onClick={(): void => {
                setSelectedChemicalType({
                  chemicalTypeParent: '',
                  chemicalType: '',
                  chemicalTypeLabel: '',
                });
                const newVal = selectedValues.filter((obj: any) => {
                  return obj.name !== 'type';
                });
                setSelectedValues(newVal);
              }}
            >
              {item.chemicalTypeLabel}
            </div>
          );
        case 'pest':
          return (
            <div
              key={Math.random()}
              className="selected-item"
              onClick={(): void => {
                setSelectedTargetPest({
                  pestParent: '',
                  pest: '',
                  pestLabel: '',
                });
                const newVal = selectedValues.filter((obj: any) => {
                  return obj.name !== 'pest';
                });
                setSelectedValues(newVal);
              }}
            >
              {item.pestLabel}
            </div>
          );
        case 'toxicity':
          return (
            <span>
              <span key={`toxicity_${item.parentIndex}`}>
                <span
                  key={`toxicity_child_${item.childIndex}_${item.parentIndex}`}
                >
                  <div
                    className="selected-item"
                    onClick={(e): void => {
                      removeToxicity(item.parentIndex, item.childIndex);
                    }}
                  >
                    {item.subItemsItemLabel}
                  </div>
                </span>
              </span>
            </span>
          );
        case 'regulatory':
          return (
            <span>
              <span key={`regulatory_${item.parentIndex}`}>
                <span
                  key={`regulatory_child_${item.childIndex}_${item.parentIndex}`}
                >
                  <div
                    className="selected-item"
                    onClick={(e): void => {
                      removeChemicalRegulatoryListItem(
                        item.parentIndex,
                        item.childIndex,
                      );
                    }}
                  >
                    {item.subItemsItemLabel}
                  </div>
                </span>
              </span>
            </span>
          );
        default:
          return '';
      }
    });
  };

  return (
    <div className="chemical-selections">
      <div className="selection-title-wrapper">
        <h6>YOUR SELECTIONS</h6>
      </div>
      <div className="selected-items">{getComponent()} </div>
      <div className="button-holder">
        <a
          href="/"
          onClick={(e): void => {
            e.preventDefault();
            searchButtonClick();
          }}
          className={
            selectedTargetPest.pestLabel !== '' ||
            selectedChemicalType.chemicalTypeLabel !== ''
              ? 'enable'
              : 'disable'
          }
        >
          Search <span />
        </a>
      </div>
    </div>
  );
};
export default ChemicalSelections;
