/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const globalCprMapContent = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=ca-pesticide-map`);
};

const globalCprMapApi = {
  globalCprMapContent,
};

export default globalCprMapApi;
