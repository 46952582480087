
import { useEffect, useState } from 'react';
import usgsmapApi from '../../../api/usgsmapApi';

const useUsgsmapDetails= () => {
  // const { query } = props;
  const [data, setData] = useState({
    
    content: '',
    acf: '',
  });

  useEffect(() => {
    const getOverviewData = async () => {
      try {
        const results = await usgsmapApi.requestUsgsMapContent();
        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    getOverviewData();
  }, []);
  return data && data;
};

export default useUsgsmapDetails;
