import React from 'react';
import {
  Container,
  Row,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from 'reactstrap';
import UseProductToxicity from './UseProductToxicity';
import KeyValueDataTable from '../../../../components/common/KeyValueDataTable';
import MultipleKeyValueDataTable from '../../../../components/common/MultipleKeyValueDataTable';
import ProductToxicityOtherIngredients from '../../components/tabs/ProductToxicityOtherIngredients';

const ProductToxicity = (props: any) => {
  // const productRegno = window.location.pathname.split('product/')[1];
  const { productName } = props;
  const url = new URL(window.location.href);
  const productRegno = url.searchParams.get('regno')!;
  const values = UseProductToxicity(productRegno);
  const { summary, otherIngredients } = values;
  return (
    <>
      <TabPane className="toxicity" tabId="2">
        <MultipleKeyValueDataTable
          tableHeading={`TOXICITY of ${productName}`}
          tableData={summary}
        />
        <ProductToxicityOtherIngredients data={otherIngredients} />
      </TabPane>
    </>
  );
};
export default ProductToxicity;
