import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PostBlock from '../../../components/common/PostBlock';

const ResourcePosts = (props: any): ReactElement => {
  const { resourceList, requestDataComplete } = props;

  // placeholders until data loaded
  const loadingItems = [];
  for (let i = 0; i < 9; i += 1) {
    loadingItems.push(
      <Col md="4" key={i}>
        <div className="loading-item">
          <div className="loading-item__image">
            <p>&nbsp;</p>
          </div>
          <div className="loading-item__content">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </Col>,
    );
  }

  return (
    <section>
      <Container className="post-blocks-container">
        {requestDataComplete === true ? (
          <Row>
            {resourceList.length > 0 ? (
              <>
                {resourceList.map((item: any, key: number) => (
                  <Col md="4" key={key}>
                    <PostBlock type="resource-small">
                      <a href={`/resources/${item.name}`}>
                        <div className="post-block__featured-image">
                          <img src={item.featuredImage} alt="" />
                        </div>
                        <div className="post-block__description">
                          <h3
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                          <h4>
                            {item.resourceCategories.map(
                              (categroy: any, key: number) => (
                                <span
                                  key={key}
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: categroy.name,
                                  }}
                                />
                              ),
                            )}
                          </h4>
                          <p>{item.excerpt}</p>
                          <span>
                            {' '}
                            {item.readMoreLinkText
                              ? item.readMoreLinkText
                              : 'Read More'}{' '}
                          </span>
                        </div>
                      </a>
                    </PostBlock>
                  </Col>
                ))}
              </>
            ) : (
              <Col md="12" className="text-center  no-articles-found">
                <h3>No Resources Found</h3>
              </Col>
            )}
          </Row>
        ) : (
          <Row>{loadingItems}</Row>
          // <></>
        )}
      </Container>
    </section>
  );
};
export default ResourcePosts;
