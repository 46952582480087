/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useReducer, ReactElement } from 'react';
import blogReducer from '../reducer';

const DEFAULT_BLOG_CONTEXT = {
  searchText: '',
  blogs: [],
  currentPage: 1,
  numPages: 1,
};

export const BlogContext = createContext<any>(DEFAULT_BLOG_CONTEXT);

export const BlogContextProvider = (props: {
  children: ReactElement;
}): ReactElement => {
  const { children } = props;
  const [blogState, dispatch] = useReducer(blogReducer, DEFAULT_BLOG_CONTEXT);

  const dispatchAction = (action: any) => {
    dispatch({ type: action.type, payload: action.payload });
  };
  const blogContext = {
    dispatch: dispatchAction,
    blogState,
  };
  return (
    <BlogContext.Provider value={blogContext}>{children}</BlogContext.Provider>
  );
};
