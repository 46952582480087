import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import useChemicalDetailOverview from './useChemicalDetailOverview';
import OverviewTab from '../components/OverviewTab';
import TopCropsSites from '../components/TopCropsSites';
import SideBarContainer from '../../../containers/SideBarContainer';
import useChemicalDetailTopCrops from './useChemicalDetailTopCrops';
import useChemicalDetailTopCounties from './useChemicalDetailTopCounties';
import TopCounties from '../components/TopCounties';
import ChemicalDetailBreadcrumb from '../components/ChemicalDetailBreadcrumb';

const ChemicalDetailContainer = (props: any) => {
  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();

  const id: string | null = query.get('id') ? query.get('id') : '';

  const overViewTabData = useChemicalDetailOverview(id!);
  const topCropsTabData = useChemicalDetailTopCrops(id!);
  const topCountiesTabData = useChemicalDetailTopCounties(id!);
  //https://stackoverflow.com/questions/54496398/typescript-type-string-undefined-is-not-assignable-to-type-string

  const [overViewData, setOverViewData]: any = useState({
    chemicalName: '',
    data: {
      chemicals: {
        chemicals: [],
        metaData: {},
      },
      summary: [],
      toxicity: [],
    },
    latestYear: '',
  });
  const [topCropsData, setTopCropsData]: any = useState({
    data: {
      metaData: {
        total: '',
      },
      list: [],
    },
  });
  const [topCountiesData, setTopCountiesData]: any = useState({
    data: {
      metaData: {
        total: 0,
      },
      list: [],
    },
  });

  const [activeTab, setActiveTab] = useState('1');

  const tabtoggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setOverViewData(overViewTabData);
  }, [overViewTabData]);

  useEffect(() => {
    setTopCropsData(topCropsTabData);
  }, [topCropsTabData]);

  useEffect(() => {
    setTopCountiesData(topCountiesTabData);
  }, [topCountiesTabData]);

  return (
    <>
      <ChemicalDetailBreadcrumb
        chemName={overViewData ? overViewData.chemicalName : ''}
      />
      <section className="tab-main-content california-tab-content">
        <Container>
          <Row>
            <Col lg="9">
              <div className="inner-upper-container">
                <div className="title-container">
                  <h2>{overViewData ? overViewData.chemicalName : ''}</h2>
                  <span>
                    {`(All California, ${
                      overViewData.latestYear ? overViewData.latestYear : ''
                    })`}
                  </span>
                </div>
                <div className="button-container">
                  <a
                    className="red-btn"
                    href="https://www.migrantclinician.org/issues/occupational-health/pesticides/reporting-illnesses"
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    <span>Report a Poisoning</span>
                  </a>
                </div>
              </div>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('1');
                      }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('2');
                      }}
                    >
                      TOP 50 CROPS & SITES
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('3');
                      }}
                    >
                      Top Counties
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane className="overview" tabId="1">
                    <OverviewTab
                      data={overViewData.data}
                      chemName={overViewData.chemicalName}
                      chemId={id!}
                    />
                  </TabPane>
                  <TabPane className="top-pesticides" tabId="2">
                    <TopCropsSites
                      crops={topCropsData.list}
                      total={topCropsData.metaData}
                      chemName={overViewData.chemicalName}
                    />
                  </TabPane>
                  <TabPane className="top-crops-sites" tabId="3">
                    <TopCounties
                      county={topCountiesData.list}
                      total={topCountiesData.metaData}
                      chemName={overViewData.chemicalName}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col lg="3">
              <SideBarContainer />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ChemicalDetailContainer;
