import React from 'react';
import Helmet from 'react-helmet';
import PrivacyPolicyDetails from '../containers/PrivacyPolicyDetails';

const PrivacyPolicy = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Privacy Policy</title>
        <meta name="description" content="Privacy Policy" />
      </Helmet>
      <PrivacyPolicyDetails />
    </>
  );
};
export default PrivacyPolicy;
