/* eslint-disable react/no-array-index-key */
import React, { ReactElement, useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import { AnySrvRecord } from 'dns';
// import CustomAccordion from '../../../components/common/CustomAccordion';

/* interface TooltipSectionsBlock {
  data: any;
} */

/* const createMarkup = (text: any) => {
  let originalText = text;
  originalText = originalText.replace(
    '<table',
    '<div class="table-responsive"><table',
  );
  originalText = originalText.replace('</table>', '<table></div>');
  return { __html: originalText };
}; */

const TooltipSections = (props: any): ReactElement => {
  const { data } = props;
  const [refs, setRefs]: any = useState([]);

  useEffect(() => {
    const references = data.reduce((acc: any, value: any) => {
      acc[`${value.Name}${value.id}`] = React.createRef();
      return acc;
    }, {});
    setRefs(references);
  }, [data]);

  useEffect(() => {
    const { hash } = window.location;
    const trimmedHash = hash.replace('#', '').toLowerCase();
    if (refs[trimmedHash]) {
      const { offsetTop } = refs[trimmedHash].current;
      window.scrollTo({
        top: offsetTop + 50,
        behavior: 'smooth',
      });
    }
  }, [refs]);

  return (
    <>
      <section className="faq-content">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10 text-center">
              {/* <h2 dangerouslySetInnerHTML={{ __html: title }} /> */}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="10">
              <div className="text-block">
                {data.map((item: any, key: number) => (
                  <div
                    className={`result-table-row ${item.Name}`}
                    key={key}
                    ref={refs[`${item.Name}${item.id}`]}
                  >
                    <h2>{item.SectionName}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.Description,
                      }}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default TooltipSections;
