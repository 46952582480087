import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Title = (props: any) => {
  const { titleText } = props;
  return (
    <section className="title-button-container">
      <Container>
        <Row>
          <Col lg="12" className="title">
            <h2>{titleText}</h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Title;
