import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestAdvanceSearchChemProperties = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/chemProperties`);
};

const requestAdvanceSearchPestSuggest = (searchTerm: any): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/pestSuggest`, {
    search: searchTerm,
  });
};

const requestAdvanceSearchCommotditySuggest = (
  searchTerm: any,
): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/commoditySuggest`, {
    search: searchTerm,
  });
};

const requestAdvanceSearchProductNameSuggest = (
  searchTerm: any,
  searchType: any = 'product'
): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/searchSuggest`, {
    search: searchTerm,
    type: searchType,
    limit:100,
  });
};

const requestAdvanceSearchCompanyNameSuggest = (
  searchTerm: any,
): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/companyNameSuggest`, {
    search: searchTerm,
  });
};

const requestAdvanceSearchRegNoSuggest = (searchTerm: any): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/regNoSuggest`, {
    search: searchTerm,
  });
};

const requestAdvanceSearchPestUses = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/pestuses`);
};

const requestAdvanceSearchProductRegYear = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/regYears`);
};

const requestAdvancedProductSearchResults = (
  searchData: any,
  pestName: string | null,
  comodity: string | null,
  productName: string | null,
  companyName: string | null,
  regNumber: string | null,
): Promise<any> => {
  const {
    pageNumber,
    prodStatus,
    // pestName,
    // siteName,
    prodUses,
    prodFormulation,
    // prodName,
    // companyName,
    // prodRegNo,
    chemToxCategory,
    ahwLabel,
    regulatoryList,
    chemicalType,
    prodRegYear,
    sortField,
    orderBy,
  } = searchData;
  return axiosApi.post(
    `${searchApiPrefix}/advancedProductSearch?page=${pageNumber}&sort=${sortField}&order=${orderBy}`,
    {
      prodStatus,
      pestName,
      siteName: comodity,
      prodUses,
      prodFormulation,
      prodName: productName,
      companyName,
      prodRegNo: regNumber,
      chemToxCategory,
      ahwLabel,
      regulatoryList,
      chemicalType,
      prodRegYear,
    },
  );
};

const downloadAdvancedProductSearchResults = (
  fileType: string,
  searchData: any,
  pestName: string | null,
  comodity: string | null,
  productName: string | null,
  companyName: string | null,
  regNumber: string | null,
): Promise<any> => {
  let requestStatement;
  let config: any = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const {
    pageNumber,
    prodStatus,
    // pestName,
    // siteName,
    prodUses,
    prodFormulation,
    // prodName,
    // companyName,
    // prodRegNo,
    chemToxCategory,
    ahwLabel,
    regulatoryList,
    chemicalType,
    prodRegYear,
    sortField,
    orderBy,
  } = searchData;

  if (fileType == 'pdf') {
    requestStatement = `${searchApiPrefix}/advProductpdf?page=${pageNumber}&sort=${sortField}&order=${orderBy}`;
    config = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    };
  } else {
    requestStatement = `${searchApiPrefix}/advProductcsv?page=${pageNumber}&sort=${sortField}&order=${orderBy}`;
  }

  return axiosApi.post(
    requestStatement,
    {
      prodStatus,
      pestName,
      siteName: comodity,
      prodUses,
      prodFormulation,
      prodName: productName,
      companyName,
      prodRegNo: regNumber,
      chemToxCategory,
      ahwLabel,
      regulatoryList,
      chemicalType,
      prodRegYear,
    },
    config,
  );
};

const advanceSearchProductApi = {
  requestAdvanceSearchChemProperties,
  requestAdvanceSearchPestSuggest,
  requestAdvanceSearchCommotditySuggest,
  requestAdvanceSearchPestUses,
  requestAdvanceSearchProductRegYear,
  requestAdvancedProductSearchResults,
  requestAdvanceSearchProductNameSuggest,
  requestAdvanceSearchCompanyNameSuggest,
  requestAdvanceSearchRegNoSuggest,
  downloadAdvancedProductSearchResults,
};

export default advanceSearchProductApi;
