import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import SideBarContainer from '../../../containers/SideBarContainer';
import CountyDetailBreadcrumb from '../components/CountyDetailBreadcrumb';
import OverviewTab from './OverviewTab';
import TopPesticides from './TopPesticides';
import TopCropsSites from './TopCropsSites';
import useTopPesticides from './useTopPesticides';
import useTopCropSites from './useTopCropSites';
import useOverview from './useOverviewTb';

const CountyDetailContainer = (props: any) => {
  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();

  const cok: string | null = query.get('cok') ? query.get('cok') : '';

  const overViewTabData = useOverview(cok!);
  const topPesticidesTabData = useTopPesticides(cok!);
  const topCropsTabData = useTopCropSites(cok!);

  const [overViewData, setOverViewData]: any = useState({
    data: {
      list: {
        countyName: '',
        countyDetails: {},
      },
    },
  });
  const [topPesticidesData, setTopPesticidesData]: any = useState({
    data: {
      pesticideCount: 0,
      list: [],
    },
  });

  const [topCropsData, setTopCropsData]: any = useState({
    data: {
      total: 0,
      list: [],
    },
  });

  const [activeTab, setActiveTab] = useState('1');

  const tabtoggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setOverViewData(overViewTabData);
  }, [overViewTabData]);

  useEffect(() => {
    setTopCropsData(topCropsTabData);
  }, [topCropsTabData]);

  useEffect(() => {
    setTopPesticidesData(topPesticidesTabData);
  }, [topPesticidesTabData]);

  return (
    <>
      <CountyDetailBreadcrumb
        countyName={
          overViewData.data.list ? overViewData.data.list.countyName : ''
        }
      />
      <section className="tab-main-content california-tab-content">
        <Container>
          <Row>
            <Col lg="9">
              <div className="inner-upper-container">
                <div className="title-container">
                  <h2>
                    {overViewData.data.list
                      ? overViewData.data.list.countyName
                      : ''}
                  </h2>
                  <span>
                    {overViewData.data.list.regionName
                      ? `(${overViewData.data.list.regionName})`
                      : ''}
                  </span>
                </div>
              </div>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('1');
                      }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('2');
                      }}
                    >
                      Top 50 Pesticides
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('3');
                      }}
                    >
                      Top 50 Crops & Sites
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane className="overview" tabId="1">
                    <OverviewTab
                      list={
                        overViewData.data.list ? overViewData.data.list : ''
                      }
                      details={
                        overViewData.data.list
                          ? overViewData.data.list.countyDetails
                          : ''
                      }
                    />
                  </TabPane>
                  <TabPane className="top-pesticides" tabId="2">
                    <TopPesticides
                      pesticides={
                        topPesticidesData.data.list
                          ? topPesticidesData.data.list
                          : []
                      }
                      countyName={
                        overViewData.data.list
                          ? overViewData.data.list.countyName
                          : ''
                      }
                      total={
                        topPesticidesData.data
                          ? topPesticidesData.data.pesticideCount
                          : 0
                      }
                    />
                  </TabPane>
                  <TabPane className="top-crops-sites" tabId="3">
                    <TopCropsSites
                      crops={
                        topCropsData.data.list ? topCropsData.data.list : []
                      }
                      countyName={
                        overViewData.data.list
                          ? overViewData.data.list.countyName
                          : ''
                      }
                      total={topCropsData.data ? topCropsData.data.total : 0}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col lg="3">
              <SideBarContainer />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default CountyDetailContainer;
