import React from 'react';
import Helmet from 'react-helmet';
import ChemicalInnerDetails from '../containers/ChemicalInnerDetails';

const ChemicalDetail = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Chemical Details</title>
        <meta name="description" content="Chemical Details" />
      </Helmet>
      <ChemicalInnerDetails />
    </>
  );
};
export default ChemicalDetail;
