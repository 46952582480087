import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TableViewMore from '../../../components/common/TableViewMore';
import SearchResultsDownload from '../../../components/common/SearchResultsDownload';

const TopPesticides = (props: any) => {
  const { pesticides, total, cropName } = props;
  const [pesticidesForTable, setPesticidesForTable] = useState([]);

  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();
  const sk: string | null = query.get('sk') ? query.get('sk') : '';

  const tableTitles = [
    {
      label: 'Chemical Name',
      field: 'ChemicalName',
    },
    {
      label: 'Uses',
      field: 'uses',
    },
    {
      label: 'Chemical Class',
      field: 'chemicalClass',
    },
    {
      label: 'Gross Pounds',
      field: 'grossPounds',
    },
    {
      label: 'App Rate (lbs a.i./acre)',
      field: 'applicationRate',
    },
    {
      label: 'Acres Planted',
      field: 'acresPlanted',
    },
    {
      label: 'Acres Treated',
      field: 'acresTreated',
    },
    {
      label: 'PAN Bad Actor',
      field: 'panBadActor',
    },
  ];
  const tableItemLink = {
    linkedTo: '/california-pesticide-use/california-chemical-detail?id=',
    field: 'Rec_Id',
  };

  useEffect(() => {
    if (pesticides) {
      setPesticidesForTable(pesticides.slice(0, 25));
    }
  }, [pesticides]);

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }   
  }

  return (
    <>
      <div className="search-result-table top-fifty-pesticides">
        <div className="result-table">
          <div className="result-table-row titles">
            <div>
              <div>Chemical Name</div>
              <div>Uses</div>
              <div>
                Chemical <br /> Class
              </div>
              <div>
                Gross <br /> Pounds
              </div>
              <div>
                App. Rate<br></br>(lbs a.i./acre)
              </div>
              <div>
                Acres <br /> Planted
              </div>
              <div>
                Acres <br /> Treated
              </div>
              <div>
                PAN Bad <br /> Actor
              </div>
            </div>
          </div>
          {pesticidesForTable.length > 0 ? (
            pesticidesForTable.map((item: any, index: number) => (
              <div className="result-table-row" key={index}>
                {/* <pre>{JSON.stringify(item)}</pre> */}
                <Link
                  to={`/california-pesticide-use/california-chemical-detail?id=${item.Rec_Id}`}
                >
                  <div>
                    <h3>{item.ChemicalName}</h3>
                  </div>
                  <div>
                    <span>{item.uses}</span>
                  </div>
                  <div>
                    <span>{item.chemicalClass}</span>
                  </div>
                  <div>{decimalFormat(item.grossPounds.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.applicationRate.replace(/,/g, ""),2)}</div>
                  <div>{decimalFormat(item.acresPlanted.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.acresTreated.replace(/,/g, ""),1)}</div>
                  <div>{item.panBadActor}</div>
                </Link>
              </div>
            ))
          ) : (
            <p className="no-results">No Results Found!</p>
          )}
        </div>
      </div>
      <TableViewMore
        items={pesticides}
        total={total}
        title={cropName}
        tableColumnTitles={tableTitles}
        tableItemLink={tableItemLink}
      />
      {pesticidesForTable.length > 0 ? (
        <SearchResultsDownload
          csvDownloadPath={`californiacrop?sk=${sk}&tab=croppesticide&limit=50&download=csv`}
          pdfDownloadPath={`californiacrop?sk=${sk}&tab=croppesticide&limit=50&download=pdf`}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default TopPesticides;
