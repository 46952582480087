import React from 'react';
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import donationSectionApi from '../../api/donationSectionApi';


const Donation = (props: any) => {
    const [data, setData] = useState({
          donate_button_label: '',
          donate_button_link: '',
          donate_text_content: ''        
      });
      
      useEffect(() => {
        const getDonation = async () => {
          try {
            const results = await donationSectionApi.requestDonationSectionDetails();
            if (results.data) {
              setData(results.data.acf);
            }
          } catch (e) {}
        };
        getDonation();
      }, []);
  const {
       donate_text_content:donateContent, 
       donate_button_label: donateButtonLabel, 
       donate_button_link:donateButtonLink } = data;
  if (donateContent) {
    return (
      <section className="source-methology-donate">
        <Container>
          <Row className="justify-content-center">
            <Col sm="10 text-center">
              <div className="text-block">
                <div
               dangerouslySetInnerHTML={{
                     __html: donateContent,
                   }}
                ></div>
                { <a className="donateBtn" target="_blank" href={donateButtonLink}>
                  <span>{donateButtonLabel}</span>
                </a> }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
  return <></>;
};
export default Donation;
