import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { home } from '../../constants/index';
import useHeader from '../../containers/useHeader';
import NavigationContainer from '../../containers/NavigationContainer';

const Header = (props: any) => {
  const { headerMainLogo, headerTopCaption, headerTopLogo, enable_popup, popup_content, popup_title, popup_publish_date } = useHeader();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  useEffect(()=>{
    if(enable_popup==='Yes'){
      let today = new Date();

      let modifyCurrentDate =  (today.getMonth() + 1)+ '-' + today.getDate() + '-' + today.getFullYear();
      //let modifyCurrentDate = "13/12/2020";//'07/12/2020';
      
      let popedUp = localStorage.getItem('popupOpened');
      let popedUpExpiredate = localStorage.getItem('popupDate');

      let localExpiredDate = JSON.stringify(popedUpExpiredate);

     // console.log("AYakil"+popup_publish_date+"Today"+modifyCurrentDate+"Other"+today+'MUja'+localExpiredDate);
     
      //if current date & expire date is same set a new value to popup
      if(new String(modifyCurrentDate).valueOf() == new String(JSON.parse(localExpiredDate)).valueOf()){        
        localStorage.setItem('popupOpened', "No");
      }
      
      if(popedUp && popedUp==='No'){
        if(modifyCurrentDate<=popup_publish_date){
          toggle();
          localStorage.setItem('popupDate', popup_publish_date);
          localStorage.setItem('popupOpened', "Yes");
        } 
      }else if(!popedUp){
        toggle();
          localStorage.setItem('popupDate', popup_publish_date);
          localStorage.setItem('popupOpened', "Yes");
      }      
    }      
  },[enable_popup])  
  return (
    <header className="header fixed-top">
      <div className="header-top-bar">
        <Container>
          <Row>
            <Col
              sm="12"
              className="d-flex justify-content-end align-items-center"
            >
              <p>{headerTopCaption}</p>
              <a href="http://www.panna.org/" target="_blank">
                <img src={headerTopLogo} alt="" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col sm="3" xs="6">
            <div className="header-logo">
              <a href={home}>
                <img src={headerMainLogo} alt="" />
              </a>
            </div>
          </Col>
          <NavigationContainer />
        </Row>
      </Container>      
      {
        enable_popup === 'Yes' ?
        <div>
          <Modal isOpen={modal} closeButton className={'welcome-popup'} toggle={toggle}>
            {/* <ModalHeader toggle={toggle} >{popup_title}</ModalHeader> */}
              <ModalBody>         
                <div dangerouslySetInnerHTML={{ __html: popup_content }} />          
              </ModalBody>        
          </Modal>
        </div>
        :
        ''
      }      
    </header>
  );
};

export default Header;
