import React from 'react';
import Helmet from 'react-helmet';
import NotFpundContainer from '../containers/NotFoundContainer';

const NotFound = (props: any) => {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
        <meta name="description" content="Page Not Found" />
      </Helmet>
      <NotFpundContainer />
    </>
  );
};
export default NotFound;
