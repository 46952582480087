import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const OverviewBreadcrumb = (props: any) => {
  return (
    <section>
      <Container>
        <Row>
          <Col xs="12">
            <Breadcrumb tag="nav" listTag="div">
              <BreadcrumbItem className="disable" tag="a">
                About
              </BreadcrumbItem>
              <BreadcrumbItem className="active" tag="a">
                Overview
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default OverviewBreadcrumb;
