import React from 'react';
import TableViewMore from '../../../components/common/TableViewMore';
import SearchResultsDownload from '../../../components/common/SearchResultsDownload';

const TopPesticides = (props: any) => {
  const { topPesticideData, total, toPesticideFull } = props;
  const title = 'Top 50 Pesticides';
  const tableTitles = [
    {
      label: 'Chemical Name',
      field: 'Chem_Name',
    },

    {
      label: 'Chemical Class',
      field: 'ClassList',
    },
    {
      label: 'Gross Pounds',
      field: 'grossPounds',
    },
    {
      label: 'Application Rate',
      field: 'applicationRate',
    },
    {
      label: 'Acres Planted',
      field: 'acresPlanted',
    },
    {
      label: 'Acres Treated',
      field: 'acresTreated',
    },
    {
      label: 'PAN Bad Actor',
      field: 'S_BA',
    },
  ];
  const tableItemLink = {
    linkedTo: '/chemical/',
    field: 'Rec_Id',
  };

  const decimalFormat = (val: any, decimalCount: number) => {
    if (val > 0.00001) {
      let decimalValue = Number(val) > 1000 ? 0 : 1;
      decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
      const nl = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue
      });
  
      return nl.format(val);
    } else {
      return val;
    }   
  }

  return (
    <>
      <div className="search-result-table top-fifty-pesticides">
        <div className="result-table">
          <div className="result-table-row titles">
            <div>
              <div>Chemical Name</div>
              <div>
                Chemical<br></br> Class
              </div>
              <div>
                Gross <br></br> Pounds
              </div>
              <div>
                App. <br></br> Rate (lbs a.i./acre)
              </div>
              <div>
                Acres <br></br> Planted
              </div>
              <div>
                Acres<br></br> Treated
              </div>
              <div>
                PAN Bad <br></br> Actor
              </div>
            </div>
          </div>
          {topPesticideData.map((item: any, key: number) => (
            <div className="result-table-row">
              <a href={`/chemical/${item.Rec_Id}`}>
                <div>
                  <h3>{item.Chem_Name}</h3>
                </div>
                <div><span>{item.ClassList}</span></div>
                <div>{decimalFormat(item.grossPounds.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.applicationRate.replace(/,/g, ""),2)}</div>
                  <div>{decimalFormat(item.acresPlanted.replace(/,/g, ""),1)}</div>
                  <div>{decimalFormat(item.acresTreated.replace(/,/g, ""),1)}</div>
                <div>{item.S_BA}</div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <TableViewMore
        items={toPesticideFull}
        title={title}
        tableColumnTitles={tableTitles}
        tableItemLink={tableItemLink}
        total={total}
      />
       <SearchResultsDownload
        csvDownloadPath={`californiaTopPesticidesDownload?sort=Gross_Lbs&order=DESC&download=csv`}
        pdfDownloadPath={`californiaTopPesticidesDownload?sort=Gross_Lbs&order=DESC&download=pdf`}
        />
    </>
  );
};
export default TopPesticides;
