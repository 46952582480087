import React from 'react';

const SearchResultTitle = (props: any) => {
  const {
    searchValue,
    numberOfResults,
    requestDataComplete,
    dataNotFound,
    isSorting,
  } = props;
  if (requestDataComplete || dataNotFound) {
    return (
      <div className="search-result-title">
        <h1>{searchValue}</h1>
        <p>
          ({dataNotFound ? '0' : numberOfResults}
          {numberOfResults > 1 ? ' Results' : ' Result'})
        </p>
      </div>
    );
  }
  if (isSorting) {
    return (
      <div className="search-result-title">
        <h1>{searchValue}</h1>
        <p>
          ({dataNotFound ? '0' : numberOfResults}
          {numberOfResults > 1 ? ' Results' : ' Result'})
        </p>
      </div>
    );
  }
  return <></>;
};
export default SearchResultTitle;
