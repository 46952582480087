import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PostBlock from '../../../components/common/PostBlock';

const BlogPosts = (props: any): ReactElement => {
  const { blogList, requestDataComplete } = props;

  // placeholders until data loaded
  const loadingItems = [];
  for (let i = 0; i < 9; i += 1) {
    loadingItems.push(
      <Col sm="6" lg="4" xl="12" key={i}>
        <div className="loading-item">
          <div className="loading-item__image">
            <p>&nbsp;</p>
          </div>
          <div className="loading-item__content">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </Col>,
    );
  }

  return (
    <section>
      <Container className="post-blocks-container">
        {requestDataComplete === true ? (
          <Row>
            {blogList.length > 0 ? (
              <>
                {blogList.map((item: any, key: number) => (
                  <Col xl={{ size: 10, offset: 1 }} lg={4} sm={6} key={key}>
                    <PostBlock type="blog-large">
                      <a href={`/blog/${item.name}`}>
                        <div className="post-block__featured-image">
                          <img src={item.featured_image} alt="" />
                        </div>
                        <div className="post-block__description">
                          <h4>{item.custom_date.toUpperCase()}</h4>
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.excerpt,
                            }}
                          />
                          <span>
                            {' '}
                            {item.readMoreLinkText
                              ? item.readMoreLinkText
                              : 'Read More'}{' '}
                          </span>
                        </div>
                      </a>
                    </PostBlock>
                  </Col>
                ))}
              </>
            ) : (
              <Col md="12" className="text-center no-articles-found">
                <h3>No Blogs Found</h3>
              </Col>
            )}
          </Row>
        ) : (
          <Row>{loadingItems}</Row>
          // <></>
        )}
      </Container>
    </section>

    // <section>
    //   <Container>
    //     <Row>{loadingItems}</Row>
    //   </Container>
    // </section>
  );
};
export default BlogPosts;
