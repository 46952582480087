/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useTop50PesticidesTabContent = (cok: string) => {
  const [data, setData] = useState({
    data: {
      List: [],
    },
  });

  useEffect(() => {
    const getCountyTop50pesticidesData = async () => {
      try {
        const results = await californiaSearchApi.requestCountyTop50PesticidesContent(
          cok,
        );
        setData(results.data);
      } catch (e) {}
    };
    getCountyTop50pesticidesData();
  }, []);

  return data && data;
};

export default useTop50PesticidesTabContent;
