/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ContactForm from '../components/ContactForm';

const OverviewTextBlock = (props: any) => {
  const { acf, content } = props;
  const { page_title } = acf;
  const { rendered } = content;
  var contentTop = rendered;
  var contentBottom = '';
  if (rendered) {
    var contentArr = rendered.split('[add contact form]');
    contentTop = contentArr[0];
    contentBottom = contentArr[1];
  }

  return (
    <section className="common-page-content">
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="text-center">{page_title}</h1>

            <div
              className="text-block"
              dangerouslySetInnerHTML={{ __html: contentTop}}
            />
            <ContactForm />
           <div
              className="text-block"
              dangerouslySetInnerHTML={{ __html: contentBottom}}
            /> 
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default OverviewTextBlock;
