/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestRelatedWebsite = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/home`);
};

const requestFooter = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/acf/v3/options/footer`);
};

const requestHeader = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/acf/v3/options/header`);
};

const requestGeneralSettings = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/acf/v3/options/theme-general-settings`);
};

const homeApi = {
  requestRelatedWebsite,
  requestFooter,
  requestHeader,
  requestGeneralSettings,
};

export default homeApi;
