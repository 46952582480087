import { useEffect, useState } from 'react';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';

const usePestContent = () => {
  // const { query } = props;
  const [pestData, setpestData] = useState({
    data: [],
  });
  const getPestData = async () => {
    try {
      const results = await advanceSearchProductApi.requestAdvanceSearchPestUses();

      return results.data.data;
    } catch (e) {}
  };
  useEffect(() => {
    const loadPestData = async () => {
      const pestDataList = await getPestData();

      pestDataList.map((elm: any) => {
        elm.selected = false;
        elm.selectedElementIndex = -1;
        elm.noOfSelected = 0;
        elm.subItems.map((subElm: any) => {
          subElm.selected = false;
        });
      });
      setpestData(pestDataList);
    };
    loadPestData();
  }, []);

  return pestData;
};

export default usePestContent;
