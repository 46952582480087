import React from 'react';
import SideBar from '../components/common/SideBar';
import useSideBarList from './useSideBarList';
import useGeneralThemeOptions from './useGeneralThemeOptions';

const DonateWithWebList = () => {
  const sideBarProps = useSideBarList();
  const generalThemeOptionProps = useGeneralThemeOptions();
  const { blog_posts } = sideBarProps;
  const {
    catUrl,
    ctaDescription,
    ctaImage,
    ctaTitle,
    ctaUrlTitle,
  } = generalThemeOptionProps;
  return (
    <>
      <SideBar
        sideBarPosts={blog_posts}
        catUrl={catUrl}
        ctaDescription={ctaDescription}
        ctaImage={ctaImage}
        ctaTitle={ctaTitle}
        ctaUrlTitle={ctaUrlTitle}
      />
    </>
  );
};

export default DonateWithWebList;
