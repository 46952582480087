/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import chemicalApi from '../../../api/chemicalApi';

const useOverviewTabContent = (chemicalId: string) => {
  const [data, setData] = useState({
    data: {
      chemicalName: '',
      badActor: '',
      highlyHazardous: '',
      tab: '',
      data: {
        summary: [],
        chemicals: {
          chemicals: [],
          metaData:{
            totalchemicals: '',
          }
        },
        toxicity: [],
      },
    },
  });

  useEffect(() => {
    const getChemicalHealthData = async () => {
      try {
        const results = await chemicalApi.requestChemicalOverviewContent(
          chemicalId,
        );
        setData(results.data);
      } catch (e) {}
    };
    getChemicalHealthData();
  }, []);

  return data && data;
};

export default useOverviewTabContent;
