/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import advanceSearchChemicalApi from '../../../api/advanceSearchChemicalApi';
import TargetPestFilter from '../components/TargetPestFilter';
import ChemicalTypeFilter from '../components/ChemicalTypeFilter';
import ChemicalToxicityFilter from '../components/ChemicalToxicityFilter';
import ChemicalRegulatoryListFilter from '../components/ChemicalRegulatoryListFilter';
import ChemicalSelections from '../components/ChemicalSelections';
import ChemicalResultTable from '../components/ChemicalResultTable';
import ChemicalResultBreadcrumb from '../components/ChemicalResultBreadcrumb';
import ChemicalTitleButton from '../components/ChemicalTitleButton';
import ChemciResultTitle from '../components/ChemciResultTitle';
import ChemicalPagination from '../components/ChemicalPagination';
import ChemicalResultTablePlaceholder from '../components/ChemicalResultTablePlaceholder';
import AdvSearchResultsDownload from '../../../components/common/AdvSearchResultsDownload';

const fileDownload = require('js-file-download');

const AdvanceSearchChemicalContainer = (props: any) => {
  const [data, setData] = useState({
    list: [],
    metaData: { page: '1', total: '0', additonalColumn: [] },
  });
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [sort, setSort] = useState('chemicalName');
  const [order, setOrder] = useState('ASC');
  const [isSorting, setIsSorting] = useState(false);
  const [page, setPage] = useState('1');
  const [total, setTotal] = useState('0');
  const [targetPests, setTargetPests] = useState({
    data: [],
  });
  const [chemicalTypes, setChemicalTypes] = useState({
    data: [],
  });
  const [chemicalToxicity, setChemicalToxicity] = useState();
  const [chemicalRegulatoryList, setChemicalRegulatoryList] = useState();

  const [selectedTargetPest, setSelectedTargetPest] = useState({
    pestParent: '',
    pest: '',
    pestLabel: '',
    itemValue: '',
  });

  const [selectedChemicalType, setSelectedChemicalType] = useState({
    chemicalTypeParent: '',
    chemicalType: '',
    chemicalTypeLabel: '',
    itemValue: '',
  });

  const [
    noOfChemicalToxicitySelected,
    setNoOfChemicalToxicitySelected,
  ] = useState(0);

  const [noOfRegulatoryListSelected, setNoOfRegulatoryListSelected] = useState(
    0,
  );

  const [selectedValues, setSelectedValues] = useState<any>([]);

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    maxLimitMessage:
      'Maximum of 10 selections are allowed. To add a different item, please deselect an item from the selection.',
    mimLimitMessage: 'Minimim one selection from ',
    showMinLimitMessage: false,
    showMaxLimitMessage: false,
  });

  const [searchData, setSearchData] = useState({
    searchTerm: null,
    pageNumber: page,
    sortField: sort,
    orderBy: order,
    targetPestTerm: '',
    chemicalTypeTerm: '',
    chemicalToxicityTerm: '',
    regulartoryListTerm: '',
  });

  const [additionalColumns, setAdditionalColumns]: any = useState([]);

  const getTargetPestData = async () => {
    try {
      const results = await advanceSearchChemicalApi.requestAdvanceSearchTargetPest();
      setTargetPests(results.data);
    } catch (e) {}
  };
  const getChemicalTypeData = async () => {
    try {
      const results = await advanceSearchChemicalApi.requestAdvanceSearchChemicalType();
      setChemicalTypes(results.data);
    } catch (e) {}
  };
  const getChemicalToxicityData = async () => {
    try {
      const results = await advanceSearchChemicalApi.requestAdvanceSearchChemicalToxicityList();
      return results.data.data;
    } catch (e) {}
  };
  const getChemicalRegulatoryListData = async () => {
    try {
      const results = await advanceSearchChemicalApi.requestAdvanceSearchChemicalRegulatoryList();
      return results.data.data;
    } catch (e) {}
  };

  const getSearchData = async () => {
    try {
      setIsSearchClicked(true);
      setRequestDataComplete(false);
      setDataNotFound(false);

      const results = await advanceSearchChemicalApi.requestAdvancedChemicalSearchResults(
        searchData,
      );

      setData(results.data.data);
      setTotal(results.data.data.metaData.total);
      setRequestDataComplete(true);
      setIsSorting(false);
      if (window.screen.width > 768) {
        window.scrollTo(0, 450);
      } else if (window.screen.width > 400) {
        window.scrollTo(0, 950);
      } else {
        window.scrollTo(0, 1550);
      }
    } catch (e) {
      if (e.response && e.response.status) {
        setDataNotFound(true);
      }
    }
  };

  const downloadSearchData = async (fileType: string) => {
    try {
      const results = await advanceSearchChemicalApi.downloadAdvancedChemicalSearchResults(
        fileType,
        searchData,
      );

      if (fileType == 'pdf') {
        fileDownload(
          results.data,
          `advanced-chemical-download-${Date.now()}.pdf`,
        );
      } else {
        fileDownload(
          results.data,
          `advanced-chemical-download-${Date.now()}.csv`,
        );
      }
    } catch (e) {
      if (e.response && e.response.status) {
      }
    }
  };

  const downloadlick = (fileType: string) => {
    downloadSearchData(fileType);
  };

  useEffect(() => {
    const loadChemicalToxicityData = async () => {
      const chemicalToxicityData = await getChemicalToxicityData();
      chemicalToxicityData.map((elm: any) => {
        elm.selected = false;
        elm.noOfSelected = 0;
        elm.subItems.map((subElm: any) => {
          subElm.selected = false;
        });
      });
      setChemicalToxicity(chemicalToxicityData);
    };

    const loadChemicalRegulatoryListData = async () => {
      const chemicalRegulatoryListData = await getChemicalRegulatoryListData();
      chemicalRegulatoryListData.map((elm: any) => {
        elm.selected = false;
        elm.noOfSelected = 0;
        elm.subItems.map((subElm: any) => {
          subElm.selected = false;
        });
      });
      setChemicalRegulatoryList(chemicalRegulatoryListData);
    };

    getTargetPestData();
    getChemicalTypeData();
    loadChemicalToxicityData();
    loadChemicalRegulatoryListData();
  }, []);

  const searchButtonClick = () => {
    searchData.targetPestTerm = selectedTargetPest.itemValue;
    searchData.chemicalTypeTerm = selectedChemicalType.itemValue;

    const selectedChemicalToxicity: any = [];
    const selectedRegulatoryList: any = [];

    const tempAdditionalColumns: any = [];

    chemicalToxicity.map((item: any, index: number) => {
      item.subItems.filter((innerItem: any, innerIndex: number) => {
        if (item.subItems[innerIndex].selected) {
          tempAdditionalColumns.push({
            name: item.subItems[innerIndex].itemValue,
            prettyName: item.subItems[innerIndex].itemLabel,
          });
          selectedChemicalToxicity.push(item.subItems[innerIndex].itemValue);
        }
      });
    });

    chemicalRegulatoryList.map((item: any, index: number) => {
      item.subItems.filter((innerItem: any, innerIndex: number) => {
        if (item.subItems[innerIndex].selected) {
          tempAdditionalColumns.push({
            name: item.subItems[innerIndex].itemValue,
            prettyName: item.subItems[innerIndex].itemLabel,
          });
          selectedRegulatoryList.push(item.subItems[innerIndex].itemValue);
        }
      });
    });

    setAdditionalColumns([]);
    setAdditionalColumns(tempAdditionalColumns);

    searchData.chemicalToxicityTerm = selectedChemicalToxicity.join();
    searchData.regulartoryListTerm = selectedRegulatoryList.join();
    getSearchData();
  };

  const pageClick = (e: any, pageNumber: string) => {
    e.preventDefault();
    searchData.pageNumber = pageNumber;
    getSearchData();
  };

  const clickSort = (sortName: string) => {
    setIsSorting(true);
    let pageOrder = searchData.orderBy;
    if (sortName === searchData.sortField) {
      pageOrder = searchData.orderBy === 'ASC' ? 'DESC' : 'ASC';
    } else {
      pageOrder = 'ASC';
    }
    searchData.pageNumber = '1';
    searchData.sortField = sortName;
    searchData.orderBy = pageOrder;
    getSearchData();
  };

  return (
    <>
      <ChemicalResultBreadcrumb />
      <ChemicalTitleButton />
      <Container>
        <Row>
          <Col>
            <div className="chemical-error-message">
              {errorMessage.showMaxLimitMessage
                ? errorMessage.maxLimitMessage
                : ''}
              {errorMessage.showMinLimitMessage
                ? errorMessage.mimLimitMessage
                : ''}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="filters-holder">
        <Row className="filters-wrapper">
          <Col md="6" className="chemical-left-filters-wrapper">
            <div className="chemical-left-filters">
              <TargetPestFilter
                targetPests={targetPests}
                selectedTargetPest={selectedTargetPest}
                setSelectedTargetPest={setSelectedTargetPest}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setIsSearchClicked={setIsSearchClicked}
              />
              <p>OR</p>
              <ChemicalTypeFilter
                chemicalTypes={chemicalTypes}
                selectedChemicalType={selectedChemicalType}
                setSelectedChemicalType={setSelectedChemicalType}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setIsSearchClicked={setIsSearchClicked}
              />
            </div>
          </Col>
          <Col
            md="6"
            className={`chemical-right-filters-wrapper ${
              selectedTargetPest.pest === '' &&
              selectedChemicalType.chemicalType === ''
                ? 'disable'
                : ''
            } `}
          >
            <div className="chemical-right-filters">
              <ChemicalToxicityFilter
                chemicalToxicity={chemicalToxicity}
                setChemicalToxicity={setChemicalToxicity}
                noOfChemicalToxicitySelected={noOfChemicalToxicitySelected}
                setNoOfChemicalToxicitySelected={
                  setNoOfChemicalToxicitySelected
                }
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setIsSearchClicked={setIsSearchClicked}
              />
              <ChemicalRegulatoryListFilter
                chemicalRegulatoryList={chemicalRegulatoryList}
                setChemicalRegulatoryList={setChemicalRegulatoryList}
                noOfRegulatoryListSelected={noOfRegulatoryListSelected}
                setNoOfRegulatoryListSelected={setNoOfRegulatoryListSelected}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setIsSearchClicked={setIsSearchClicked}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ChemicalSelections
              selectedTargetPest={selectedTargetPest}
              setSelectedTargetPest={setSelectedTargetPest}
              selectedChemicalType={selectedChemicalType}
              setSelectedChemicalType={setSelectedChemicalType}
              chemicalToxicity={chemicalToxicity}
              setChemicalToxicity={setChemicalToxicity}
              chemicalRegulatoryList={chemicalRegulatoryList}
              setChemicalRegulatoryList={setChemicalRegulatoryList}
              noOfChemicalToxicitySelected={noOfChemicalToxicitySelected}
              setNoOfChemicalToxicitySelected={setNoOfChemicalToxicitySelected}
              noOfRegulatoryListSelected={noOfRegulatoryListSelected}
              setNoOfRegulatoryListSelected={setNoOfRegulatoryListSelected}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              searchButtonClick={searchButtonClick}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              isSearchClicked={isSearchClicked}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ChemciResultTitle
              requestDataComplete={requestDataComplete}
              dataNotFound={dataNotFound}
              numberOfResults={total}
              isSorting={isSorting}
            />
          </Col>
        </Row>
        <ChemicalResultTable
          list={data.list}
          additionalColumns={data.metaData.additonalColumn}
          // additionalColumns={additionalColumns}
          requestDataComplete={requestDataComplete}
          dataNotFound={dataNotFound}
          clickSort={clickSort}
        />
        <ChemicalResultTablePlaceholder isSorting={isSorting} type="chemical" />
      </Container>
      {requestDataComplete ? (
        <AdvSearchResultsDownload
          csvParams={`sort=${sort}&order=${order}&type=product&search=`}
          pdfParams={`sort=${sort}&order=${order}&type=product&search=`}
          downloadlick={downloadlick}
        />
      ) : (
        ''
      )}

      <ChemicalPagination
        currentPage={searchData.pageNumber}
        numPages={Math.ceil(parseInt(total) / 25)}
        pageClick={pageClick}
        requestDataComplete={requestDataComplete}
        dataNotFound={dataNotFound}
      />
      <div style={{ padding: '60px  0 0 0' }} />
    </>
  );
};
export default AdvanceSearchChemicalContainer;
