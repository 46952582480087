/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestResourceMethologyDetails = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=source-methodology`);
};

const sourceMethologyApi = {
  requestResourceMethologyDetails,
};

export default sourceMethologyApi;
