import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

const BlogSearch = (props: any) => {
  const { searchHandleSubmit } = props;
  const [searchValue, setSearchValue] = useState('');
  return (
    <section>
      <Container className="post-blocks-search-container">
        <Row>
          <Col xl={{ size: 4, offset: 7 }} sm={{ size: 6, offset: 6 }}>
            <Form
              className="search"
              onSubmit={(e) => searchHandleSubmit(e, searchValue)}
            >
              <FormGroup>
                <Label for="search">search </Label>
                <Input
                  type="search"
                  name="search"
                  id="search"
                  placeholder="search"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default BlogSearch;
