/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestTooltipInfo = (): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/getTooltipContent`);
};
const requestTooltip = (type: string, param: string): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/TooltipDetails?type=${type}&param=${param}`);
};

const tooltipApi = {
  requestTooltipInfo,
  requestTooltip
};

export default tooltipApi;
