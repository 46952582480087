import React from 'react';
import Helmet from 'react-helmet';
import SourceMethologyMainContent from '../containers/SourceMethologyMainContent';

const Sources = (props: any) => {
  return (
    <div>
      <Helmet>
        <title>PesticideInfo | Sources and Methodology</title>
        <meta name="description" content="Sources and Methodology" />
      </Helmet>
      <SourceMethologyMainContent />
    </div>
  );
};
export default Sources;
