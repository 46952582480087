import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import ProductDetailBreadcrumb from '../components/ProductDetailBreadcrumb';
import ProductDetailTitle from '../components/ProductDetailTitle';
import ProductDetailNavButton from '../components/ProductDetailNavButton';
import ProductOverview from '../containers/tabs/ProductOverview';
import ProductToxicity from '../containers/tabs/ProductToxicity';
import ProductRegistration from '../containers/tabs/ProductRegistration';
import SideBarContainer from '../../../containers/SideBarContainer';

const ProductDetailsInner = (props: any) => {
  const [productName, setProductName] = useState('');
  const [badActor, setBadActor] = useState('');
  const [highlyHazardous, setHighlyHazardous] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const tabtoggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ProductDetailBreadcrumb productName={productName} />
      <section className="tab-main-content">
        <Container>
          <Row>
            <Col lg="9">
              <div className="inner-upper-container">
                <ProductDetailTitle
                  productName={productName}
                  badActor={badActor}
                />
                <ProductDetailNavButton />
              </div>
              <div className="tab-main-wrapper">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('1');
                      }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('2');
                      }}
                    >
                      Toxicity
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('3');
                      }}
                    >
                      Registration & Use
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <ProductOverview
                    setBadActor={setBadActor}
                    setProductName={setProductName}
                    setHighlyHazardous={setHighlyHazardous}
                  />
                  <ProductToxicity productName={productName} />
                  <ProductRegistration
                    productName={productName}
                    badActor={badActor}
                  />
                </TabContent>
              </div>
            </Col>
            <Col lg="3">
              <SideBarContainer />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ProductDetailsInner;
