import { useEffect, useState } from 'react';
import resourcesApi from '../../../api/resourcesApi';
import  homeApi from '../../../api/homeApi';

const useResourceInner = (slug: string) => {
  const [enableDonation, setEnableDonation] = useState('No');
  const [enableNewsletterSubscription, setEnableNewsletterSubscription] = useState('No');
  const [data, setData] = useState({
    title: {
      rendered: '',
    },
    featured_image: '',
    acf: {
      custom_date: '',
      file_upload: [],
      enable_donation:'',
      enable_newsletter_subscription: '',
    },
    content: {
      rendered: '',
    },
    Category: '',
    relatedResourcePost: [],
    enableDonation: enableDonation,
    enableNewsletterSubscription: enableNewsletterSubscription,
  });

  useEffect(() => {
    const getResourceInnerData = async () => {
      try {
        const results = await resourcesApi.requestResourceDetails(slug);
        setData(results.data[0]);
      } catch (e) {}
    };
    const getHeaderData = async () => {
      try {
        const results = await homeApi.requestHeader();
        setEnableDonation(results.data.acf.enable_donation_resource);
        setEnableNewsletterSubscription(results.data.acf.enable_newsletter_subscription_resource);      
      } catch (e) {}
    };
    getResourceInnerData();
    getHeaderData();
  }, []);
  data['enableDonation'] = enableDonation;
  data['enableNewsletterSubscription'] = enableNewsletterSubscription;
  return data && data;
};

export default useResourceInner;
