import { useEffect, useState } from 'react';
import termsConditionsApi from '../../../api/termsConditionsApi';

const useTermsConditions = () => {
 
const [data, setData] = useState({
  content: '',
  acf: '',
});

useEffect(() => {
  const getTermsConditionsData = async () => {
    try {
      const results = await termsConditionsApi.requestTermsConditions();

      if (results.data.length > 0) {
        setData(results.data[0]);
       
      }
      
    } catch (e) {
      
    }
  };
  getTermsConditionsData();
}, []);

return data && data;
};


export default useTermsConditions;


