import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const TooltipsBreadcrumb = (props: any) => {
  return (
    <Container>
      <Row>
        <Col xs="12">
          <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem tag="a" className="disable">
              About
            </BreadcrumbItem>
            <BreadcrumbItem className="active" tag="a">
              Tooltip Info
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </Container>
  );
};
export default TooltipsBreadcrumb;
