/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestSideBarPosts = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/sidebar?perpage=2`);
};

const sideBarApi = {
  requestSideBarPosts,
};

export default sideBarApi;
