import React from 'react';
import Helmet from 'react-helmet';
import CaliforniaSearchContainer from '../containers/CaliforniaSearchContainer';

const CaliforniaSearch = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California Pesticide Use</title>
        <meta name="description" content="California Pesticide Use" />
      </Helmet>
      <CaliforniaSearchContainer />
    </>
  );
};
export default CaliforniaSearch;
