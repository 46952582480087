import { useEffect, useState } from 'react';
import advanceSearchRegYearApi from '../../../api/advanceSearchProductApi';

const useInfoContent = () => {
  const [yearData, setyearData] = useState({
    data: [],
  });
  const getRegYearData = async () => {
    try {
      const results = await advanceSearchRegYearApi.requestAdvanceSearchProductRegYear();

      return results.data.data;
    } catch (e) {}
  };
  useEffect(() => {
    const loadRegYearData = async () => {
      const regYearDataList = await getRegYearData();

      regYearDataList.map((elm: any) => {
        elm.selected = false;
        elm.selectedElementIndex = -1;
        elm.noOfSelected = 0;
        elm.subItems.map((subElm: any) => {
          subElm.selected = false;
        });
      });
      setyearData(regYearDataList);
    };
    loadRegYearData();
  }, []);

  return yearData;
};

export default useInfoContent;
