import React from 'react';
import BreadCrumb from '../components/SearchLandingBreadCrumb';

const FeaturedText = (props: any) => {
  return (
    <>
      <BreadCrumb />
    </>
  );
};
export default FeaturedText;
