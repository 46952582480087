/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

const ChemicalRegulatoryListFilter = (props: any) => {
  const {
    chemicalRegulatoryList,
    setChemicalRegulatoryList,
    noOfRegulatoryListSelected,
    setNoOfRegulatoryListSelected,
    errorMessage,
    setErrorMessage,
    selectedValues,
    setSelectedValues,
    setIsSearchClicked,
  } = props;
  const [regulatoryListActiveId, setregulatoryListActiveId] = useState();

  const toggleRegulatoryListItem = (
    parentIndex: number,
    childIndex: number,
  ) => {
    const regulatoryList = [...chemicalRegulatoryList];
    const isSelected =
      regulatoryList[parentIndex].subItems[childIndex].selected;
    if (!isSelected) {
      if (noOfRegulatoryListSelected === 10) {
        const error = { ...errorMessage, showMaxLimitMessage: true };
        setErrorMessage(error);
        return;
      }
      regulatoryList[parentIndex].selected = true;
      regulatoryList[parentIndex].noOfSelected += 1;
      setNoOfRegulatoryListSelected(noOfRegulatoryListSelected + 1);
      selectedValues.push({
        name: 'regulatory',
        itemLabel: regulatoryList[parentIndex].itemLabel,
        itemValue: regulatoryList[parentIndex].itemValue,
        noOfSelected: regulatoryList[parentIndex].noOfSelected,
        parentIndex,
        childIndex,
        subItemsItemLabel:
          regulatoryList[parentIndex].subItems[childIndex].itemLabel,
        subItemsItemValue:
          regulatoryList[parentIndex].subItems[childIndex].itemValue,
      });
    } else {
      regulatoryList[parentIndex].noOfSelected -= 1;
      setNoOfRegulatoryListSelected(noOfRegulatoryListSelected - 1);
      const error = { ...errorMessage, showMaxLimitMessage: false };
      setErrorMessage(error);
      const newSelectedValues = selectedValues.filter((obj: any) => {
        return (
          obj.subItemsItemValue !==
          regulatoryList[parentIndex].subItems[childIndex].itemValue
        );
      });
      setSelectedValues(newSelectedValues);
    }
    regulatoryList[parentIndex].subItems[childIndex].selected = !isSelected;
    setChemicalRegulatoryList(regulatoryList);
  };

  if (chemicalRegulatoryList !== undefined) {
    return (
      <div className="chemical-toxicity-filter">
        <div className="title">REGULATORY LISTS</div>
        <ul className="filter">
          {chemicalRegulatoryList.map((item: any, key: number) => (
            <li
              key={`item-${key}`}
              className={`${
                regulatoryListActiveId !== key ? 'collapsed' : ''
              } ${item.noOfSelected > 0 ? 'selected' : ''}`}
              onClick={(): void => {
                if (regulatoryListActiveId === key) {
                  setregulatoryListActiveId('');
                } else {
                  setregulatoryListActiveId(key);
                }
              }}
            >
              {item.itemLabel}
              <ul>
                {item.subItems.map((subItem: any, innerKey: number) => (
                  <li
                    key={`item-${innerKey}`}
                    className={`${subItem.selected ? 'selected' : ''}`}
                    onClick={(e): void => {
                      setIsSearchClicked(false);
                      e.stopPropagation();
                      toggleRegulatoryListItem(key, innerKey);
                    }}
                  >
                    {subItem.itemLabel}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return <></>;
};
export default ChemicalRegulatoryListFilter;
