import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const NotFoundText = (props: any) => {
  return (
    <>
      <section className="not-found-container">
        <Container>
          <Row>
            <Col xs="12">
              <div className="not-found-content">
                <h1>404</h1>
                <h2>Oops…Page Not Found!</h2>
                <p>
                  This page was not found. You may have mistyped the address or
                  the page may have moved.
                </p>
                <a href="/">Go to Home</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default NotFoundText;
