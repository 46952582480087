import React from 'react';
import Helmet from 'react-helmet';
import AdvanceSearchChemicalContainer from '../containers/AdvanceSearchChemicalContainer';

const AdvanceSearchChemical = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Advanced Chemical Search</title>
        <meta name="description" content="Advanced Chemical Search" />
      </Helmet>
      <AdvanceSearchChemicalContainer />
    </>
  );
};
export default AdvanceSearchChemical;
