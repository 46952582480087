import React from 'react';
import Helmet from 'react-helmet';
import CropDetailContainer from '../containers/CropDetailContainer';

const CropDetailPage = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California Pesticide Use - Crop</title>
        <meta name="description" content="California Pesticide Use Crop" />
      </Helmet>
      <CropDetailContainer />
    </>
  );
};
export default CropDetailPage;
