import React from 'react';
import Helmet from 'react-helmet';
import BlogListContainer from '../containers/BlogListContainer';

const BlogList = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Blog</title>
        <meta name="description" content="Blog" />
      </Helmet>
      <BlogListContainer />
    </>
  );
};
export default BlogList;
