import { useEffect, useState } from 'react';
import homeApi from '../api/homeApi';

const useFooter = () => {
  // const { query } = props;
  const [data, setData] = useState({
    footerAddressOne: '',
    footerAddressTwo: '',
    footerBottomCaption: '',
    footerMainLogo: '',
    footerSocialMediaCaption: '',
    footerSocialMediaFacebook: '',
    footerSocialMediaPinterest: '',
    footerSocialMediaTwitter: '',
    footerSocialMediaYoutube: '',
  });

  useEffect(() => {
    const getFooterData = async () => {
      try {
        const results = await homeApi.requestFooter();
        setData(results.data.acf);
      } catch (e) {}
    };
    getFooterData();
  }, []);

  return data && data;
};

export default useFooter;
