import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const BreadCrumb = (props: any) => {
  return (
    <section>
      <Container>
        <Row>
          <Col xs="12">
            <Breadcrumb className="detail-page" tag="nav" listTag="div">
              <BreadcrumbItem tag="a" href="/">
                Search Chemicals or Products
              </BreadcrumbItem>
              <BreadcrumbItem tag="a" href="/advance-search">
                Advanced Search
              </BreadcrumbItem>
              <BreadcrumbItem className="active" tag="a">
                Products
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default BreadCrumb;
