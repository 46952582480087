import { useEffect, useState } from 'react';
import sourceMethologyApi from '../../../api/sourceMethologyApi';

const useSourceMethologyContent = () => {
  const [data, setData] = useState({
    content: '',
    acf: {
      page_title: '',
      donate_button_label: '',
      donate_button_link: '',
      donate_text_content: '',
      enable_donation:'',
      enable_newsletter_subscription:'',
      question_answers: [],
    },
  });

  useEffect(() => {
    const getSourceMethologyData = async () => {
      try {
        const results = await sourceMethologyApi.requestResourceMethologyDetails();
        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    getSourceMethologyData();
  }, []);

  return data && data;
};

export default useSourceMethologyContent;
