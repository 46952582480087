/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestTopPesticidesContent = (): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiaTopPesticides?sort=Gross_Lbs&order=DESC`,
  );
};

const requestTopCropsAndSitesContent = (): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiaTopCrops?sort=Gross_Lbs&order=DESC`,
  );
};

const requestAllPesticideUse = (): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/californiaPestUseByCounty?sort=Gross_Lbs&order=DESC`,
  );
};


const TopPesticidesApi = {
  requestTopPesticidesContent,
  requestTopCropsAndSitesContent,
  requestAllPesticideUse
};

export default TopPesticidesApi;
