import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const ResourceInnerBreadcrumb = (props: any) => {
  const { title } = props;
  return (
    <Breadcrumb tag="nav" listTag="div">
      <BreadcrumbItem tag="a" className="disable">
        Alternatives & Resources
      </BreadcrumbItem>
      <BreadcrumbItem tag="a" href="/resources">
        Resources
      </BreadcrumbItem>
      <BreadcrumbItem tag="a" className="active">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
export default ResourceInnerBreadcrumb;
