/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import productApi from '../../../../api/productApi';

const UseProductToxicity = (regno: string) => {
  const [data, setData] = useState({
    summary: [],
    otherIngredients: []
  });

  useEffect(() => {
    const getProductToxicityData = async () => {
      try {
        const results = await productApi.requestProductToxicityContent(regno);
      
        setData(results.data.data.data);
      } catch (e) { }
    };
    getProductToxicityData();
  }, []);

  return data && data;
};

export default UseProductToxicity;
