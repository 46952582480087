import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import TextBlock from '../components/TextBlock';
import useCprMapContent from './useCprMapContent';
import GlobalCprMapBreadcrumb from '../components/GlobalCprMapBreadcrumb';

const CprMapMainContainer = (props: any) => {
  const globalBanMapProps = useCprMapContent();

  const { content } = globalBanMapProps;

  return (
    <>
      <section className={`global-ban-map-data`}>
        <Container>
          <Row>
            <Col lg={'9'}>
              <GlobalCprMapBreadcrumb />
              <TextBlock content={content.rendered} />
            </Col>
            <Col lg={'12'}>
              <div className={`iframe-holder`}>
                <iframe title="Map" width="100%" height="900px"  src="https://cpr-explorer-v2-snowy.vercel.app/" frameBorder={0} style={{
                  marginBottom: '40px',
                }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CprMapMainContainer;
