/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { searchApiPrefix } from '../constants';

const requestProductToxicityContent = (regno: string): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/product?regno=${regno}&tab=toxicity`);
};

const requestProductOverviewContent = (
  regno: string,
  distno: string,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/product?regno=${regno}&distno=${distno}&tab=overview`,
  );
};

const requestProductRegistrationContent = (
  regno: string,
  distno: string,
): Promise<any> => {
  return axiosApi.get(
    `${searchApiPrefix}/product?regno=${regno}&distno=${distno}&tab=registrationAndUse`,
  );
};

const requestProductOthernames = (regno: string): Promise<any> => {
  return axiosApi.get(`${searchApiPrefix}/productSynonyms?regno=${regno}`);
};

const requestPestViewMore = (regno: string): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/productPests?regno=${regno}`);
};

const requestPestSuggestions = (
  regno: string,
  searchTerm: string,
  limit: number,
): Promise<any> => {
  return axiosApi.post(
    `${searchApiPrefix}/productPests?regno=${regno}&limit=${limit}`,
    {
      search: searchTerm,
    },
  );
};

const requestCommodityViewMore = (regno: string): Promise<any> => {
  return axiosApi.post(`${searchApiPrefix}/productCommodities?regno=${regno}`);
};

const requestCommoditySuggestions = (
  regno: string,
  searchTerm: string,
  limit: number,
): Promise<any> => {
  return axiosApi.post(
    `${searchApiPrefix}/productCommodities?regno=${regno}&limit=${limit}`,
    {
      search: searchTerm,
    },
  );
};

const productApi = {
  requestProductToxicityContent,
  requestProductOverviewContent,
  requestProductRegistrationContent,
  requestProductOthernames,
  requestPestViewMore,
  requestPestSuggestions,
  requestCommodityViewMore,
  requestCommoditySuggestions,
};

export default productApi;
