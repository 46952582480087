import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import parseJson from 'parse-json';

interface ResourceInnerArticleBlock {
  title: string;
  bannerImage: string;
  customDate: string;
  content: string;
  category: any;
}
//to open the external link in new tab
const checkHrefs = (content: any) => {
  let a = content.split("<a ");
  let hostName = window.location.origin;
  let newContent = '';
  for (let i = 0; i < a.length; i++) {
    if (a[i].indexOf(hostName) === -1) {
      newContent += a[i].replace('href=', '<a target="_blank" href=');
    } else {
      newContent += a[i].replace('href=', '<a href=');
    }
  }
  return newContent;
};



const ResourceInnerArticle = (
  props: ResourceInnerArticleBlock,
): ReactElement => {
  const { title, bannerImage, content, category } = props;
  const pageUrl = window.location.href;

  return (
    <>
      <Row className="common-inner-header">
        <Col sm="12">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
        </Col>
        <Col sm="12">
          <img src={bannerImage} alt="" className="img-fluid" />
        </Col>
        <Col sm="12 d-flex">
          <div className="d-flex mr-auto">&nbsp;</div>
          <div className="d-flex blog-inner-social-media">
            <ul>
              <li>
                <EmailShareButton children="" url={pageUrl} />
              </li>
              <li>
                <FacebookShareButton children="" url={pageUrl} title={title} />
              </li>
              <li>
                <TwitterShareButton children="" url={pageUrl} title={title} />
              </li>
              <li>
                {/* <LinkedinShareButton children="" url={pageUrl} title={title} /> */}
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div
            className="text-block detail-page-content"
            dangerouslySetInnerHTML={{ __html: checkHrefs(content) }}
          />
        </Col>
      </Row>
      {category != '' ? (
        <Row>
          <Col sm="12">
            <div className="resource-inner-category-list">
              <h4>Categories: </h4>
              <span dangerouslySetInnerHTML={{ __html: category.join(', ') }} />
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
};
export default ResourceInnerArticle;
