import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import TooltipInTable from '../../../components/common/TooltipInTable';
import TableViewMore from '../../../components/common/TableViewMore';
import chemicalApi from '../../../api/chemicalApi';
import useUsageOtherNames from '../containers/useUsageOtherNames';
import { UncontrolledTooltip } from 'reactstrap';

const TableCardRelatedList = (props: any) => {
  const { chemicals, total, chemicalId } = props;
  const values = useUsageOtherNames(chemicalId);
  const { data } = values;
  const [chemicalsData, setChemicalsData] = useState([]);
  useEffect(() => {
    if (chemicals) {
      setChemicalsData(chemicals.slice(0, 8));
    }
  }, [chemicals]);
  const tableTitles = [
    {
      label: 'Chemical Name',
      field: 'chemicalName',
    },
    {
      label: 'CAS Number',
      field: 'casNumber',
    },
    {
      label: 'Reason',
      field: 'reason',
    },
    {
      label: 'Chem Use Type',
      field: 'ChemuseType',
    },
    {
      label: 'U.S. EPA Reg',
      field: 'epaRegistered',
    },
    {
      label: 'PAN BAD ACTOR',
      field: 'panBadActor',
    },
  ];
  const tableItemLink = {
    linkedTo: '',
    field: '',
  };

  return (
    <div className="table-container related-chems">
      <div className="title-container">
        <h3>Related Chemicals</h3>
        <TooltipInTable tooltipKey="relatedchems" section= "chemical"  />
      </div>
      <Table
        className="heading-table related-chemicals"
        responsive
        striped
        hover
      >
        <thead>
          <tr>
            <th>Chemical Name</th>
            <th>CAS Number</th>
            <th>Reason</th>
            <th>Chem Use Type</th>
            <th>U.S. EPA Reg</th>
            <th>
              PAN BAD ACTOR
              <TooltipInTable tooltipKey="badactor" section= "chemical" />
            </th>
          </tr>
        </thead>
        <tbody>
          {chemicalsData.map((item: any, key: number) => (
            <tr key={key}>
              <td>
               <span className="chemical-name-limit" id={`Tooltip${key}`}>{item.chemicalName}</span>
                <UncontrolledTooltip placement="right" href="#"  target={`Tooltip${key}`} >
                 {item.chemicalName}
                </UncontrolledTooltip>
              </td>
              <td>{item.casNumber}</td>
              <td>{item.reason}</td>
              <td>{item.ChemuseType}</td>
              <td>{item.epaRegistered}</td>
              <td>{item.panBadActor}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <TableViewMore
        items={data.relatedChemicals}
        title="Related Chemicals"
        tableColumnTitles={tableTitles}
        tableItemLink={tableItemLink}
        total={total}
        viewMoreEnableCount={8}
        specialClass="related-chemicals"
      />
    </div>
  );
};
export default TableCardRelatedList;
