import React from 'react';
import Helmet from 'react-helmet';
import TermsConditionsDetails from '../containers/TermsConditionsDetails';

const TermsConditions = (props: any) => {
  return (
    <section>
      <Helmet>
        <title>PesticideInfo | Terms and Conditions</title>
        <meta name="description" content="Terms and Conditions" />
      </Helmet>
      <TermsConditionsDetails />
    </section>
  );
};
export default TermsConditions;
