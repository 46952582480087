import React, { useState, ReactElement } from 'react';
import { Tooltip } from 'reactstrap';
import toolTipApi from '../../api/tooltipApi';

const TooltipInTable = (props: any): ReactElement => {
  const { tooltipKey, section, tableData, linkData } = props;

  const [toolTip, setToolTip] = useState({
    short_description: '',
    link: '',
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const loadCallout = async () => {
    let results;
    let shortDescription = 'No Data';
    let link = '';
    try {
      const { origin } = window.location;
      results = await toolTipApi.requestTooltip(section, tooltipKey);
      shortDescription = results.data.data[0].short_description;

      const isUrl = results.data.data[0].link.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
      );
      if (isUrl === null) {
        link = `${origin}/${results.data.data[0].link}`;
      } else {
        link = results.data.data[0].link;
      }
    } catch (e) {}

    setToolTip({
      // eslint-disable-next-line @typescript-eslint/camelcase
      short_description: shortDescription,
      link,
    });
  };

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div className="tooltip-holder">
      <div
        className="tooltip-icon"
        id={tooltipKey}
        onMouseEnter={() => loadCallout()}
      />
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={tooltipKey}
        toggle={toggle}
        autohide={false}
        flip={false}
      >
        {toolTip.short_description !== '' ? (
          <>
            {toolTip.short_description}
            <a href={toolTip.link} target="_blank">
              &nbsp;Read More
            </a>
          </>
        ) : (
          <div className="loader-small" />
        )}
      </Tooltip>
    </div>
  );
};

export default TooltipInTable;
