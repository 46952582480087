import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const FeaturedText = (props: any) => {
  const { content } = props;
  return (
    <section className="advance-featured-text">
      <Container>
        <Row className="justify-content-center">
          <Col sm="10 ">
            <div
              className="text-block text-center"
              dangerouslySetInnerHTML={{ __html: content.rendered }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default FeaturedText;
