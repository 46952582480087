import React, { useEffect, useState } from 'react';
import AutoComplete from '../../../components/common/AutoComplete';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';

const CommodityAutoCompleteContainer = (props: any) => {
  const { setComodity, comodity, setIsSearchClicked } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState();
  const getPestNameAutoCompleteSuggestions = async (search: any) => {
    // console.log(searchTerm);
    try {
      const results = await advanceSearchProductApi.requestAdvanceSearchCommotditySuggest(
        search,
      );
      setSuggestions(results.data.data.list);
      // console.log(results.data.data);
    } catch (e) {}
  };
  useEffect(() => {
    setComodity(searchTerm);
    // getPestNameAutoCompleteSuggestions(setPestName);
  }, []);

  const getsuggestions = (search: any) => {
    // console.log(serchTerm);
    setSearchTerm(search);
    getPestNameAutoCompleteSuggestions(search);
    // console.log(suggestions);
  };

  return (
    <AutoComplete
      output={comodity}
      setOutput={setComodity}
      suggestions={suggestions}
      getsuggestions={getsuggestions}
      placeholder="Commodity (Crop) or Site"
      setFinalSearchValue={() => {}}
      setIsSearchClicked={setIsSearchClicked}
    />
  );
};
export default CommodityAutoCompleteContainer;
