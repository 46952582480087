// eslint-disable-next-line import/prefer-default-export
export const UPDATE_SIMPLE_SEARCH_VALUE = 'UPDATE_SIMPLE_SEARCH_VALUE';
export const UPDATE_SEARCH_RESULT_PAGE_NUMBER =
  'UPDATE_SEARCH_RESULT_PAGE_NUMBER';
export const UPDATE_SIMPLE_SEARCH_NUMBER_OF_RESULSTS =
  'UPDATE_SIMPLE_SEARCH_NUMBER_OF_RESULSTS';
// export const UPDATE_SIMPLE_SEARCH_TYPE = 'UPDATE_SIMPLE_SEARCH_TYPE';
// export const REQUEST_BLOG_LIST_SUCCESS = 'REQUEST_BLOG_LIST_SUCCESS';
// export const UPDATE_BLOG_LIST_PAGE_NUMBER = 'UPDATE_BLOG_LIST_PAGE_NUMBER';
// export const UPDATE_BLOG_LIST_SEARCH_VALUE = 'UPDATE_BLOG_LIST_SEARCH_VALUE';
// export const REQUEST_BLOG_LIST_ERROR = 'REQUEST_BLOG_LIST_ERROR';
