import React from 'react';
import Helmet from 'react-helmet';
import ResourceListContainer from '../containers/ResourceListContainer';

const ResourceList = (props: any) => {
  return (
    <section>
      <Helmet>
        <title>PesticideInfo | Resources</title>
        <meta name="description" content="Resources" />
      </Helmet>
      <ResourceListContainer />
    </section>
  );
};
export default ResourceList;
