/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import chemicalApi from '../../../api/chemicalApi';

const useChemicalHealthContent = (id: string) => {
  const [data, setData] = useState({
    acuteToxity: [],
    reproductiveAndDevelopmentalToxicity: [],
    cancerInformation: [],
    endocrineDisruption: [],
    Poisioning: [],
  });

  useEffect(() => {
    const getChemicalHealthData = async () => {
      try {
        const results = await chemicalApi.requestChemicalHealthContent(id);
        setData(results.data.data.data);
      } catch (e) {}
    };
    getChemicalHealthData();
  }, []);

  return data && data;
};

export default useChemicalHealthContent;
