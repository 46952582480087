import React from 'react';
import { TabPane } from 'reactstrap';
import UseProductRegistration from './UseProductRegistration';
import ProductRegistrationUses from '../../components/ProductRegistrationUses';
import PestContainer from './PestContainer';
import ComodityContainer from './ComodityContainer';

const ProductRegistration = (props: any) => {
  const url = new URL(window.location.href);
  const productRegno = url.searchParams.get('regno')!;
  const productDistno = url.searchParams.get('distno')!;
  const { productName, badActor } = props;
  const values = UseProductRegistration(productRegno, productDistno);

  const { uses, pests, commodityOrSite } = values;

  return (
    <>
      <TabPane className="registration" tabId="3">
        <ProductRegistrationUses uses={uses} />
        <PestContainer
          tableHeading="PESTS"
          tableData={pests.pests}
          resultCount={pests.metaData.totalPests}
          productName={productName}
          badActor={badActor}
        />
        <ComodityContainer
          tableHeading="COMMODITY (CROP) OR SITE"
          tableData={commodityOrSite.commodities}
          resultCount={commodityOrSite.metaData.totalCommodities}
          productName={productName}
          badActor={badActor}
        />
      </TabPane>
    </>
  );
};
export default ProductRegistration;
