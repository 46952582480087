import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import ChemicalDetailBreadcrumb from '../components/ChemicalDetailBreadcrumb';
import SideBarContainer from '../../../containers/SideBarContainer';
import OverviewTab from './OverviewTab';
import HealthTab from './HealthTab';
import RegulationTab from './RegulationTab';
import EcotoxicityTab from './EcotoxicityTab';
import UsageTab from './UsageTab';
import toolTipApi from '../../../api/tooltipApi';

const ChemicalInnerDetail = (props: any) => {
  const [activeTab, setActiveTab] = useState('1');
  const [chemicalName, setChemicalName] = useState('');
  const [badActor, setBadActor] = useState('');
  const [highlyHazardous, setHighlyHazardous] = useState('');

  const [badActorTooltipOpen, setBadActorTooltipOpen] = useState(false);
  const [highlyHazardousTooltipOpen, setHighlyHazardousTooltipOpen] = useState(
    false,
  );
  const [toolTip, setToolTip] = useState({
    short_description: '',
    link: '',
  });
  // const toggle = ;
  const tabtoggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadCallout = async (name: string) => {
    let results;
    // setBadActorTooltipOpen(false)
    try {
      results = await toolTipApi.requestTooltip('chemical', name);      
    } catch (e) {}
    if (results) {
      let hostName = window.location.origin;
      setToolTip({        
        short_description: results.data.data[0].short_description,
        link: hostName + "/"+ results.data.data[0].link,
      });
      //  setBadActorTooltipOpen(true)
    }    
  };

  const toggle = () => {
    setBadActorTooltipOpen(!badActorTooltipOpen);
  };
  return (
    <>
      <ChemicalDetailBreadcrumb chemName={chemicalName} />
      <section className="tab-main-content">
        <Container>
          <Row>
            <Col lg="9">
              <div className="inner-upper-container">
                <div className="title-container">
                  <h2>
                    {chemicalName}
                    <div className="logo-wrapper">
                      {badActor === 'Yes' ? (
                        <div className="tooltip-holder">
                          <span
                            id="badActor"
                            onMouseEnter={() => loadCallout('BadActor')}
                          />
                          <Tooltip
                            placement="bottom"
                            isOpen={badActorTooltipOpen}
                            target="badActor"
                            toggle={toggle}
                            autohide={false}
                          >
                            {toolTip.short_description ? (
                              <>
                                {toolTip.short_description}
                                <a href={toolTip.link} target="_blank">
                                  &nbsp;Read More
                                </a>
                              </>
                            ) : (
                              <div className="loader-small" />
                            )}
                          </Tooltip>
                        </div>
                      ) : (
                        <></>
                      )}
                      {highlyHazardous === 'Yes' ? (
                        <div className="tooltip-holder ">
                          <span id="highHazardous" 
                          onMouseEnter={() => loadCallout('hhps')} />
                          <Tooltip
                            placement="bottom"
                            isOpen={highlyHazardousTooltipOpen}
                            target="highHazardous"
                            autohide={false}
                            toggle={() => {
                              setHighlyHazardousTooltipOpen(
                                !highlyHazardousTooltipOpen,
                              );
                            }}
                          >
                            {toolTip.short_description ? (
                              <>
                                {toolTip.short_description}
                                <a href={toolTip.link} target="_blank">
                                  &nbsp;Read More
                                </a>
                              </>
                            ) : (
                              <div className="loader-small" />
                            )}
                          </Tooltip>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </h2>
                </div>
                <div className="button-container">
                  <a
                    className="blue-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      tabtoggle('2');
                      setTimeout(() => {
                        window.scrollTo({
                          top: document.body.scrollHeight,
                          // top: 500,
                          left: 0,
                          behavior: 'smooth',
                        });
                      }, 200);
                    }}
                  >
                    <span>Poisoning Symptoms</span>
                  </a>
                  <a
                    className="red-btn"
                    href="https://www.migrantclinician.org/issues/occupational-health/pesticides/reporting-illnesses"
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    <span>Report a Poisoning</span>
                  </a>
                </div>
              </div>
              <div className="tab-main-wrapper">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('1');
                      }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('2');
                      }}
                    >
                      Health
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('3');
                      }}
                    >
                      Ecotoxicity
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '4' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('4');
                      }}
                    >
                      Usage
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '5' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('5');
                      }}
                    >
                      Regulation
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane className="overview" tabId="1">
                    <OverviewTab
                      setBadActor={setBadActor}
                      setChemicalName={setChemicalName}
                      setHighlyHazardous={setHighlyHazardous}
                    />
                  </TabPane>
                  <TabPane className="health" tabId="2">
                    <HealthTab chemName={chemicalName} />
                  </TabPane>
                  <TabPane className="ecotoxicity" tabId="3">
                    <EcotoxicityTab />
                  </TabPane>
                  <TabPane className="usage" tabId="4">
                    <UsageTab badActor={badActor} chemName={chemicalName} />
                    {/* <TableCardForm />
                    <UsageMap />
                    <TableCardRelatedList /> */}
                  </TabPane>
                  <TabPane className="regulation" tabId="5">
                    <RegulationTab />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col lg="3">
              <SideBarContainer />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ChemicalInnerDetail;
