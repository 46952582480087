import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const ViewMoreModalWithLinks = (props: any) => {
  const {
    modal,
    itemName,
    badActor,
    closeClick,
    list,
    itemKey,
    count,
    popUpTitle,
    link,
  } = props;
  const { metaData, items } = list;
  return (
    <>
      <Modal isOpen={modal} className="viewmore-modal">
        <div
          onClick={() => {
            closeClick();
          }}
          className="close"
        />
        <ModalBody>
          <div className="title-container">
            <h2>{itemName}</h2>
            <div className="logo-wrapper">
              {badActor === 'Yes' ? (
                <div className="tooltip-holder">
                  <span id="badActor" />
                </div>
              ) : (
                ''
              )}
              {/* <div className="tooltip-holder ">
                <span id="highHazardous"></span>
              </div> */}
            </div>
          </div>
          <div className="otherlist">
            <div className="title">
              {/* PRODUCTS CONTAINING {itemName} */}
              {popUpTitle}
              <span>
                ({metaData[count]}{' '}
                {metaData[count] > 1 ? ' Results' : ' Result'})
              </span>
            </div>
            <div className="chemical-list-wrapper">
              {items.map((item: any, key: number) => (
                <>
                  <div className="item">
                    <Link to={`${link}${item[itemKey]}`}>{item[itemKey]}</Link>
                  </div>
                </>
              ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ViewMoreModalWithLinks;
