import React from 'react';
import AdvanceSearchBreadcrumb from '../components/AdvanceSearchBreadcrumb';
import FeaturedText from '../components/FeaturedText';
import IconWrapper from '../components/IconWrapper';
import useAdvanceSearchContent from './useAdvanceSearchContent';

const AdvanceSearchContainer = (props: any) => {
  const advanceSearchProps = useAdvanceSearchContent();
  const { content, acf } = advanceSearchProps;
  return (
    <>
      <AdvanceSearchBreadcrumb />
      <FeaturedText content={content} />
      <IconWrapper acf={acf} />
    </>
  );
};
export default AdvanceSearchContainer;
