import {
  REQUEST_BLOG_LIST_SUCCESS,
  UPDATE_BLOG_LIST_PAGE_NUMBER,
  UPDATE_BLOG_LIST_SEARCH_VALUE,
  REQUEST_BLOG_LIST_ERROR,
} from '../actionType';

const blogReducer = (state: any, action: any) => {
  switch (action.type) {
    case REQUEST_BLOG_LIST_SUCCESS:
      return {
        ...state,
        blogs: action.payload.blog_posts,
        currentPage: parseInt(action.payload.current_page, 10),
        numPages: parseInt(action.payload.max_num_pages, 10),
      };
    case UPDATE_BLOG_LIST_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.payload,
      };
    case UPDATE_BLOG_LIST_SEARCH_VALUE:
      return {
        ...state,
        searchText: action.payload,
      };
    case REQUEST_BLOG_LIST_ERROR:
      return {
        ...state,
        blogs: [],
        currentPage: 1,
        numPages: 1,
      };
    default:
      return state;
  }
};

export default blogReducer;
