import React from 'react';
import Helmet from 'react-helmet';
import TooltipContent from '../containers/TooltipContent';
import FaqBreadcrumb from '../components/TooltipBreadcrumb';

const TooltipInfo = (props: any) => {
  return (
    <div>
      <Helmet>
        <title>PesticideInfo | Info</title>
        <meta name="description" content="Info" />
      </Helmet>
      <FaqBreadcrumb />
      <TooltipContent />
    </div>
  );
};
export default TooltipInfo;
