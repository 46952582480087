import React from 'react';
import MapImage from '../../../../src/assets/images/temp/usage-map.svg';

const MapCaption = (props: any) => {
  const { latestYear, chemicalName } = props;
  const checmicalName = chemicalName.toUpperCase();
  return (
    <div className="map-container">
      <div className="title-container">
        <h3>{`ESTIMATED AGRICULTURAL USE FOR ${chemicalName}, ${latestYear}`}</h3>
      </div>
      <div className="map-image-container">
        <a
          href={`https://water.usgs.gov/nawqa/pnsp/usage/maps/show_map.php?year=${latestYear}&map=${checmicalName}&hilo=L&disp=${chemicalName}`}
          target="_blank"
        >
          <img src={MapImage} alt=""></img>
          {/* <div className="year">{latestYear}</div> */}
          <div className="year">VIEW DATA</div>
        </a>
      </div>
    </div>
  );
};
export default MapCaption;
