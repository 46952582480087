import React, { ReactElement , useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import newsletterApi from '../../api/newsletterApi';
interface NewsLetterFormBlock {
  data: []
}


const NewsLetterForm = (props: any): ReactElement => {
  //const { data } = props;
  const pageUrl = window.location.href;

  const [data, setData] = useState({
    donate_button_label: '',
    donate_button_link: '',
    donate_text_content: ''        
  });
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://actionnetwork.org/widgets/v3/form/"+"join-pan"+"?format=js&source=widget"; //TODO get the form name from post attribute
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);
  useEffect(() => {
    const getNewsletter = async () => {
      try {
          
        const results = await newsletterApi.requestNewsletterForm();
        if (results.data) {
          setData(results.data);
        }
      } catch (e) {}
    };
    getNewsletter();
  }, []);


  return (
    <>
    <Container>
        <Row>
        <Col sm="12">
            <div className="blog-inner-form">
            <link href='https://actionnetwork.org/css/style-embed-whitelabel-v3.css' rel='stylesheet' type='text/css' />
            <script src='https://actionnetwork.org/widgets/v3/form/join-pan?format=js&source=widget'></script>
            <div id='can-form-area-join-pan'></div>
        </div>
        </Col>
        </Row>
      </Container>
    </>
  );
};
export default NewsLetterForm;
