import React from 'react';
import Helmet from 'react-helmet';
import UsgsmapDetails from '../containers/usgsmapDetails';

const UsgsMaps = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | USGS Maps</title>
        <meta name="description" content="USGS Maps" />
      </Helmet>
      <UsgsmapDetails />
    </>
  );
};
export default UsgsMaps;
