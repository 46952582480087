/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestTermsConditions = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=terms-conditions`);
};

const termsConditionsApi = {
  requestTermsConditions,
};

export default termsConditionsApi;
