import React from 'react';
import Helmet from 'react-helmet';
import AdvanceProductSearchLandingPersistContainer from '../containers/AdvanceProductSearchLandingPersistContainer';

const AdvanceSearchLandingPersist = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Advanced Product Search</title>
        <meta name="description" content="Advanced Product Search" />
      </Helmet>
      <AdvanceProductSearchLandingPersistContainer />
    </>
  );
};
export default AdvanceSearchLandingPersist;
