import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const GlobalBanMapBreadcrumb = (props: any) => {
  return (
    <div>
      <Breadcrumb className="detail-page" tag="nav" listTag="div">
        <BreadcrumbItem tag="a" className="active">
          Pesticide Maps
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" className="active">
          Global Pesticide Bans
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};
export default GlobalBanMapBreadcrumb;
