import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const KeyValueDataTableWithTwoColumnsWithLinks = (props: any): ReactElement => {
  const { tableHeading, tableData, link } = props;
  return (
    <>
      <div className="data-table two-columns">
        <div className="data-table-heading">{tableHeading}</div>
        <div className="data-table-content">
          {tableData.map((item: any, key: number) => (
            <div className="data-table-key-value" key={key}>
              <div>
                <Link to={`${link}${item.useType}`}>{item.useType}</Link>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="view-more">
          <a href="/">View More</a>
        </div> */}
      </div>
    </>
  );
};
export default KeyValueDataTableWithTwoColumnsWithLinks;
