/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import faqApi from '../../../api/faqApi';

const useFaqInner = () => {
  const [data, setData] = useState({
    title: {
      rendered: '',
    },
    acf: {
      question_answers: [],
      enable_donation: '',
      enable_newsletter_subscription: '',
    },
    content: '',
  });
  useEffect(() => {
    const getFaqInnerData = async () => {
      try {
        const results = await faqApi.requestFaqDetails();
        if (results.data.length > 0) {
          setData(results.data[0]);
        }
      } catch (e) {}
    };
    getFaqInnerData();
  }, []);

  return data && data;
};

export default useFaqInner;
