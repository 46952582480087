/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useState } from 'react';
import BlogBreadCrumb from '../components/BlogBreadcrumb';
import BlogSearch from '../components/BlogSearch';
import BlogPosts from '../components/BlogPosts';
import BlogPagination from '../components/BlogPagination';
import { BlogContext } from '../../../store/blog/context';
import blogApi from '../../../api/blogsApi';
//import Donation from '../../../components/common/Donation';
import {
  REQUEST_BLOG_LIST_SUCCESS,
  UPDATE_BLOG_LIST_PAGE_NUMBER,
  UPDATE_BLOG_LIST_SEARCH_VALUE,
  REQUEST_BLOG_LIST_ERROR,
} from '../../../store/blog/actionType';

const BlogListContainer = () => {
  const { dispatch, blogState } = useContext(BlogContext);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const getBlogData = async (page: number, search: string) => {
    try {
      setRequestDataComplete(false);
      const results = await blogApi.requestBlogPosts(page, search);
      dispatch({ type: REQUEST_BLOG_LIST_SUCCESS, payload: results.data });
      setRequestDataComplete(true);
      window.scrollTo(0, 0);
    } catch (e) {
      dispatch({ type: REQUEST_BLOG_LIST_ERROR });
      setRequestDataComplete(true);
    }
  };

  useEffect(() => {
    getBlogData(1, '');
  }, []);

  const pageClick = (e: any, pageNumber: number) => {
    e.preventDefault();
    dispatch({ type: UPDATE_BLOG_LIST_PAGE_NUMBER, payload: pageNumber });
    getBlogData(pageNumber, blogState.searchText);
  };

  const searchHandleSubmit = (e: any, searchValue: string) => {
    e.preventDefault();
    dispatch({ type: UPDATE_BLOG_LIST_SEARCH_VALUE, payload: searchValue });
    getBlogData(1, searchValue);
  };

  return (
    <>
      <BlogBreadCrumb />
      <BlogSearch
        searchText={blogState.searchText}
        searchHandleSubmit={searchHandleSubmit}
      />
      <BlogPosts
        blogList={blogState.blogs}
        requestDataComplete={requestDataComplete}
      />
      <BlogPagination
        currentPage={blogState.currentPage}
        numPages={blogState.numPages}
        pageClick={pageClick}
      />
      {/* This donation section only show when it's enable from the resources page */}
      {/* {enableDonation==="Yes" ? <Donation /> :''} */}
      {/* <Donation /> */}
    </>
  );
};
export default BlogListContainer;
