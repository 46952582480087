import {
  UPDATE_SIMPLE_SEARCH_VALUE,
  UPDATE_SIMPLE_SEARCH_NUMBER_OF_RESULSTS,
  UPDATE_SEARCH_RESULT_PAGE_NUMBER,
} from '../actionType';

const searchReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_SIMPLE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload.searchValue,
        searchType: action.payload.searchType,
        page: action.payload.page,
        sort: action.payload.sort,
        order: action.payload.order,
      };
    case UPDATE_SIMPLE_SEARCH_NUMBER_OF_RESULSTS:
      return {
        ...state,
        numberOfResults: action.payload.metaData.total,
      };
    case UPDATE_SEARCH_RESULT_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
    // case UPDATE_SIMPLE_SEARCH_TYPE:
    //   return {
    //     ...state,
    //     searchType: action.payload,
    //   };
    // case UPDATE_BLOG_LIST_PAGE_NUMBER:
    //   return {
    //     ...state,
    //     currentPage: action.payload,
    //   };
    // case UPDATE_BLOG_LIST_SEARCH_VALUE:
    //   return {
    //     ...state,
    //     searchText: action.payload,
    //   };
    // case REQUEST_BLOG_LIST_ERROR:
    //   return {
    //     ...state,
    //     blogs: [],
    //     currentPage: 1,
    //     numPages: 1,
    //   };
    default:
      return state;
  }
};

export default searchReducer;
