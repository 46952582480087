import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

const ProductPagination = (props: any) => {
  const {
    currentPage,
    numPages,
    pageClick,
    requestDataComplete,
    dataNotFound,
  } = props;

  const [activeRange, setActiveRange] = useState(0);
  const currentPageNumber = parseInt(currentPage , 10);
  const goToPreviousPage = (e:any, currentPageNumber:any) => {
    pageClick(e, currentPageNumber - 1);
    const reducedActiverange = activeRange - 1;
    const newrange  = reducedActiverange <= 0 ? 1 : reducedActiverange;
    setActiveRange(newrange - 1)
  }
  if (!dataNotFound && requestDataComplete) {
    return (
      <>
        {numPages && numPages > 1 ? (
          <Container>
            <Row>
              <Col sm={{ size: 10, offset: 1 }}>          
                {<Pagination>
                  <PaginationItem disabled={currentPageNumber <= 1}>
                    <PaginationLink
                      first
                      href="#"
                      onClick={(e) => {pageClick(e, 1); setActiveRange(0);}}
                    />
                  </PaginationItem>
                  <PaginationItem disabled={currentPageNumber === 1}>
                    <PaginationLink
                      previous
                      href="#"
                      onClick={(e) => goToPreviousPage(e, currentPageNumber)}                    
                    />
                  </PaginationItem>                
                  {[...Array(numPages - activeRange < 10 ? numPages - activeRange : 10)].map((page, i) => (
                    
                    <PaginationItem
                      active={i + activeRange === currentPageNumber - 1}
                      key={i}
                    >
                      <PaginationLink
                        href="#"
                        onClick={(e) => pageClick(e, i + activeRange + 1)}
                      >
                        {i + activeRange + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPageNumber >= numPages || numPages<10}>
                    <PaginationLink
                      next
                      href="#"
                      onClick={(e) => { pageClick(e, currentPageNumber  + 1); setActiveRange(activeRange + 1);  }}
                    />
                  </PaginationItem>
                  <PaginationItem disabled={currentPageNumber == numPages || numPages<10}>
                    <PaginationLink
                      last
                      href="#"
                      onClick={(e) => {pageClick(e, numPages); setActiveRange(numPages - (numPages%10));}}
                    />
                  </PaginationItem>
                </Pagination>}
              </Col>
            </Row>
          </Container>
        ) : (
            ''
          )}
      </>
    );
  }
  return <></>;
};
export default ProductPagination;
