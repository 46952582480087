/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

const InfoContainer = (props: any) => {
  const {
    ChemicalPropertyList,
    PestList,
    setPestList,
    setChemicalPropertyList,
    RegYearList,
    setRegYearList,
    searchButtonClick,
    buttonActive,
    setbuttonActive,
    productName,
    setProductName,
    companyName,
    setCompanyName,
    regNumber,
    setRegNumber,
    pestName,
    setPestName,
    comodity,
    setComodity,
    isSearchClicked,
    selectedValues,
    setSelectedValues,
  } = props;

  const procuctPestActiveStatus: any = [];
  const procuctInfoStatus: any = [];
  const procuctPropertyStatus: any = [];
  const [noActiveSelections, setNoActiveSelections] = useState(0);

  const applySearch = () => {
    if (isSearchClicked === true) {
      searchButtonClick();
    }
  };

  useEffect(() => {
    applySearch();
  }, [
    PestList,
    RegYearList,
    regNumber,
    companyName,
    comodity,
    pestName,
    productName,
    ChemicalPropertyList,
  ]);

  const removePestItem = (parentIndex: number, childIndex: number) => {
    const pestItems = [...PestList];
    const isSelected = pestItems[parentIndex].subItems[childIndex].selected;

    if (isSelected) {
      pestItems[parentIndex].noOfSelected -= 1;
    }

    pestItems[parentIndex].subItems[childIndex].selected = !isSelected;
    setPestList(pestItems);

    const newSelectedValues = selectedValues.filter((obj: any) => {
      return obj.name !== 'pestList';
    });
    setSelectedValues(newSelectedValues);
  };

  const removeRegYearItem = (parentIndex: number, childIndex: number) => {
    const regYearItems = [...RegYearList];
    const isSelected = regYearItems[parentIndex].subItems[childIndex].selected;

    if (isSelected) {
      regYearItems[parentIndex].noOfSelected -= 1;
    }
    regYearItems[parentIndex].subItems[childIndex].selected = !isSelected;
    setRegYearList(regYearItems);

    const newSelectedValues = selectedValues.filter((obj: any) => {
      return obj.name !== 'regYearList';
    });
    setSelectedValues(newSelectedValues);
  };

  const removeChemPropertyItem = (parentIndex: number, childIndex: number) => {
    const propertyItems = [...ChemicalPropertyList];
    const isSelected = propertyItems[parentIndex].subItems[childIndex].selected;

    if (isSelected) {
      propertyItems[parentIndex].noOfSelected -= 1;
    }
    propertyItems[parentIndex].subItems[childIndex].selected = !isSelected;
    setChemicalPropertyList(propertyItems);

    const newSelectedValues = selectedValues.filter((obj: any) => {
      return (
        obj.subItemsItemValue !==
        propertyItems[parentIndex].subItems[childIndex].itemValue
      );
    });
    setSelectedValues(newSelectedValues);
  };

  const getSelectedTags = () => {
    return selectedValues.map((item: any, key: number) => {
      if (item.name === 'pestName' && item.value !== '') {
        return (
          <li
            onClick={(e): void => {
              setPestName('');
              const newVal = selectedValues.filter((obj: any) => {
                return obj.name !== 'pestName';
              });
              setSelectedValues(newVal);
            }}
          >
            {item.value}
            <i className="close" />
          </li>
        );
      }
      if (item.name === 'comodity' && item.value !== '') {
        return (
          <li
            onClick={(e): void => {
              setComodity('');
              const newVal = selectedValues.filter((obj: any) => {
                return obj.name !== 'comodity';
              });
              setSelectedValues(newVal);
            }}
          >
            {item.value}
            <i className="close" />
          </li>
        );
      }
      if (item.name === 'pestList') {
        return (
          <li
            onClick={(e): void => {
              removePestItem(item.parentIndex, item.childIndex);
            }}
          >
            {item.subItemsItemLabel}
            <i className="close" />
          </li>
        );
      }
      if (item.name === 'productName' && item.value !== '') {
        return (
          <li
            onClick={(e): void => {
              setProductName('');
            }}
          >
            {item.value}
            <i className="close" />
          </li>
        );
      }
      if (item.name === 'companyName' && item.value !== '') {
        return (
          <li
            onClick={(e): void => {
              setCompanyName('');
            }}
          >
            {item.value}
            <i className="close" />
          </li>
        );
      }
      if (item.name === 'regNumber' && item.value !== '') {
        return (
          <li
            onClick={(e): void => {
              setRegNumber('');
            }}
          >
            {item.value}
            <i className="close" />
          </li>
        );
      }
      if (item.name === 'regYearList') {
        return (
          <li
            onClick={(e): void => {
              removeRegYearItem(item.parentIndex, item.childIndex);
            }}
          >
            {item.subItemsItemLabel}
            <i className="close" />
          </li>
        );
      }
      if (item.name === 'chemicalPropertyList') {
        return (
          <li
            onClick={(e): void => {
              removeChemPropertyItem(item.parentIndex, item.childIndex);
            }}
          >
            {item.subItemsItemLabel}
            <i className="close" />
          </li>
        );
      }
    });
  };

  return (
    <div className="filter-selections">
      <div className="selection-title-wrapper">
        <h6>YOUR SELECTIONS</h6>
      </div>

      <ul className="selected-items">{getSelectedTags()}</ul>
      <div className="button-holder">
        <a
          className={
            buttonActive ||
            productName !== '' ||
            companyName !== '' ||
            regNumber !== '' ||
            pestName !== '' ||
            comodity !== ''
              ? 'enable'
              : 'disable'
          }
          href="/"
          onClick={(e): void => {
            e.preventDefault();
            searchButtonClick();
          }}
        >
          <span>Search</span>
        </a>
      </div>
    </div>
  );
};
export default InfoContainer;
