import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useCaliforniaCropSite = () => {
  const [data, setData]: any = useState([]);

  useEffect(() => {
    const getCaliforniaCropSiteData = async () => {
      try {
        const results = await californiaSearchApi.requestCaliforniaCropSitesList();
        if (results.data) {
          let groupCount = 0;
          const chemList: any = [];
          results.data.data.cropsSites.map((cropsSite: any, index: number) => {
            if (cropsSite.key === '') {
              chemList.push({
                label: cropsSite.value,
                options: [],
              });
            }
          });
          results.data.data.cropsSites.map((cropsSite: any, index: number) => {
            if (cropsSite.key === '') {
              if (index > 1) {
                groupCount += 1;
              }
            } else {
              chemList[groupCount].options.push({
                label: cropsSite.value,
                value: cropsSite.key,
              });
            }
          });
          chemList.unshift({
            label: 'All Categories',
            options: [
              {
                label: 'All',
                value: 'all',
              },
            ],
          });
          setData([...chemList]);
        }
      } catch (e) {}
    };
    getCaliforniaCropSiteData();
  }, []);

  return data && data;
};

export default useCaliforniaCropSite;
