import React from 'react';
import { Container, Row, Breadcrumb, BreadcrumbItem, Col } from 'reactstrap';

const SearchResultBreadcrumb = (props: any) => {
  return (
    <Container>
      <Row>
        <Col sm="12">
          <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem tag="a" href="/">
              Search Chemicals or Products
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </Container>
  );
};
export default SearchResultBreadcrumb;
