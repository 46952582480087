
import React from 'react';
import CaTownshipmapContent  from '../components/CaTownshipmapContent';
import CaTownshipmapBreadCrumb from '../components/CaTownshipmapBreadCrump';
import useCaTownshipmapDetails from './useCaTownshipmapDetails';

const CaTownshipmapDetails = () => {
  const overviewProps =  useCaTownshipmapDetails();
  const { content,acf} = overviewProps;
  return (
    <>
     <CaTownshipmapBreadCrumb/>  
     <CaTownshipmapContent content={content} acf={acf}  />
    </>
  );
};
export default CaTownshipmapDetails


