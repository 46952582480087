/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestFaqDetails = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=faq`);
};

const faqApi = {
  requestFaqDetails,
};

export default faqApi;
