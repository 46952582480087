import React from 'react';
import Helmet from 'react-helmet';
import ShortcutDetailContainer from '../containers/ShortcutDetailContainer';

const CropDetailPage = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California Pesticide Use Search Results</title>
        <meta
          name="description"
          content="California Pesticide Use Search Results"
        />
      </Helmet>
      <ShortcutDetailContainer />
    </>
  );
};
export default CropDetailPage;
