import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import SearchResultBreadcrumb from '../components/SearchResultBreadcrumb';
import TitleWrapper from '../components/TitleWrapper';
import SearchResultTable from '../components/SearchResultTable';
import californiaSearchApi from '../../../api/californiaSearchApi';
import AdvSearchResultsDownload from '../../../components/common/AdvSearchResultsDownload';
const fileDownload = require('js-file-download');

const SearchResulContainer = (props: any) => {
  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };

  const query = useQuery();

  const title: string | null = query.get('title') ? query.get('title') : '';
  const chk: string | null = query.get('chk') ? query.get('chk') : '';
  const sk: string | null = query.get('sk') ? query.get('sk') : '';
  const cok: string | null = query.get('cok') ? query.get('cok') : '';
  const minyr: string | null = query.get('minyr') ? query.get('minyr') : null;
  const maxyr: string | null = query.get('maxyr') ? query.get('maxyr') : null;

  const history = useHistory();
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [data, setData] = useState({
    list: [],
    metaData: {
      total: '',
      latestYear: '',
      earliestYear: '',
    },
  });
  const [tableData, setTableData]: any = useState([]);
  const [minYear, setMinYear] = useState();
  const [maxYear, setMaxYear] = useState();
  const [earliestYear, setEarliestYear] = useState('');
  const [latestYear, setLatestYear] = useState('');

  const searchParams = {
    chk,
    sk,
    cok,
    minYear: minyr,
    maxYear: maxyr,
  };
  const getSearchData = async () => {
    try {
      setRequestDataComplete(false);
      setDataNotFound(false);
      const results = await californiaSearchApi.requestCaSearchResultsSpecificChemicalCropRegion(
        chk,
        sk,
        cok,
        minYear,
        maxYear,
      );
      setData(results.data.data);
      setRequestDataComplete(true);
      window.scrollTo(0, 0);
      history.push(
        `/california-search?title=${title}&chk=${chk}&sk=${sk}&cok=${cok}&minyr=${
        minYear || results.data.data.metaData.latestYear - 6
        }&maxyr=${maxYear || results.data.data.metaData.latestYear}`,
      );
    } catch (e) {
      if (e.response && e.response.status) {
        setDataNotFound(true);
      }
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);

  const onDateUpdate = () => {
    getSearchData();
  };

  /*  const [searchParams, setsearchParams] = useState({
    chk: chk,
    sk: sk,
    cok: cok,
    minYear: minYear,
    setMinYear: setMinYear,
    maxYear: maxYear,
  }); */

  const downloadSearchData = async (fileType: string) => {
    try {
      const results = await californiaSearchApi.downloadCaliforniaSearchApiResults(
        fileType,
        searchParams,
      );

      if (fileType == 'pdf') {
        fileDownload(results.data, 'california-pesticide-download-' + Date.now() + '.pdf');
      } else {
        fileDownload(results.data, 'california-pesticide-download-' + Date.now() + '.csv');
      }
    } catch (e) {
      if (e.response && e.response.status) {
      }
    }
  };

  const downloadlick = (fileType: string) => {
    downloadSearchData(fileType);
  };

  useEffect(() => {
    const list: any = [];
    const yearValuesSeparation = (arr: Array<String>) => {
      const temp: any = [];
      Object.entries(arr).map((item, index): void => {
        temp.push(item[1]);
      });
      temp.shift();
      return temp;
    };
    // if (data.metaData.latestYear) {
    //   setLatestYear(data.metaData.latestYear);
    // }
    Object.entries(data.list).map((item): void => {
      list.push({
        countyNamelabel: item[1][0]['countyNamelabel'],
        countyCode: item[1][0]['countyCode'],
        cropNameLabel: item[1][1]['cropNameLabel'],
        cropCode: item[1][1]['cropCode'],
        chemicalNamelabel: item[1][2]['chemicalNamelabel'],
        chemicalCode: item[1][2]['chemicalCode'],
        grossPounds: yearValuesSeparation(item[1][3]),
        // applCount: yearValuesSeparation(item[1][4]),
        // fieldCount: yearValuesSeparation(item[1][5]),

        acresPlanted: yearValuesSeparation(item[1][4]),
        acresTreated: yearValuesSeparation(item[1][5]),
        applRate: yearValuesSeparation(item[1][6]),
      });
    });
    setTableData([...list]);
  }, [data]);

  return (
    <>
      <SearchResultBreadcrumb />
      <TitleWrapper title={title} />
      <SearchResultTable
        requestDataComplete={requestDataComplete}
        dataNotFound={dataNotFound}
        tableData={tableData}
        minYear={minYear}
        setMinYear={setMinYear}
        maxYear={maxYear}
        setMaxYear={setMaxYear}
        earliestYear={earliestYear}
        latestYear={latestYear}
        onDateUpdate={onDateUpdate}
        data={data}
      />
      {requestDataComplete ? (
        <AdvSearchResultsDownload downloadlick={downloadlick} />
      ) : (
          ''
        )}
      <div className="m-5" />
    </>
  );
};
export default SearchResulContainer;
