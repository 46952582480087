import React from 'react';
import Header from './Header';
import FooterContainer from '../../containers/FooterContainer';

const Layout = (props: any) => {
  const { children } = props;
  return (
    <>
      <Header />
      <div>{children}</div>
      <FooterContainer />
    </>
  );
};

export default Layout;
