import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';
import ResourceInnerArticle from '../components/ResourceInnerArticle';
import ResourceAttachments from '../components/ResourceAttachments';
import ResourceRelatedPosts from '../components/ResourceRelatedPosts';
import ResourceInnerBreadcrumb from '../components/ResourceInnerBreadcrumb';
import useResourceInner from './useResourceInner';
import Donation from '../../../components/common/Donation';
import NewsLetterForm from '../../../components/common/NewsLetterForm';

const ResourceInnerDetails = () => {
  const resourceInnerProps = useResourceInner(
    window.location.pathname.split('resources/')[1],
  );

  const {
    title,
    featured_image,
    acf,
    content,
    relatedResourcePost,
    Category,
    enableDonation , 
    enableNewsletterSubscription,
  } = resourceInnerProps;
  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    const { hash } = window.location;
    const trimmedHash = hash.replace('#', '').toLowerCase();
      
      scroller.scrollTo(trimmedHash, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -100
      });
      
      
  });
  return (
    <>
      <ResourceInnerBreadcrumb title={title.rendered} />
      <ResourceInnerArticle
        title={title.rendered}
        bannerImage={featured_image}
        customDate={acf.custom_date}
        content={content.rendered}
        category={Category}
      />
      <ResourceAttachments fileUpload={acf.file_upload} />
      {relatedResourcePost.length > 0 && (
        <ResourceRelatedPosts relatedPosts={relatedResourcePost} />
      )}
       {enableDonation==="Yes" ? <Donation /> :''}
       {enableNewsletterSubscription === "Yes" ? <NewsLetterForm /> : ''}
    </>
  );
};
export default ResourceInnerDetails;
