/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import SeeMoreYears from './SeeMoreYears';

const SearchResultTable = (props: any) => {
  const {
    requestDataComplete,
    dataNotFound,
    tableData,
    minYear,
    setMinYear,
    maxYear,
    setMaxYear,
    earliestYear,
    latestYear,
    onDateUpdate,
    data,
  } = props;

  const [years, setYears]: any = useState([]);
  const [panelVisible, setPanelVisible]: any = useState(false);

   const refTop = useRef(null);
   const refBottom = useRef(null);

  useEffect(() => {
    if (data) {
      const ToYear =
        parseInt(maxYear, 0) || parseInt(data.metaData.latestYear, 0);
      const FromYear =
        parseInt(minYear, 0) || parseInt(data.metaData.latestYear, 0) - 6;
      const noOfYears = ToYear - FromYear + 1;
      const yearsTemp = [];
      for (let i = 0; i < noOfYears; i += 1) {
        yearsTemp.push(ToYear - i);
      }
      setYears(yearsTemp);
    }
  }, [data]);

  const togglePanel = () => {
    setPanelVisible(!panelVisible);
  };

  const scrollDiv = (ref: any, movedLength: number) => {
    if (ref !== undefined) {
      ref.current.scrollLeft = movedLength;
    }
  };

  const ScrollDivBottom = (e: any) => {
    let element = e.target;
    scrollDiv(refBottom, element.scrollLeft);
  };

  const ScrollDivTop = (e: any) => {
    let element = e.target;
    scrollDiv(refTop, element.scrollLeft);
  };

  if (requestDataComplete) {
    return (

      <section className="ca-search-result-table">
        <Container>
          <Row>
            <Col sm="12">
              {/* <pre>{JSON.stringify(years)}</pre> */}
              <div className="table-wrapper">
                <div className="description">
                  <div className="title">Description</div>
                  {tableData.map((item: any, index: number) => (
                    <div key={`description-${index}`}>
                      <div>
                        {/* <pre>{JSON.stringify(item)}</pre> */}
                        <Link
                          to={`/california-pesticide-use/california-county-detail?cok=${item.countyCode}`}
                          title={item.countyNamelabel}
                        >
                          {item.countyNamelabel}
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={`/california-pesticide-use/california-crop-detail?sk=${item.cropCode}`}
                          title={item.cropNameLabel}
                        >
                          {item.cropNameLabel}
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={`/california-pesticide-use/california-chemical-detail?id=${item.chemicalCode}`}
                          title={item.chemicalNamelabel}
                        >
                          {item.chemicalNamelabel}
                        </Link>
                      </div>
                      <div>Gross Pounds</div>
                      {/* <div>Appl Count</div>
                      <div>Field Count</div> */}
                      <div>Acres Planted</div>
                      <div>Acres Treated</div>
                      <div>Appl Rate (lbs a.i./acre)</div>
                    </div>
                  ))}
                </div>

                <div className="years additional-scroll" ref={refTop} onScroll={ScrollDivBottom}>
                  <div className="empty-scrollable" style={{ width: `${years.length * 100}px`}}>

                  </div>
               </div>
                <div className="years" ref={refBottom} onScroll={ScrollDivTop}>

                  <div>
                    <div>
                      {years.map((item: any, index: number) => (
                        <div key={index}>{item}</div>
                      ))}
                    </div>
                  </div>
                  {tableData.map((item: any, index: number) => (
                    <div
                      key={`list - ${index}`}
                      style={{ width: `${years.length * 100}px` }}
                    >
                      <div className="gross-pounds">
                        {item.grossPounds.map(
                          (grossPound: any, grossPoundIndex: number) => (
                            <div key={`${index}-${grossPoundIndex}-gp`}>
                              {grossPound !== null
                                ? grossPound
                                : '-'}
                            </div>
                          ),
                        )}
                      </div>
                      {/* <div className="apple-count">
                        {item.applCount.map(
                          (applCount: any, applCountIndex: number) => (
                            <div key={`${index}-${applCountIndex}-ap`}>
                              {applCount !== null ? applCount.toFixed(2) : '-'}
                            </div>
                          ),
                        )}
                      </div> */}
                      {/* <div className="field-count">
                        {item.fieldCount.map(
                          (fieldCount: any, fieldCountIndex: number) => (
                            <div key={`${index}-${fieldCountIndex}-fc`}>
                              {fieldCount !== null
                                ? fieldCount.toFixed(2)
                                : '-'}
                            </div>
                          ),
                        )}
                      </div> */}
                      <div className="arces-planted">
                        {item.acresPlanted.map(
                          (acresPlanted: any, acresPlantedIndex: number) => (
                            <div key={`${index}-${acresPlantedIndex}-apd`}>
                              {acresPlanted !== null
                                ? acresPlanted
                                : '-'}
                            </div>
                          ),
                        )}
                      </div>
                      <div className="arces-treated">
                        {item.acresTreated.map(
                          (acresTreated: any, acresTreatedIndex: number) => (
                            <div key={`${index}-${acresTreatedIndex}-at`}>
                              {acresTreated !== null
                                ? acresTreated
                                : '-'}
                            </div>
                          ),
                        )}
                      </div>
                      <div className="apple-rate">
                        {item.applRate.map(
                          (applRate: any, applRateIndex: number) => (
                            <div key={`${index}-${applRateIndex}-ar`}>
                              {applRate !== null ? applRate: '-'}
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="more-years">
                  <div onClick={togglePanel}>See More Years</div>
                  <div>
                    <SeeMoreYears
                      minYear={minYear}
                      setMinYear={setMinYear}
                      maxYear={maxYear}
                      setMaxYear={setMaxYear}
                      earliestYear={parseInt(data.metaData.earliestYear, 0)}
                      latestYear={parseInt(data.metaData.latestYear, 0)}
                      onDateUpdate={onDateUpdate}
                      panelVisible={panelVisible}
                      setPanelVisible={setPanelVisible}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
  if (dataNotFound) {
    return (
      <Container className="search-result-table">
        <Row>
          <Col sm="12" className="no-result-found">
            <h3>No Search Results Found</h3>
            <p>
              {' '}
              Not all pesticides are used on all crops in all counties. Please
              try your search again
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <Col sm="12" className="text-center">
          <div className="loader" />
        </Col>
      </Row>
    </Container>
  );
};
export default SearchResultTable;
