import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const BlogBreadcrumb = (props: any) => {
 
  return (
    <section>
      <Container>
        <Row>
          <Col sm="12">
            <Breadcrumb tag="nav" listTag="div">
              <BreadcrumbItem tag="a" className="disable">
                Alternatives & Resources
              </BreadcrumbItem>
              <BreadcrumbItem tag="a" className="active">
                Blog
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default BlogBreadcrumb;
