import { useEffect, useState } from 'react';
import privacyPolicyApi from '../../../api/privacyPolicyApi';

const usePrivacyPolicy = () => {
 
const [data, setData] = useState({
  content: '',
  acf: '',
});

useEffect(() => {
  const getPrivacyPolicyData = async () => {
    try {
      const results = await privacyPolicyApi.requestPrivacyPolicyDetails();

      if (results.data.length > 0) {
        setData(results.data[0]);
       
      }
      
    } catch (e) {
      
    }
  };
  getPrivacyPolicyData();
}, []);

return data && data;
};


export default usePrivacyPolicy;


