import React from 'react';
import useEcotoxicityTab from './useEcotoxicityTab';
import KeyValueDataTable from '../../../components/common/KeyValueDataTable';
import KeyValueDataTableOneColumn from '../../../components/common/KeyValueDataTableOneColumn';
import EcotoxicityEnvironmental from '../components/EcotoxicityEnvironmental';

const EcotoxicityTab = (props: any) => {
  const chemicalId = window.location.pathname.split('chemical/')[1];
  const values = useEcotoxicityTab(chemicalId);
  const {
    waterContaminationPotential,
    usNationalDrinkingWaterStandards,
    environmentalToxicity,
    // whoWaterQuality,
  } = values;
  return (
    <>
      <EcotoxicityEnvironmental
        data={[
          {
            value: 'description comes here',
          },
        ]}
      />
      {/* <KeyValueDataTableOneColumn
        tableHeading="ENVIRONMENTAL TOXICITY"
        tableData={environmentalToxicity}
        linkData
      /> */}

      <KeyValueDataTable
        tableHeading="WATER CONTAMINATION POTENTIAL"
        tableData={waterContaminationPotential}
      />

      <KeyValueDataTable
        tableHeading="U.S. NATIONAL DRINKING WATER STANDARDS"
        tableData={usNationalDrinkingWaterStandards}
      />

      {/* <KeyValueDataTableOneColumn
        tableHeading="WHO WATER QUALITY"
        tableData={whoWaterQuality}
      /> */}
    </>
  );
};
export default EcotoxicityTab;
