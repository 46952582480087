import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const GlobalBanMapLogoItems = (props: any) => {
  const { logoItems, logHeading } = props;
  return (
    <div className="logo-items">
      <div className="presented-by">
        <h3>PRESENTED BY:</h3>
        <ul>
          {logoItems.map((item: any, key: number) => (
              item.itemLogoImage.includes('pan-logo.svg')?
              <li key={key}>
                <a href={item.itemLogoUrl} target="_blank">
                  <img src={item.itemLogoImage} alt="" />
                </a>
              </li>
              :
              ''
          ))}
        </ul>
      </div> 
      <div className="map-data-courtesy">
        <h3>{logHeading}:</h3>
        <ul>
          {logoItems.map((item: any, key: number) => (
            !item.itemLogoImage.includes('pan-logo.svg')?
            <li key={key}>
              <a href={item.itemLogoUrl} target="_blank">
                <img src={item.itemLogoImage} alt="" />
              </a>
            </li>
            :
            ''
          ))}
        </ul>
      </div>            
    </div>
  );
};
export default GlobalBanMapLogoItems;
