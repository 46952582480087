import React from 'react';
import Helmet from 'react-helmet';
import OverviewContent from '../containers/OverviewContent';

const BlogInner = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | About</title>
        <meta name="description" content="About" />
      </Helmet>
      <OverviewContent />
    </>
  );
};
export default BlogInner;
