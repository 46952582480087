/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import californiaSearchApi from '../../../api/californiaSearchApi';

const useOverviewTabContent = (cok: string) => {
  const [data, setData] = useState({
    data: {
      list: {
        tab: '',
        regionName: '',
        countyName: '',
        countyDetails: {
          countyAddress: '',
          countyPhone: '',
          cdfaWebsite: '',
        },
      },
    },
  });

  useEffect(() => {
    const getCountyOverviewData = async () => {
      try {
        const results = await californiaSearchApi.requestCountyOverviewContent(
          cok,
        );
        setData(results.data);
      } catch (e) {}
    };
    getCountyOverviewData();
  }, []);

  return data && data;
};

export default useOverviewTabContent;
