/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import overviewApi from '../../../api/overviewApi';
import { scroller } from 'react-scroll';

const ContactForm = () => {
  const [formData, setFormData] = useState({name: '', subject: '', email: '', message: '', status: ''})
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState('')

  const updateInput = (e: any)  => {
     const {name, value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })
        setMessage('');
  }

  const handleSubmit = async (event: any) => {
     event.preventDefault();
     try {
      const results = await overviewApi.sendContactEmail(
        formData.name,
        formData.email,
        formData.subject,
        formData.message,
      );

     setStatus(results.data.data);
     setMessage(results.data.message);
     setFormData({ name: '', subject: '', email: '', message: '', status: '' });
    } catch (e) {}
    };

    useEffect(()=>{
      const { hash } = window.location;
      const trimmedHash = hash.replace('#', '').toLowerCase();
        
        scroller.scrollTo(trimmedHash, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -100
        });                
    });

  //console.log(JSON.stringify(formData));
  return (
    <>
      <div className="contactform-content" id="contact-us">
        <div className="row">
          <div className="col-sm-4 contactform">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                  name="name"
                  value={formData.name}
                placeholder="Name"
                  onChange={updateInput}
                required
                />
                </div>
              <div className="form-group">
              <input
                type="email"
                  name="email"
                  value={formData.email}
                className="form-control"
                placeholder="Email"
                onChange={updateInput} required
                />
              </div>

              <div className="form-group">
              <input
                type="text"
                  name="subject"
                  value={formData.subject}
                className="form-control"
                placeholder="Subject"
                onChange={updateInput}  required
                />
              </div>
              <div className="form-group">
              <textarea
                  name="message"
                  value={formData.message}
                className="form-control"
                placeholder="Message"
                onChange={updateInput}  required></textarea>
              </div>
              <button type="submit" className="">Submit</button>
              <div className="alert alert-success" role="alert">
               {message}
              </div>
                </form>
            </div>
          </div>
        </div>
    </>
  )
}

export default ContactForm
