import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col } from 'reactstrap';

const Navigation = (props: any) => {
  const { navItems } = props;
  const [isToggled, setToggled] = useState(false);

  const toggleTrueFalse = () => {
    setToggled(!isToggled);
  };

  return (
    <Col sm="9" xs="6" className="ml-auto">
      <button
        className="d-xl-none header-menu-toggle"
        onClick={toggleTrueFalse}
        type="button"
      >
        Menu
      </button>
      <ul className={`header-links ${isToggled ? `show-menu` : ``}`}>
        {navItems.map((item: any, key: number) => (
          <li key={key}>
            <NavLink
              activeClassName="link-parent-active"
              className={`${item.submenu.length > 0 ? 'link-disable' : ''}`}
              to={item.url}
              isActive={(match, location) => {
                if (
                  (location.pathname === '/blog' ||
                    location.pathname === '/resources') &&
                  item.url === '/alternative-resources'
                ) {
                  return true;
                }
                if (!match) {
                  return false;
                }
                return true;
              }}
              onClick={toggleTrueFalse}
            >
              {item.label}
            </NavLink>
            {item.submenu.length > 0 && (
              <ul>
                {item.submenu.map((subitem: any, key: number) => (
                  <li key={key}>
                    <NavLink
                      activeClassName="link-sub-active"
                      to={subitem.url}
                      onClick={toggleTrueFalse}
                    >
                      {subitem.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default Navigation;
