/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import productApi from '../../../../api/productApi';

const UseProductOverview = (regno: string, distno: string) => {
  /*  const [data, setData] = useState({
    summary: [],
    manufacturer: [],
    distributor: [],
    otherNames: { metaData: { total: '' }, otherNames: [] }
  }); */
  const [data, setData] = useState({
    data: {
      productName: '',
      badActor: '',
      HHP: '',
      tab: '',
      data: {
        summary: [],
        manufacturer: [],
        distributor: [],
        otherNames: {
          metaData: { total: '' },
          otherNames: [],
        },
      },
    },
  });
  useEffect(() => {
    const getProductOverviewData = async () => {
      try {
        const results = await productApi.requestProductOverviewContent(
          regno,
          distno,
        );

        setData(results.data);
      } catch (e) {}
    };
    getProductOverviewData();
  }, []);

  return data && data;
};

export default UseProductOverview;
