/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from 'react';
import chemicalApi from '../../../api/chemicalApi';

const useUsageOtherNamesContent = (chemicalId: string) => {
  const [data, setData] = useState({
    data: {
      metaData: { totalchemicals: '' },
      relatedChemicals: [],
    },
  });

  useEffect(() => {
    const getUsageOtherData = async () => {
      try {
        const results = await chemicalApi.requestRelatedChemicals(chemicalId);
        setData(results.data);
      } catch (e) {}
    };
    getUsageOtherData();
  }, []);

  return data && data;
};

export default useUsageOtherNamesContent;
