import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const FeaturedText = (props: any) => {
  const { acf } = props;
  if (acf) {
    return (
      <section className="icon-wrapper">
        <Container>
          <Row className="justify-content-center">
            <Col sm="12">
              <div className="icon-holder">
                <div className="icon-button-wrap">
                  <a href="/advance-search/chemical" rel="noopener noreferrer">
                    <img src={acf.chemicals_icon} alt="" />{' '}
                  </a>
                  <a
                    className="button"
                    href="/advance-search/chemical"
                    rel="noopener noreferrer"
                  >
                    <span>{acf.chemicals_button_label}</span>
                  </a>
                </div>
                <span>Or</span>
                <div className="icon-button-wrap">
                  <a href="/advance-search/product" rel="noopener noreferrer">
                    <img src={acf.products_icon} alt="" />
                  </a>
                  <a
                    className="button"
                    href="/advance-search/product"
                    rel="noopener noreferrer"
                  >
                    <span>{acf.products_button_label}</span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
  return <></>;
};
export default FeaturedText;
