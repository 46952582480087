import { useEffect, useState } from 'react';
import homeApi from '../api/homeApi';

const useHeader = () => {
  // const { query } = props;
  const [data, setData] = useState({
    headerMainLogo: '',
    headerTopCaption: '',
    headerTopLogo: '',
    enable_popup: '',
    popup_title: '',
    popup_content: '',
    popup_publish_date: '',
  });

  useEffect(() => {
    const getHeaderData = async () => {
      try {
        const results = await homeApi.requestHeader();
        setData(results.data.acf);
      } catch (e) {}
    };
    getHeaderData();
  }, []);

  return data && data;
};

export default useHeader;
