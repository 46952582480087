import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const BlogInnerBreadcrumb = (props: any) => {
  const { title } = props;
  return (
    <Breadcrumb tag="nav" listTag="div">
      <BreadcrumbItem className="disable" tag="a">
        Alternatives & Resources
      </BreadcrumbItem>
      <BreadcrumbItem tag="a" href="/blog">
        Blog
      </BreadcrumbItem>
      <BreadcrumbItem className="active" tag="a">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
export default BlogInnerBreadcrumb;
