import React, { useEffect, useState } from 'react';
import UseInfoContent from './UseInfoContainer';
import ProductFilter from '../components/ProductFilter';

const InfoContainer = (props: any) => {
  const yearinfo = UseInfoContent();
  const {
    RegYearList,
    setRegYearList,
    setbuttonActive,
    setProductName,
    setCompanyName,
    setRegNumber,
    productName,
    companyName,
    regNumber,
    setIsSearchClicked,
    selectedValues,
    setSelectedValues,
  } = props;
  const [regYearProps, setregYearProps] = useState();
  const [noOfYearListSelected, setnoOfYearListSelected] = useState(0);

  useEffect(() => {
    setregYearProps(yearinfo);
  }, []);
  return (
    <>
      <ProductFilter
        setRegYearList={setRegYearList}
        RegYearList={RegYearList}
        noOfYearListSelected={noOfYearListSelected}
        setnoOfYearListSelected={setnoOfYearListSelected}
        data={yearinfo}
        setbuttonActive={setbuttonActive}
        setProductName={setProductName}
        setCompanyName={setCompanyName}
        setRegNumber={setRegNumber}
        productName={productName}
        companyName={companyName}
        regNumber={regNumber}
        setIsSearchClicked={setIsSearchClicked}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
      />
    </>
  );
};
export default InfoContainer;
