/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestCaTownshipMapContent = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/pages?slug=ca-township-maps`);
};

const overviewApi = {
  requestCaTownshipMapContent ,
};

export default overviewApi;