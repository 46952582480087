import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import toolTipApi from '../../../api/tooltipApi';

const ProductDetailTitle = (props: any) => {
  const { productName, badActor } = props;

  const [toolTip, setToolTip] = useState({
    short_description: '',
    link: '',
  });

  const [badActorTooltipOpen, setBadActorTooltipOpen] = useState(false);

  const loadCallout = async (name: string) => {
    let results;
    // setBadActorTooltipOpen(false)
    try {
      results = await toolTipApi.requestTooltip('product', name);
    } catch (e) {}
    if (results) {
      setToolTip({
        short_description: results.data.data[0].short_description,
        link: results.data.data[0].link,
      });
      //  setBadActorTooltipOpen(true)
    }
  };

  const toggle = () => {
    setBadActorTooltipOpen(!badActorTooltipOpen);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className="title-container">
      <h2>
        {productName}
        <div className="logo-wrapper">
          {badActor === 'Yes' ? (
            <div className="tooltip-holder">
              <span
                id="badActor"
                onMouseEnter={() => loadCallout('BadActor')}
              />
              <Tooltip
                placement="bottom"
                isOpen={badActorTooltipOpen}
                target="badActor"
                toggle={toggle}
                autohide={false}
              >
                {toolTip.short_description ? (
                  <>
                    {toolTip.short_description}
                    <a href={toolTip.link} target="_blank">
                      &nbsp;Read More
                    </a>
                  </>
                ) : (
                  <div className="loader-small" />
                )}
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
          {/* <div className="tooltip-holder ">
            <span id="highHazardous"></span>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="highHazardous"
              toggle={toggle}
            >
              Tooltip 2
            </Tooltip>
          </div> */}
        </div>
      </h2>
    </div>
  );
};
export default ProductDetailTitle;
