/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const ChemicalResultTablePlaceholder = (props: any) => {
  const { isSorting } = props;
  const tableRows = new Array(25);
  for (let i = 0, len = tableRows.length; i < len; i += 1) {
    tableRows[i] = {
      html: ``,
    };
  }
  if (isSorting) {
    return (
      <Container className="search-result-table">
        <Row>
          <Col sm="12" style={{ padding: 0 }}>
            <div className="result-table">
              <div className="result-table-row titles">
                <div>
                  <div>Chemical Name</div>
                  <div>EPA REGISTERED</div>
                  <div>PAN BAD ACTOR</div>
                  <div>HIGHLY HAZARDOUS PESTICIDE</div>
                  <div>EU REGISTERED</div>
                </div>
              </div>
              {tableRows.map((item: any, key: number) => (
                <div className="result-table-row" key={key}>
                  <div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  return <></>;
};
export default ChemicalResultTablePlaceholder;
