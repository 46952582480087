/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const AdvSearchResultsDownload = (props: any) => {
  const { downloadlick } = props;
  return (
    <Container>
      <Row>
        <Col sm="12">
          <div className="search-result-download ca-search-download">
            <span>
              DOWNLOAD RESULTS
              <ul>
                <li>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      downloadlick('csv');
                    }}
                  >
                    CSV
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      downloadlick('pdf');
                    }}
                  >
                    PDF
                  </a>
                </li>
              </ul>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default AdvSearchResultsDownload;
