import React, { ReactElement, useEffect } from 'react';
import { Container,Row, Col } from 'reactstrap';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

interface BlogInnerArticleBlock {
  title: string;
  bannerImage: string;
  customDate: string;
  content: string;
  description: string;
  formName: string;
  yoast_meta: any;
}

const BlogInnerArticle = (props: BlogInnerArticleBlock): ReactElement => {
  const { title, bannerImage, customDate, content, description, formName, yoast_meta } = props;
  const pageUrl = window.location.href;
  let facebook = '';
  let twitter_title = '';
  yoast_meta.map((yoast_data: { name: any; property:any, content: any; }) => {
    
    if(yoast_data.property==='og:title'){ facebook = yoast_data.content }
    //twitter:title // twitter:description 
    if(yoast_data.name === 'twitter:title'){ twitter_title = yoast_data.content }
});
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://actionnetwork.org/widgets/v3/form/"+"join-pan"+"?format=js&source=widget"; //TODO get the form name from post attribute
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
  <>    
      <Row className="common-inner-header blog-inner-header">
        <Col sm="12" xl="12" className="blog-content">
          <div className="blog-description">
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div className="blog-inner-date">
              <p>{customDate}</p>
            </div>
          </div>
        </Col>
        <Col sm="12" xl={{ size: 10, offset: 1 }} className="blog-image">
          <figure>
            <img src={bannerImage} alt="" className="img-fluid" />
          </figure>
          <div className="d-flex blog-inner-social-media">
            <ul>
              <li>
                <EmailShareButton children="" url={pageUrl} />
              </li>
              <li>
                <FacebookShareButton children="" url={pageUrl} />
              </li>
              <li>
                {
                  twitter_title != '' ? 
                    <TwitterShareButton children="" url={pageUrl} title={twitter_title} /> 
                    : 
                    <TwitterShareButton children="" url={pageUrl} title={title} />
                }                
              </li>
              <li>{/* <LinkedinShareButton children="" url={pageUrl} /> */}</li>
            </ul>
          </div>
        </Col>
        {/* <Col sm="12 d-flex">
        </Col> */}
      </Row>
      <Row className="blog-inner-header">
        <Col sm="12">
          <div
            className="detail-page-content"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div
            className="text-block detail-page-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Col>
      </Row>
    </>
  );
};
export default BlogInnerArticle;
