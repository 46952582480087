import React from 'react';
import useChemicalHealthContent from './useHealthTab';
import KeyValueDataTable from '../../../components/common/KeyValueDataTable';
import TableCardSymptoms from '../components/TableCardSymptoms';

const HealthTab = (props: any) => {
  const chemicalId = window.location.pathname.split('chemical/')[1];
  const { chemName } = props;
  const values = useChemicalHealthContent(chemicalId);
  const {
    acuteToxity,
    reproductiveAndDevelopmentalToxicity,
    cancerInformation,
    endocrineDisruption,
    Poisioning,
  } = values;
  return (
    <>
      <KeyValueDataTable
        tableHeading={`TOXICITY of ${chemName}`}
        tableData={acuteToxity}
      />
      <KeyValueDataTable
        tableHeading="REPRODUCTIVE AND DEVELOPMENTAL TOXICITY"
        tableData={reproductiveAndDevelopmentalToxicity}
      />
      <KeyValueDataTable
        tableHeading="CANCER INFORMATION"
        tableData={cancerInformation}
      />
      <KeyValueDataTable
        tableHeading="ENDOCRINE DISRUPTION"
        tableData={endocrineDisruption}
      />
      <TableCardSymptoms tableData={Poisioning} chemName={chemName} />
    </>
  );
};
export default HealthTab;
