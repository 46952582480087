import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import SideBarContainer from '../../../containers/SideBarContainer';
import CountyDetailBreadcrumb from '../components/ShortcutDetailBreadcrumb';
import TopPesticides from './TopPesticides';
import TopCounties from './TopCounties';
import TopCrops from './TopCrops';
import useTopPesticides from './useTopPesticides';
import useTopCropsAndSites from './useTopCropsAndSites';
import useTopCounties from './useTopCounties';

const CropDetailContainer = (props: any) => {
  const topPesticideTabData = useTopPesticides();
  const topCropsAndSitesTabData = useTopCropsAndSites();
  const topCountiesTabData = useTopCounties();

  //const topPesticidedata = topPesticideProps;
  //const { data } = topCropsAndSites;

  const [topPesticidedata, settopPesticidedata]: any = useState({
    data: {
      data: [],
      metaData: {
        total: '',
      },
    },
  });

  const [topCropsAndSitesdata, settopCropsAndSitesdata]: any = useState({
    data: {
      data: [],
      metaData: {
        total: '',
      },
    },
  });

  const [topCountiesdata, settopCountiesdata]: any = useState({
    data: {
      data: [],
      metaData: {
        total: '',
      },
    },
  });

  useEffect(() => {
    settopPesticidedata(topPesticideTabData);
  }, [topPesticideTabData]);

  useEffect(() => {
    settopCropsAndSitesdata(topCropsAndSitesTabData);
  }, [topCropsAndSitesTabData]);

  useEffect(() => {
    settopCountiesdata(topCountiesTabData);
  }, [topCountiesTabData]);

  const toPesticideFull = topPesticidedata.data.data;
  const topPesticideDataLimit = topPesticidedata.data.data.slice(0, 25);
  const topPesticideTotal = topPesticidedata.data.metaData.total;

  const topCropsandSitesFull = topCropsAndSitesdata.data.data;
  const topCropsandSitesDataLimit = topCropsAndSitesdata.data.data.slice(0, 25);
  const topCropsAndSitesdataTotal = topCropsAndSitesdata.data.metaData.total;

  const topCountiesFull = topCountiesdata.data.data;
  const topCountiesDataLimit = topCountiesdata.data.data.slice(0, 25);
  const topCountiesDataTotal = topCountiesdata.data.metaData.total;

  const [activeTab, setActiveTab] = useState('1');
  const tabtoggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();

  const tab: string | null = query.get('tab') ? query.get('tab') : '';
  useEffect(() => {
    setActiveTab(tab!);
  }, [tab]);

  return (
    <>
      <CountyDetailBreadcrumb />
      <section className="tab-main-content california-tab-content shortcut-detail-content">
        <Container>
          <Row>
            <Col lg="9">
              <div className="inner-upper-container">
                <div className="title-container">
                  <h2>Top Pesticide Usage</h2>
                  <span>{`(All California, ${
                    topPesticidedata.data.metaData.year
                      ? topPesticidedata.data.metaData.year
                      : ''
                  })`}</span>
                </div>
              </div>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('1');
                      }}
                    >
                      Top 50 Pesticides
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('2');
                      }}
                    >
                      Top 50 Crops & Sites
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        tabtoggle('3');
                      }}
                    >
                      All Pesticide Use By County
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane className="overview" tabId="1">
                    <TopPesticides
                      toPesticideFull={toPesticideFull ? toPesticideFull : ''}
                      topPesticideData={
                        topPesticideDataLimit ? topPesticideDataLimit : ''
                      }
                      total={topPesticideTotal ? topPesticideTotal : ''}
                    />
                  </TabPane>
                  <TabPane className="top-pesticides" tabId="2">
                    <TopCrops
                      topCropsandSitesFull={
                        topCropsandSitesFull ? topCropsandSitesFull : ''
                      }
                      topCropsandSitesDataLimit={
                        topCropsandSitesDataLimit
                          ? topCropsandSitesDataLimit
                          : ''
                      }
                      total={
                        topCropsAndSitesdataTotal
                          ? topCropsAndSitesdataTotal
                          : ''
                      }
                    />
                  </TabPane>
                  <TabPane className="top-crops-sites" tabId="3">
                    <TopCounties
                      topCountiesFull={topCountiesFull ? topCountiesFull : ''}
                      topCountiesData={
                        topCountiesDataLimit ? topCountiesDataLimit : ''
                      }
                      total={topCountiesDataTotal ? topCountiesDataTotal : ''}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col lg="3">
              <SideBarContainer />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default CropDetailContainer;
