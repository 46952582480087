import ReactGA from 'react-ga';
import config from '../config';

const TRACKING_ID = `${config.googleAnalytic.GA_TRACK_ID}`;

function init() {
  ReactGA.initialize(TRACKING_ID, { debug: false });
}

function sendEvent(payload: any) {
  ReactGA.event(payload);
}

function sendPageview(path: any) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
};
