
import React from 'react';
import UsgsContent  from '../components/usgsContent';
import UsgsmapBreadCrumb from '../components/UsgsmapBreadCrump';
import useUsgsmapDetails from './useUsgsmapDetails';

const UsgsmapDetails = () => {
  const overviewProps =  useUsgsmapDetails();
  const { content,acf} = overviewProps;
  return (
    <>
     <UsgsmapBreadCrumb/>  
     <UsgsContent content={content} acf={acf}  />
    </>
  );
};
export default UsgsmapDetails 


