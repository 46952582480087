import React, { useEffect, useState } from 'react';
import TableViewMore from '../../../components/common/TableViewMore';

const TableCaliforniaData = (props: any) => {
  const { californiaDetails, tableHeading, total } = props;
  const [californiaTable, setCaliforniaTable] = useState([]);
  useEffect(() => {
    if (californiaDetails) {
      setCaliforniaTable(californiaDetails.slice(0, 8));
    }
  }, [californiaDetails]);
  const tableTitles = [
    {
      label: 'Crop or Site',
      field: 'Commodity_Name',
    },
    {
      label: 'Gross Pounds',
      field: 'grossPounds',
    },
    {
      label: 'Application Rate',
      field: 'applicationRate',
    },
    {
      label: 'Acres Planted',
      field: 'acresPlanted',
    },
    {
      label: 'Acres Treated',
      field: 'acresTreated',
    },
    {
      label: 'Application Count',
      field: 'applicationCount',
    },
  ];
  const tableItemLink = {
    linkedTo: '/california-pesticide-use/california-crop-detail?sk=',
    field: 'site_code',
  };

  const decimalFormat = (val: any, decimalCount: number) => {
    let decimalValue = Number(val) > 1000 ? 0 : 1;
    decimalValue = decimalCount === 1 ? decimalValue : decimalCount;
    const nl = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalValue,
      maximumFractionDigits: decimalValue
    });

    return nl.format(val);
  }

  return (
    <>
      <div className="search-result-table top-fifty-crops">
        <div className="title-container">
          <h3>{tableHeading}</h3>
          <span>
            {total > 0 ? `(${total} ${total > 1 ? 'Results' : 'Result'})` : ''}
          </span>
        </div>
        <div className="result-table">
          <div className="result-table-row titles">
            <div>
              <div>Crop or Site</div>
              <div>Gross Pounds</div>
              <div>Application Rate (lbs a.i./acre)</div>
              <div>Acres Planted</div>
              <div>Acres Treated</div>
              <div>
                Application<br></br> Count
              </div>
            </div>
          </div>
          {californiaTable.length > 0 ? (
            californiaTable.map((item: any, index: number) => (
              <div className="result-table-row" key={index}>
                <a
                  href={`/california-pesticide-use/california-crop-detail?sk=${item.site_code}`}
                >
                  <div>
                    <h3>{item.Commodity_Name}</h3>
                  </div>
                  <div>{decimalFormat(item.grossPounds,1)}</div>
                  <div>{decimalFormat(item.applicationRate,2)}</div>
                  <div>{decimalFormat(item.acresPlanted,1)}</div>
                  <div>{decimalFormat(item.acresTreated,1)}</div>
                  <div>{decimalFormat(item.applicationCount,0)}</div>
                </a>
              </div>
            ))
          ) : (
            <div className="no-data">
              <p>No Results Found!</p>
            </div>
          )}
        </div>
        <TableViewMore
          items={californiaDetails}
          title={tableHeading}
          tableColumnTitles={tableTitles}
          tableItemLink={tableItemLink}
          total={total}
          viewMoreEnableCount={8}
          specialClass={'advanceDetailPage'}
        />
      </div>
    </>
  );
};
export default TableCaliforniaData;
