import React from 'react';
import Helmet from 'react-helmet';
import ChemicalDetailContainer from '../containers/ChemicalDetailContainer';

const CaliforniaChemicalDetail = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California Pesticide Use - Chemical</title>
        <meta name="description" content="California Pesticide Use Chemical" />
      </Helmet>
      <ChemicalDetailContainer />
    </>
  );
};
export default CaliforniaChemicalDetail;
