import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestBlogPosts = (page: number, search: string): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/blogs?page=${page}&s=${search}`);
};

const requestBlogDetails = (slug: string): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/wp/v2/posts?slug=${slug}`);
};

const requestNewsletterForm = (): Promise<any> => {
  return axiosApi.get(`https://actionnetwork.org/api/v2/forms/a2286329-8e94-4337-980b-bd9fe2025e14/embed`);
};

const blogApi = {
  requestBlogPosts,
  requestBlogDetails,
  requestNewsletterForm
};

export default blogApi;
