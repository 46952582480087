import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'reactstrap';

interface DonateBlock {
  donateText: string;
  donateButtonLabel: string;
  donateButtonLink: string;
}

const TextBlock = (props: DonateBlock): ReactElement => {
  const { donateText, donateButtonLabel, donateButtonLink } = props;
  if (donateText) {
    return (
      <section className="home-donate">
        <Container>
          <Row className="justify-content-center">
            <Col sm="10" md="6">
              <div className="text-block text-center">
                <div dangerouslySetInnerHTML={{ __html: donateText }} />
                <a
                  href={donateButtonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{donateButtonLabel}</span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
  return <></>;
};
export default TextBlock;
