import React, { ReactElement, useState, useEffect } from 'react';
import chemicalApi from '../../../api/chemicalApi';
import KeyValueDataTableWithSearch from '../../../components/common/KeyValueDataTableWithSearch';

const UsageProductContainer = (props: any): ReactElement => {
  const { tableHeading, tableData, resultCount, chemName, badActor } = props;
  const chemicalId = window.location.pathname.split('chemical/')[1];

  const [modal, setModal] = useState(false);
  const [tableItems, setTableItems] = useState(tableData);
  const [tableResultCount, setTableResultCount] = useState(tableData);
  const [finalSearchValue, setFinalSearchValue] = useState('');

  const [list, setList] = useState({
    metaData: { totalProducts: 0 },
    items: [],
  });

  const closeClick = () => {
    setModal(false);
  };

  const [searchInnerValue, setSearchInnerValue] = useState();
  const [suggestions, setSuggestions]: any = useState([]);

  const productViewMore = async () => {
    setModal(!modal);
    let results;
    try {
      // results = await chemicalApi.requestChemicalUsageContain(chemicalId);
      results = await chemicalApi.requestChemicalUsageProductSuggestions(
        chemicalId,
        finalSearchValue,
        0,
      );
    } catch (e) {}

    setList({
      metaData: results.data.data.metaData
        ? results.data.data.metaData
        : { totalProducts: 0 },
      items: results.data.data.products ? results.data.data.products : [],
    });
  };

  const getsuggestions = async (searchInnerValue: any) => {
    try {
      const results = await chemicalApi.requestChemicalUsageProductSuggestions(
        chemicalId,
        searchInnerValue,
        1000,
      );
      const tempSuggestions: any = [];
      // setTableItems(results.data.data.products);
      results.data.data.products.map((item: any, index: Number) => {
        tempSuggestions.push({
          suggestion: item.PROD_NAME,
        });
      });
      setSuggestions(tempSuggestions);
    } catch (e) {}
  };

  useEffect(() => {
    setTableItems(tableData);
  }, [tableData]);

  useEffect(() => {
    setTableResultCount(resultCount);
  }, [resultCount]);

  useEffect(() => {
    const getFilteredTableData = async () => {
      try {
        const results = await chemicalApi.requestChemicalUsageProductSuggestions(
          chemicalId,
          finalSearchValue,
          8,
        );
        setTableItems(results.data.data.products);
        setTableResultCount(results.data.data.metaData.totalProducts);
      } catch (e) {}
    };
    getFilteredTableData();
  }, [finalSearchValue]);

  return (
    <>
      <KeyValueDataTableWithSearch
        tableHeading={tableHeading}
        tableData={tableItems}
        resultCount={tableResultCount}
        itemName={chemName}
        badActor={badActor}
        list={list}
        itemKey={'PROD_NAME'}
        count={'totalProducts'}
        modal={modal}
        closeClick={closeClick}
        viewMore={productViewMore}
        searchInnerValue={searchInnerValue}
        setSearchInnerValue={setSearchInnerValue}
        suggestions={suggestions}
        getsuggestions={getsuggestions}
        setFinalSearchValue={setFinalSearchValue}
      />
    </>
  );
};
export default UsageProductContainer;
