import React from 'react';
import Helmet from 'react-helmet';
import SearchResultContainer from '../containers/SearchResultContainer';

const SearchResult = (props: any) => {
  return (
    <section>
      <Helmet>
        <title>PesticideInfo | Search Results</title>
        <meta name="description" content="Search Results" />
      </Helmet>
      <SearchResultContainer />
    </section>
  );
};
export default SearchResult;
