import React, { useEffect, useState } from 'react';
import KeyValueDataTable from '../../../components/common/KeyValueDataTable';
import TableCardTwoColumn from '../../chemical/components/TableCardTwoColumn';

const OverviewTab = (props: any) => {
  const { data, chemName, chemId } = props;

  const [tableData, setTableData] = useState({
    summary: [],
    toxicity: [],
    chemicals: {
      chemicals: [],
      metaData: {
        totalchemicals: '',
      },
    },
  });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const linkData = {
    label: 'View Detailed Chemical Information',
    linkTo: `/chemical/${chemId}`,
  };

  return (
    <>
      {/* <pre>{JSON.stringify(tableData.summary)}</pre> */}
      {tableData.summary ? (
        <KeyValueDataTable
          tableHeading="SUMMARY"
          tableData={tableData.summary}
          linkData={linkData}
        />
      ) : (
        ''
      )}
      {tableData.toxicity ? (
        <KeyValueDataTable
          tableHeading={`TOXICITY OF ${chemName}`}
          tableData={tableData.toxicity}
        />
      ) : (
        ''
      )}
      {tableData.chemicals ? (
        <TableCardTwoColumn
          title={`OTHER NAMES FOR ${chemName}`}
          list={tableData.chemicals.chemicals}
          chemName={chemName}
          badActor=""
          count={tableData.chemicals.metaData.totalchemicals}
          chemId={chemId}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default OverviewTab;
