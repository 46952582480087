/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import CustomAccordion from '../../../components/common/CustomAccordion';

const SourceMethologyAccordian = (props: any) => {
  const { faqs, activeId, updateActiveId } = props;

  const createMarkup = (text: any) => {
    let originalText = text;
    originalText = originalText.replace(
      '<table',
      '<div class="table-responsive"><table',
    );
    originalText = originalText.replace(
      '</table>',
      '<table></div><div class="arrow-right"></div>',
    );
    return { __html: originalText };
  };
  return (
    <section className="faq-content">
      <Container>
        <Row className="justify-content-center">
          <Col lg="10">
            {faqs.map((item: any, key: number) => (
              <CustomAccordion
                title={item.question}
                key={key}
                index={key}
                activeId={activeId}
                updateActiveId={updateActiveId}
              >
                <div dangerouslySetInnerHTML={createMarkup(item.answer)} />
              </CustomAccordion>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default SourceMethologyAccordian;
