/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

const ChemicalToxicityFilter = (props: any) => {
  const {
    chemicalToxicity,
    setChemicalToxicity,
    noOfChemicalToxicitySelected,
    setNoOfChemicalToxicitySelected,
    errorMessage,
    setErrorMessage,
    selectedValues,
    setSelectedValues,
    setIsSearchClicked,
  } = props;
  const [chemicalToxicityActiveId, setChemicalToxicityActiveId] = useState();

  const toggleToxicity = (parentIndex: number, childIndex: number) => {
    const toxicity = [...chemicalToxicity];
    const isSelected = toxicity[parentIndex].subItems[childIndex].selected;
    if (!isSelected) {
      if (noOfChemicalToxicitySelected === 10) {
        const error = { ...errorMessage, showMaxLimitMessage: true };
        setErrorMessage(error);
        return;
      }
      toxicity[parentIndex].selected = true;
      toxicity[parentIndex].noOfSelected += 1;
      setNoOfChemicalToxicitySelected(noOfChemicalToxicitySelected + 1);
      selectedValues.push({
        name: 'toxicity',
        itemLabel: toxicity[parentIndex].itemLabel,
        itemValue: toxicity[parentIndex].itemValue,
        parentIndex,
        childIndex,
        noOfSelected: toxicity[parentIndex].noOfSelected,
        subItemsItemLabel: toxicity[parentIndex].subItems[childIndex].itemLabel,
        subItemsItemValue: toxicity[parentIndex].subItems[childIndex].itemValue,
      });
    } else {
      toxicity[parentIndex].noOfSelected -= 1;
      setNoOfChemicalToxicitySelected(noOfChemicalToxicitySelected - 1);
      const error = { ...errorMessage, showMaxLimitMessage: false };
      setErrorMessage(error);
      const newSelectedValues = selectedValues.filter((obj: any) => {
        return (
          obj.subItemsItemValue !==
          toxicity[parentIndex].subItems[childIndex].itemValue
        );
      });
      setSelectedValues(newSelectedValues);
    }
    toxicity[parentIndex].subItems[childIndex].selected = !isSelected;
    setChemicalToxicity(toxicity);
  };

  if (chemicalToxicity !== undefined) {
    return (
      <div className="chemical-toxicity-filter">
        <div className="title">CHEMICAL TOXICITY</div>
        <ul className="filter">
          {chemicalToxicity.map((item: any, key: number) => (
            <li
              key={`item-${key}`}
              className={`${
                chemicalToxicityActiveId !== key ? 'collapsed' : ''
              } ${item.noOfSelected > 0 ? 'selected' : ''}`}
              onClick={(): void => {
                if (chemicalToxicityActiveId === key) {
                  setChemicalToxicityActiveId('');
                } else {
                  setChemicalToxicityActiveId(key);
                }
              }}
            >
              {item.itemLabel}
              <ul>
                {item.subItems.map((subItem: any, innerKey: number) => (
                  <li
                    key={`item-${innerKey}`}
                    className={`${subItem.selected ? 'selected' : ''}`}
                    onClick={(e): void => {
                      setIsSearchClicked(false);
                      e.stopPropagation();
                      toggleToxicity(key, innerKey);
                    }}
                  >
                    {subItem.itemLabel}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return <></>;
};
export default ChemicalToxicityFilter;
