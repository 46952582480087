import React, { useState } from 'react';
import Select from 'react-select';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const CaliforniaHomeSearch = (props: any) => {
  const {
    californiaChemicalsList,
    californiaCropSiteList,
    californiaRegionCountyList,
  } = props;
  const [selections, setSelections]: any = useState({
    chemical: null,
    chemicalName: '',
    cropSite: null,
    cropSiteName: '',
    regionCounty: null,
    regionCountyName: '',
  });
  return (
    <section className="search-container">
      <Container>
        <Row>
          <Col sm="12">
            <h2>SEARCH PESTICIDE USE IN CALIFORNIA</h2>
            <p className="error">Please make a selection in each field</p>
            <div className="selection-wrapper">
              <div className="dropdown-holder">
                <Select
                  //menuIsOpen={true}
                  options={californiaChemicalsList}
                  placeholder="Click to select a chemical"
                  onChange={(item: any) => {
                    setSelections({
                      ...selections,
                      chemical: item.value,
                      chemicalName: item.label,
                    });
                  }}
                />
              </div>
              <i className="plus" />
              <div className="dropdown-holder">
                <Select
                  options={californiaCropSiteList}
                  placeholder="Click to select a crop or site"
                  onChange={(item: any) => {
                    setSelections({
                      ...selections,
                      cropSite: item.value,
                      cropSiteName: item.label,
                    });
                  }}
                />
              </div>
              <i className="plus" />
              <div className="dropdown-holder">
                <Select
                  // menuIsOpen={true}
                  options={californiaRegionCountyList}
                  placeholder="Click to select a region or county"
                  onChange={(item: any) => {
                    setSelections({
                      ...selections,
                      regionCounty: item.value,
                      regionCountyName: item.label,
                    });
                  }}
                />
              </div>
              <div
                className={`search-button ${
                  selections.chemical === null ||
                  selections.cropSite === null ||
                  selections.regionCounty === null
                    ? 'disable'
                    : ''
                }`}
              >
                <Link
                  to={`/california-search?title=${selections.chemicalName},${selections.cropSiteName},${selections.regionCountyName}&chk=${selections.chemical}&sk=${selections.cropSite}&cok=${selections.regionCounty}`}
                />
              </div>
            </div>
            <div className="shortcut-wrapper">
              <h3>OR TRY SHORTCUTS</h3>
              <div className="link-wrapper">
                <Link to="/california-top-pesticide-usage?tab=1">
                  <span>Top 50 Pesticides</span>
                </Link>
                <Link to="/california-top-pesticide-usage?tab=2">
                  <span>Top 50 Crops & Sites</span>
                </Link>
                <Link to="/california-top-pesticide-usage?tab=3">
                  <span>All Pesticide Use By County</span>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default CaliforniaHomeSearch;
