/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Draggable from 'react-draggable';

const CountryPopup = (props: any) => {
  const { isPopup, setIsPopup, selectedCountryData } = props;

  if (isPopup && selectedCountryData.meteData.countryName !== '') {
    return (
      <Draggable>
        <div className="country-popup">
          <span
            onClick={(e) => {
              setIsPopup(false);
            }}
          />
          {selectedCountryData.meteData ? (
            <div>
              <div>
                {selectedCountryData.meteData.countryName}
                <span>{selectedCountryData.meteData.totalBannedChemicals}</span>
              </div>
              <div>
                Highly Hazardous Pesticides:
                <span>{selectedCountryData.meteData.hhpsBanned}</span>
              </div>
              <div>
                Other Pesticides:
                <span>
                  {selectedCountryData.meteData.totalBannedChemicals -
                    selectedCountryData.meteData.hhpsBanned}
                </span>
              </div>
            </div>
          ) : (
            ''
          )}
          <div>
            {selectedCountryData.list.length > 0 ? (
              <div>
                <div>Full List</div>
                <div>
                  <ul>
                    {selectedCountryData.list.map(
                      (chemical: any, index: number) => (
                        <li key={`chem-${index}`}>{chemical.Chem_Name}</li>
                      ),
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Draggable>
    );
  }
  return <></>;
};
export default CountryPopup;
