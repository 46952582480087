import React from 'react';
import TextBlock from '../components/TextBlock';
import RelatedArticles from '../components/RelatedArticles';
import useDonateWithWebList from './useDonateWithWebList';

const DonateWithWebList = () => {
  const homeProps = useDonateWithWebList();
  const {
    donateText,
    donateButtonLabel,
    donateButtonLink,
    relatedWebsites,
  } = homeProps;
  //console.log('homeProps', homeProps);
  return (
    <>
      <TextBlock
        donateText={donateText}
        donateButtonLabel={donateButtonLabel}
        donateButtonLink={donateButtonLink}
      />
      <RelatedArticles relatedWebsites={relatedWebsites} />
    </>
  );
};

export default DonateWithWebList;
