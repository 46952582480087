import React from 'react';
import TitleButton from '../components/SearchLandingTitleButton';

const TitleButtons = (props: any) => {
  return (
    <>
      <TitleButton />
    </>
  );
};
export default TitleButtons;
