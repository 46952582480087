import {
  REQUEST_RESOURCE_LIST_SUCCESS,
  UPDATE_RESOURCE_LIST_PAGE_NUMBER,
  UPDATE_RESOURCE_LIST_SEARCH_VALUE,
  UPDATE_RESOURCE_CATEGORY_ID,
  REQUEST_RESOURCE_LIST_ERROR,
} from '../actionType';

const resourceReducer = (state: any, action: any) => {
  switch (action.type) {
    case REQUEST_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        resources: action.payload.resourcePosts,
        currentPage: parseInt(action.payload.currentPage, 10),
        numPages: parseInt(action.payload.maxNumPages, 10),
      };
    case UPDATE_RESOURCE_LIST_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.payload,
      };
    case UPDATE_RESOURCE_LIST_SEARCH_VALUE:
      return {
        ...state,
        searchText: action.payload,
      };
    case UPDATE_RESOURCE_CATEGORY_ID:
      return {
        ...state,
        category: action.payload,
      };
    case REQUEST_RESOURCE_LIST_ERROR:
      return {
        ...state,
        searchText: ' ',
        resources: [],
        currentPage: 1,
        numPages: 1,
        category: ' ',
        resourcesAvailable: false,
      };
    default:
      return state;
  }
};

export default resourceReducer;
