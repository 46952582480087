import { useEffect, useState } from 'react';
import homeApi from '../api/homeApi';

const useGeneralThemeOptions = () => {
  // const { query } = props;
  const [data, setData] = useState({
    catUrl: '',
    ctaDescription: '',
    ctaImage: '',
    ctaTitle: '',
    ctaUrlTitle: '',
  });

  useEffect(() => {
    const getHeaderData = async () => {
      try {
        const results = await homeApi.requestGeneralSettings();
        setData(results.data.acf);
      } catch (e) {}
    };
    getHeaderData();
  }, []);

  return data && data;
};

export default useGeneralThemeOptions;