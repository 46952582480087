import React, { useState, useEffect, useRef } from 'react';
import AutoComplete from '../../../components/common/AutoComplete';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';

const SearchForm = (props: any) => {
  const { searchHandleSubmit, showTextInvalidError } = props;
  const searchinput: any = useRef(null);
  const [searchType, setSearchType] = useState('chemical');
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState();
  const [productName, setProductName] = useState('');

  const getSearchAutoCompleteSuggestions = async (search: any) => {
    // console.log(searchTerm);
    try {
      const results = await advanceSearchProductApi.requestAdvanceSearchProductNameSuggest(
        search,
        searchType,
      );

      setSuggestions(results.data.data.list);
      // console.log(results.data.data);
    } catch (e) {}
  };
  useEffect(() => {
    setProductName(searchTerm);
    // getPestNameAutoCompleteSuggestions(setPestName);
  }, []);

  const getsuggestions = (search: any) => {
    // console.log(serchTerm);
    setSearchTerm(search);
    getSearchAutoCompleteSuggestions(search);
    // console.log(suggestions);
  };

  let sort = 'Chem_Name';
  const page = '1';
  const order = 'ASC';
  let errorMessage = '';

  useEffect(() => {
    if (searchType === 'product') {
      sort = 'name';
    }
  });

  if (showTextInvalidError) {
    errorMessage = 'Keyword is required';
  }
  return (
    <form
      className={`search ${errorMessage !== '' ? 'error' : ''}`}
      onSubmit={(e) => {
        searchHandleSubmit(e, productName, searchType, page, sort, order);
      }}
    >
      <div className="error-message-wrapper">
        {errorMessage !== '' ? (
          <div className="error-message">{errorMessage}</div>
        ) : (
          ''
        )}
      </div>

      <AutoComplete
        output={productName}
        setOutput={setProductName}
        suggestions={suggestions}
        getsuggestions={getsuggestions}
        placeholder="Search by a chemical, product or more"
        setFinalSearchValue={() => {}}
        searchinput={searchinput}
        searchButton={true}
      />

      <div className="bottom-elements">
        <input
          type="radio"
          id="chemical-search"
          name="mainsearch"
          value="chemical"
          defaultChecked
          onChange={(e) => {
            setSearchType(e.target.value);
              searchinput.current.focus();
          }}
        />
        <label htmlFor="chemical-search">Chemical Search</label>
        <input
          type="radio"
          id="product-search"
          name="mainsearch"
          value="product"
          onChange={(e) => {
            setSearchType(e.target.value);
             searchinput.current.focus();
          }}
        />
        <label htmlFor="product-search">Product Search</label>
        <a href="/advance-search" className="icon-link">
          Advanced search
        </a>
      </div>
    </form>
  );
};
export default SearchForm;
