import React from 'react';
import Footer from '../components/common/Footer';
import useFooter from './useFooter';

const FooterContainer = () => {
  const {
    footerAddressOne,
    footerAddressTwo,
    footerBottomCaption,
    footerMainLogo,
    footerSocialMediaCaption,
    footerSocialMediaFacebook,
    footerSocialMediaPinterest,
    footerSocialMediaTwitter,
    footerSocialMediaYoutube,
  } = useFooter();

  return (
    <>
      <Footer
        footerAddressOne={footerAddressOne}
        footerAddressTwo={footerAddressTwo}
        footerBottomCaption={footerBottomCaption}
        footerMainLogo={footerMainLogo}
        footerSocialMediaCaption={footerSocialMediaCaption}
        footerSocialMediaFacebook={footerSocialMediaFacebook}
        footerSocialMediaPinterest={footerSocialMediaPinterest}
        footerSocialMediaTwitter={footerSocialMediaTwitter}
        footerSocialMediaYoutube={footerSocialMediaYoutube}
      />
    </>
  );
};

export default FooterContainer;
