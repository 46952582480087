import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../components/BreadCrumb';
import Title from '../components/Title';
import advanceSearchProductApi from '../../../api/advanceSearchProductApi';
import AdvSearchResultsDownload from '../../../components/common/AdvSearchResultsDownload';
import ProductPagination from '../components/ProductPagination';
import ProductResultTablePlaceholder from '../components/ProductResultTablePlaceholder';
import SearchResultTable from '../components/SearchResultTable';

const fileDownload = require('js-file-download');

const AdvanceProductSearchLandingPersistContainer = (props: any) => {
  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };
  const query = useQuery();

  const uses: string | null = query.get('uses') ? query.get('uses') : '';
  const pest: string | null = query.get('pest') ? query.get('pest') : '';
  const commodity: string | null = query.get('commodity')
    ? query.get('commodity')
    : '';

  const [data, setData] = useState({
    list: [],
    metaData: {
      page: '1',
      total: '0',
    },
  });
  const [titleText, setTitleText] = useState('');
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [total, setTotal] = useState('0');
  const [sort, setSort] = useState('name');
  const [order, setOrder] = useState('ASC');
  const [isSorting, setIsSorting] = useState(false);
  const [searchData, setSearchData] = useState({
    sortField: sort,
    orderBy: order,
    pageNumber: '1',
    prodStatus: 'active',
    prodUses: uses,
    prodFormulation: null,
    chemToxCategory: null,
    ahwLabel: null,
    regulatoryList: null,
    chemicalType: null,
    prodRegYear: null,
  });

  const getSearchData = async () => {
    try {
      setRequestDataComplete(false);
      setDataNotFound(false);
      const results = await advanceSearchProductApi.requestAdvancedProductSearchResults(
        searchData,
        pest,
        commodity,
        '',
        '',
        '',
      );

      setData(results.data.data);
      setTotal(results.data.data.metaData.total);
      setRequestDataComplete(true);
      setIsSorting(false);
    } catch (e) {
      if (e.response && e.response.status) {
        setDataNotFound(true);
      }
    }
  };
  const downloadSearchData = async (fileType: string) => {
    try {
      const results = await advanceSearchProductApi.downloadAdvancedProductSearchResults(
        fileType,
        searchData,
        pest,
        commodity,
        '',
        '',
        '',
      );

      if (fileType == 'pdf') {
        fileDownload(
          results.data,
          `advanced-product-download-${Date.now()}.pdf`,
        );
      } else {
        fileDownload(
          results.data,
          `advanced-product-download-${Date.now()}.csv`,
        );
      }
    } catch (e) {
      if (e.response && e.response.status) {
      }
    }
  };

  const downloadlick = (fileType: string) => {
    downloadSearchData(fileType);
  };

  const pageClick = (e: any, pageNumber: string) => {
    e.preventDefault();
    searchData.pageNumber = pageNumber;
    getSearchData();
  };

  const clickSort = (sortName: string) => {
    setIsSorting(true);
    let pageOrder = searchData.orderBy;
    if (sortName === searchData.sortField) {
      pageOrder = searchData.orderBy === 'ASC' ? 'DESC' : 'ASC';
    } else {
      pageOrder = 'ASC';
    }
    searchData.pageNumber = '1';
    searchData.sortField = sortName;
    searchData.orderBy = pageOrder;
    getSearchData();
  };

  useEffect(() => {
    getSearchData();
    window.scrollTo(0, 0);

    if (uses !== '') {
      setTitleText(`Registered Uses - ${uses}`);
    }

    if (pest !== '') {
      setTitleText(`Pests - ${pest}`);
    }

    if (commodity !== '') {
      setTitleText(`Commodity (Crop) or Site - ${commodity}`);
    }
  }, []);

  return (
    <>
      <BreadCrumb />
      <Title titleText={titleText} />
      <SearchResultTable
        data={data}
        list={data.list}
        pageClick={pageClick}
        requestDataComplete={requestDataComplete}
        dataNotFound={dataNotFound}
        numberOfResults={total}
        clickSort={clickSort}
      />
      <ProductResultTablePlaceholder
        isSorting={isSorting}
        numberOfResults={total}
      />
      {requestDataComplete ? (
        <AdvSearchResultsDownload
          csvParams={`sort=${sort}&order=${order}&type=product&search=`}
          pdfParams={`sort=${sort}&order=${order}&type=product&search=`}
          downloadlick={downloadlick}
        />
      ) : (
        ''
      )}
      <ProductPagination
        currentPage={data.metaData.page}
        numPages={Math.ceil(parseInt(data.metaData.total) / 25)}
        pageClick={pageClick}
        requestDataComplete={requestDataComplete}
        dataNotFound={dataNotFound}
      />
      <div style={{ padding: '60px  0 0 0' }} />
    </>
  );
};
export default AdvanceProductSearchLandingPersistContainer;
