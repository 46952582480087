/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';

import SearchResultBreadcrumb from '../components/SearchResultBreadcrumb';
import SearchResultPagination from '../components/SearchResultPagination';
import SearchResultTitle from '../components/SearchResultTitle';
import SearchResultTable from '../components/SearchResultTable';
import SearchResultSearchForm from '../components/SearchResultSearchForm';
import simpleSearchResultApi from '../../../api/simpleSearchResultApi';
import { SearchContext } from '../../../store/search/context';
import {
  UPDATE_SIMPLE_SEARCH_VALUE,
  UPDATE_SIMPLE_SEARCH_NUMBER_OF_RESULSTS,
  UPDATE_SEARCH_RESULT_PAGE_NUMBER,
} from '../../../store/search/actionType';
import SearchResultPlaceholderTable from '../components/SearchResultPlaceholderTable';
import SearchResultsDownload from '../../../components/common/SearchResultsDownload';

const SearchResultContainer = (props: any) => {
  const history = useHistory();
  const { dispatch, searchState } = useContext(SearchContext);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [data, setData] = useState({
    list: [],
    metaData: [],
  });
  const [showTextInvalidError, setShowTextInvalidError] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(decodeURIComponent(useLocation().search));
  };

  const query = useQuery();
  let search: string | null = query.get('search') ? query.get('search') : '';
  var encodedSearchParam = encodeURI(search!);
  encodedSearchParam = encodedSearchParam.replace(/#/g, '%23');
  encodedSearchParam = encodedSearchParam.replace(/&/g, '%2526');
  let type: string | null = query.get('type') ? query.get('type') : 'chemical';
  const page: string | null = query.get('page') ? query.get('page') : '1';
  const sortFromUrl: string | null = query.get('sort')
    ? query.get('sort')
    : 'name';
  const order: string | null = query.get('order') ? query.get('order') : 'ASC';

  const [sort, setSort] = useState(sortFromUrl);

  const getSearchData = async (
    searchTerm: string | null,
    searchType: string | null,
    pageNumber: string | null,
    sortField: string | null,
    orderBy: string | null,
  ) => {
    try {
      setRequestDataComplete(false);
      setDataNotFound(false);
      const results = await simpleSearchResultApi.requestSimpleSearchResults(
        searchTerm,
        searchType,
        pageNumber,
        sortField,
        orderBy,
      );
      setData(results.data.data);
      setRequestDataComplete(true);
      dispatch({
        type: UPDATE_SIMPLE_SEARCH_NUMBER_OF_RESULSTS,
        payload: results.data.data,
      });
      window.scrollTo(0, 0);
      setIsSorting(false);
    } catch (e) {
      if (e.response && e.response.status) {
        setDataNotFound(true);
      }
    }
  };

  useEffect(() => {
    getSearchData(search, type, page, sort, order);
  }, []);

  const pageClick = (e: any, pageNumber: string) => {
    e.preventDefault();
    dispatch({ type: UPDATE_SEARCH_RESULT_PAGE_NUMBER, payload: pageNumber });
    history.push(
      `/search-result?page=${pageNumber}&sort=${sort}&order=${order}&search=${search}&type=${type}`,
    );
    getSearchData(search, type, pageNumber, sort, order);
  };

  const clickSort = (sortName: string) => {
    var searchParam = encodeURI(search!);
    searchParam = searchParam.replace(/#/g, '%23');

    setSort(sortName);
    setIsSorting(true);
    let pageOrder = order;
    if (sortName === sort) {
      pageOrder = order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      pageOrder = 'ASC';
    }
    history.push(
      `/search-result?page=1&sort=${sortName}&order=${pageOrder}&search=${searchParam}&type=${type}`,
    );
    getSearchData(search, type, '1', sortName, pageOrder);
  };

  const resultsSearchHandleSubmit = (
    e: any,
    searchValue: string,
    searchType: string,
  ) => {
    const inputValue = searchValue.trim();
    e.preventDefault();
    if (inputValue !== '') {
      dispatch({
        type: UPDATE_SIMPLE_SEARCH_VALUE,
        payload: { type, searchValue },
      });
      search = searchValue;
      type = searchType;
      history.push(
        `/search-result?page=1&sort=${sort}&order=ASC&search=${searchValue}&type=${searchType}`,
      );
      getSearchData(decodeURIComponent(search), type, '1', sort, 'ASC');
      setShowTextInvalidError(false);
    } else {
      setShowTextInvalidError(true);
      dispatch({
        type: UPDATE_SIMPLE_SEARCH_VALUE,
        payload: { type, searchValue: '', page, sort, order },
      });
    }
  };

  return (
    <section>
      <SearchResultBreadcrumb />
      <Container>
        <Row>
          <Col sm="6">
            <SearchResultTitle
              searchValue={search}
              numberOfResults={searchState.numberOfResults}
              requestDataComplete={requestDataComplete}
              dataNotFound={dataNotFound}
              isSorting={isSorting}
            />
          </Col>
          <Col sm="6">
            <SearchResultSearchForm
              resultsSearchHandleSubmit={resultsSearchHandleSubmit}
              showTextInvalidError={showTextInvalidError}
              type={type}
              setSort={setSort}
            />
          </Col>
        </Row>
      </Container>
      <div className="search-result-table-container">
        <SearchResultTable
          type={type}
          list={data.list}
          requestDataComplete={requestDataComplete}
          dataNotFound={dataNotFound}
          clickSort={clickSort}
          sort={sort}
          className="search-result-table-wrapper"
        />
        <SearchResultPlaceholderTable isSorting={isSorting} type={type} />
      </div>
      {requestDataComplete ? (
        <SearchResultsDownload
        csvDownloadPath={`searchcsv?sort=${sort}&order=${order}&type=${type}&search=${encodedSearchParam}`}
        pdfDownloadPath={`searchpdf?sort=${sort}&order=${order}&type=${type}&search=${encodedSearchParam}`}
        />
      ) : (
        ''
      )}

      <SearchResultPagination
        currentPage={page}
        numPages={Math.ceil(searchState.numberOfResults / 25)}
        pageClick={pageClick}
        requestDataComplete={requestDataComplete}
        dataNotFound={dataNotFound}
      />
      <div style={{ padding: '60px  0 0 0' }} />
    </section>
  );
};
export default SearchResultContainer;
