import React from 'react';
import Helmet from 'react-helmet';
import CountyDetailContainer from '../containers/CountyDetailContainer';

const CountyDetailPage = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California Pesticide Use - County</title>
        <meta name="description" content="California Pesticide Use County" />
      </Helmet>
      <CountyDetailContainer />
    </>
  );
};
export default CountyDetailPage;
