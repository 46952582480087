/* eslint-disable camelcase */
import axiosApi from './axiosApi';
import { wpApiPrefix } from '../constants';

const requestDonationSectionDetails = (): Promise<any> => {
  return axiosApi.get(`${wpApiPrefix}/acf/v3/options/header`);
};

const donationSectionApi = {
    requestDonationSectionDetails,
};

export default donationSectionApi;
