/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { readFileSync } from 'fs';
import Draggable from 'react-draggable';

const ChemicalResultTable = (props: any) => {
  const {
    list,
    requestDataComplete,
    dataNotFound,
    clickSort,
    additionalColumns,
  } = props;
  const [scrollVal, setScrollVal] = useState(0);

  // const tableWrapperOne = useRef<null | HTMLElement>(null);
  const tableWrapperOne = useRef<HTMLInputElement>(null);
  const tableWrapperTwo = useRef<HTMLInputElement>(null);

  const scrollHandlerOne = (e: any) => {
    if (tableWrapperTwo.current) {
      const newSrollVal = scrollVal + 10;
      console.log('scrollVal', scrollVal);
      tableWrapperTwo.current.scrollLeft = newSrollVal;
      console.log('tableWrapperTwo', tableWrapperTwo);
      setScrollVal(newSrollVal);
    }
  };

  const scrollHandlerTwo = (e: any) => {
    if (tableWrapperOne.current) {
      tableWrapperOne.current.scrollLeft = 10;
    }
  };

  if (requestDataComplete) {
    return (
      <Container className="search-result-table chemical-advanced ">
        <Row>
          {list && list.length > 0 ? (
            <Col sm="12" style={{ padding: 0 }}>
              {/* <div
                className="result-table-wrapper-one"
                ref={tableWrapperOne}
                onScroll={(e: any) => {
                  scrollHandlerOne(console.log(e));
                }}
              >
                <div className="result-table-one"></div>
              </div> */}

              <div
                className="result-table-wrapper-two"
                onScroll={(e: any) => {
                  scrollHandlerTwo(e);
                }}
              >
                <div className="result-table" ref={tableWrapperTwo}>
                  <div className="result-table-row titles">
                    <div>
                      <div
                        onClick={() => {
                          clickSort('chemicalName');
                        }}
                      >
                        Chemical Name
                      </div>
                      <div
                        onClick={() => {
                          clickSort('ePARegistered');
                        }}
                      >
                        EPA REGISTERED
                      </div>
                      <div
                        onClick={() => {
                          clickSort('panBadActor');
                        }}
                      >
                        PAN BAD ACTOR
                      </div>
                      <div
                      // onClick={() => {
                      //   clickSort('name4');
                      // }}
                      >
                        HIGHLY HAZARDOUS PESTICIDE
                      </div>
                      <div
                      // onClick={() => {
                      //   clickSort('name4');
                      // }}
                      >
                        EU REGISTERED
                      </div>
                      {additionalColumns.map((item: any, key: number) => (
                        <div
                          key={key}
                          onClick={() => {
                            clickSort(item.split(':')[0]);
                          }}
                        >
                          {item.split(':')[1]}
                        </div>
                      ))}
                    </div>
                  </div>
                  {list.map((item: any, key: number) => (
                    <div className="result-table-row" key={key}>
                      <Link target="_blank" to={`/chemical/${item.Rec_Id}`}>
                        <div>
                          <h3>{item.chemicalName}</h3>
                        </div>
                        <div>{item.ePARegistered}</div>
                        <div>{item.panBadActor}</div>
                        <div>{
                          (item.hhp==='1') ? 
                            'Yes'
                            : 
                            (item.hhp === '0') ?
                              'No'
                              : 
                              'Not Listed'
                        }</div>
                        <div>{item.eURegistered? item.eURegistered : 'Not Listed'}</div>
                        {additionalColumns.map(
                          (innerItem: any, innerKey: number) => (
                            <div
                              key={innerKey}
                              className={key % 2 === 1 ? 'odd' : 'even'}
                            >
                              {item[innerItem.split(':')[0]]}
                            </div>
                          ),
                        )}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Container>
    );
  }
  if (dataNotFound) {
    return (
      <Container className="search-result-table">
        <Row>
          <Col sm="12" className="no-result-found">
            <h3>No Search Results Found</h3>
            <p>No results found for this combination. Please modify your search.</p>
          </Col>
        </Row>
      </Container>
    );
  }
  return <></>;
};
export default ChemicalResultTable;
