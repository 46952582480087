import React from 'react';
import Helmet from 'react-helmet';
import CprMapMainContainer from '../containers/CprMapMainContainer';

const BlogInner = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | California People and Pesticides Explorer</title>
        <meta name="description" content="California People and Pesticides Explorer" />
      </Helmet>
      <CprMapMainContainer />
    </>
  );
};
export default BlogInner;
