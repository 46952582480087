/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useState } from 'react';
import { ResourceContext } from '../../../store/resource/context';
import resourcesApi from '../../../api/resourcesApi';
import ResourcePosts from '../components/ResourcePosts';
import {
  REQUEST_RESOURCE_LIST_SUCCESS,
  UPDATE_RESOURCE_LIST_PAGE_NUMBER,
  UPDATE_RESOURCE_LIST_SEARCH_VALUE,
  UPDATE_RESOURCE_CATEGORY_ID,
  REQUEST_RESOURCE_LIST_ERROR,
} from '../../../store/resource/actionType';
import ResourceBreadcrumb from '../components/ResourceBreadcrumb';
import ResourceSearch from '../components/ResourceSearch';
import ResourcePagination from '../components/ResourcePagination';
import ResourceTitle from '../components/ResourceTitle';
import ResourceCategories from '../components/ResourceCategories';
//import Donation from '../../../components/common/Donation';
//import NewsLetterForm from '../../../components/common/NewsLetterForm';

const ResourceListContainer = () => {
  const { dispatch, resourceState } = useContext(ResourceContext);
  const [resourceTitle, setTitle] = useState();
  //const [enableDonation, setEnableDonation] = useState();
  //const [enableNewsletterSubscription, setEnableNewsletterSubscription] = useState();
  
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [resourceCategories, setResourceCategories] = useState();
  const slug = window.location.pathname.split('resources/category/')[1];

  const getCurrentCategory = (
    categoryList: any[],
    currentCategoryName: any,
  ) => {
    let categoryId = ' ';
    if (categoryList) {
      categoryList.map((category: any, key: number) => {
        if (category.term_slug === currentCategoryName) {
          categoryId = category.term_id;
          return categoryId;
        }
      });
    }
    return categoryId;
  };

  const getResourceData = async (
    category: any,
    search: string,
    page: number,
  ) => {
    try {
      setRequestDataComplete(false);
      const results = await resourcesApi.requestResourcePosts(
        category,
        search,
        page,
      );
      dispatch({ type: REQUEST_RESOURCE_LIST_SUCCESS, payload: results.data });
      setRequestDataComplete(true);
      window.scrollTo(0, 0);
    } catch (e) {
      dispatch({ type: REQUEST_RESOURCE_LIST_ERROR });
      setRequestDataComplete(true);
    }
  };

  useEffect(() => {
    const getResourceCategories = async () => {
      try {
        const results = await resourcesApi.requestCategories();
        setResourceCategories(results.data);
        const catId = getCurrentCategory(results.data, slug);

        // read url params
        getResourceData(catId, '', 1);
        dispatch({ type: UPDATE_RESOURCE_CATEGORY_ID, payload: catId });
      } catch (e) {}
    };
    const getResourceTitle = async () => {
      try {
        const results = await resourcesApi.requestResourceTitle();
        setTitle(results.data[0].title.rendered);
      } catch (e) {}
    };
    getResourceTitle();
    getResourceCategories();
  }, []);

  const searchHandleSubmit = (e: any, searchValue: string) => {
    e.preventDefault();
    dispatch({ type: UPDATE_RESOURCE_LIST_SEARCH_VALUE, payload: searchValue });
    getResourceData(resourceState.category, searchValue, 1);
  };

  const pageClick = (e: any, pageNumber: number) => {
    e.preventDefault();
    dispatch({ type: UPDATE_RESOURCE_LIST_PAGE_NUMBER, payload: pageNumber });
    getResourceData(
      resourceState.category,
      resourceState.searchText,
      pageNumber,
    );
  };

  const linkClick = (e: any, id: number) => {
    dispatch({ type: UPDATE_RESOURCE_CATEGORY_ID, payload: id });
    getResourceData(id, resourceState.searchText, 1);
  };

  return (
    <>
      <ResourceBreadcrumb />
      <ResourceTitle resourceTitle={resourceTitle} />
      <ResourceCategories
        resourceCategories={resourceCategories}
        linkClick={linkClick}
        activeCategoryId={resourceState.category}
      />
      <ResourceSearch
        searchText={resourceState.searchText}
        searchHandleSubmit={searchHandleSubmit}
      />
      <ResourcePosts
        requestDataComplete={requestDataComplete}
        resourceList={resourceState.resources}
      />
      <ResourcePagination
        currentPage={resourceState.currentPage}
        numPages={resourceState.numPages}
        pageClick={pageClick}
      />
      {/* This donation section only show when it's enable from the resources page */}
      {/* {enableDonation==="Yes" ? <Donation /> :''} */}
      {/* {enableNewsletterSubscription === "Yes" ? <NewsLetterForm /> : ''} */}
    </>
  );
};
export default ResourceListContainer;
