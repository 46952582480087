import React from 'react';
import { Table } from 'reactstrap';

const ProductOverviewManufacturer = (props: any) => {
  const { manufacturer, distributor } = props;
  return (
    <Table className="manufacturer" responsive bordered>
      <tbody>
        <tr>
          <td className="heading" colSpan={2}>
            MANUFACTURER AND DISTRIBUTOR
          </td>
        </tr>
        <tr>
          <td>
            <strong>Manufacturer:</strong>
            <br />
            <br />

            {manufacturer.map((item: any, key: number) => (
              <>
                <p key={key}>
                  <strong>{item['manufacturerName']}</strong>
                  <br />
                  {item['manufacturerStreet']},
                  <br />
                  {item['manufacturerCity']}, {item['manufacturerState']}{' '}
                  {item['manufacturerZip']}
                  <br />
                  Company Number: {item['manufacturerNo']}
                </p>
              </>
            ))}
          </td>
          <td>
            <strong>Distributor:</strong>
            <br />
            <br />
            {distributor.map((item: any, key: number) => (
              <>
                <p key={key}>
                  <strong>{item['distributorName']}</strong>
                  <br />
                  {item['distributorStreet']},
                  <br />
                  {item['distributorCity']}, {item['distributorState']}{' '}
                  {item['distributorZip']}
                  <br />
                  Company Number: {item['distributorNo']}
                </p>
              </>
            ))}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
export default ProductOverviewManufacturer;
