import React from 'react';
import './assets/scss/main.scss';
import { BrowserRouter } from 'react-router-dom';
import Route from './router';
import { CommonContextProvider } from './store/common/context';
import { BlogContextProvider } from './store/blog/context';
import { ResourceContextProvider } from './store/resource/context';
import { SearchContextProvider } from './store/search/context';

function App() {
  return (
    <CommonContextProvider>
      <BlogContextProvider>
        <SearchContextProvider>
          <ResourceContextProvider>
            <BrowserRouter>
              <div className="App">
                <Route />
              </div>
            </BrowserRouter>
          </ResourceContextProvider>
        </SearchContextProvider>
      </BlogContextProvider>
    </CommonContextProvider>
  );
}

export default App;
