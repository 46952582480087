import React from 'react';
import Helmet from 'react-helmet';
import ProductDetailsInner from '../containers/ProductDetailsInner';

const ProductDetail = (props: any) => {
  return (
    <>
      <Helmet>
        <title>PesticideInfo | Product Details</title>
        <meta name="description" content="Product Details" />
      </Helmet>
      <ProductDetailsInner />
    </>
  );
};
export default ProductDetail;
